@charset "UTF-8";
.nav-container {
  position: fixed;
  z-index: 104;
  top: 10px;
  left: 1vw;
  padding: 0;
  margin: 0;
  width: 98vw;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 10px;
  transition: 0.3s ease all; }
  .nav-container .icon-box {
    width: 50px;
    padding: 10px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center; }
  .nav-container .logo-container {
    display: flex; }
  .nav-container .logo-center {
    justify-content: center;
    align-items: center;
    display: flex; }
  .nav-container .navi-container {
    display: flex;
    justify-content: flex-end; }
    .nav-container .navi-container .nav-element {
      width: 55px;
      font-size: 15px;
      font-weight: 500;
      color: #333; }

.nav-draw-container {
  position: fixed;
  z-index: 1000;
  top: 10px;
  left: 1vw;
  padding: 0;
  margin: 0;
  width: 98vw;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 10px 0 10px;
  transition: ease-in 0.3s all; }
  .nav-draw-container .nav-btn-box {
    position: absolute;
    top: 50px;
    left: 0;
    display: none;
    height: 250px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: fadein ease-in 0.3s; }
    .nav-draw-container .nav-btn-box .nav-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      height: 14.28%;
      width: 100%; }
    .nav-draw-container .nav-btn-box .nav-btn:active {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      color: white;
      background-color: gray;
      height: 14.28%;
      width: 100%; }
  .nav-draw-container .draw-header {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
  .nav-draw-container .icon-box {
    width: 50px;
    padding: 10px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center; }
  .nav-draw-container .logo-container {
    display: flex; }
  .nav-draw-container .logo-center {
    justify-content: center;
    align-items: center;
    display: flex; }
  .nav-draw-container .navi-container {
    display: flex;
    justify-content: flex-end; }
    .nav-draw-container .navi-container .nav-element {
      width: 55px;
      font-size: 12px;
      font-weight: 500;
      color: #333; }

.image-icon-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

.custom-icon {
  padding: 5px 10px 5px 10px; }

.nav-toggle-container {
  position: fixed;
  z-index: 104;
  top: 10px;
  left: 1vw;
  padding: 0;
  margin: 0;
  width: 98vw;
  height: 50px;
  background-color: rgba(255, 255, 255, 0);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 20px;
  transition: 0.3s ease all; }
  .nav-toggle-container .logo-container {
    display: flex; }
  .nav-toggle-container .logo-center {
    justify-content: center;
    align-items: center;
    display: flex; }
  .nav-toggle-container .navi-container {
    display: flex;
    justify-content: flex-end; }
    .nav-toggle-container .navi-container .nav-element {
      width: 55px;
      font-size: 12px;
      font-weight: 300;
      color: #333; }

.shoes-images-container {
  margin: 20vh 25px 20vh 25px;
  background-color: rgba(255, 255, 255, 0.3); }
  @media only screen and (max-width: 575px) {
    .shoes-images-container {
      margin: 20vh 10px 20vh 10px; } }
  .shoes-images-container .shoes-images-row {
    width: 100%;
    padding: 15px 0 15px 0; }
  .shoes-images-container .shoes-images-header {
    padding: 20px;
    font-size: 15px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center; }
  .shoes-images-container .shoes-images-col {
    padding: 20px;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 50vw; }
    @media only screen and (max-width: 575px) {
      .shoes-images-container .shoes-images-col {
        padding: 20px 5px 20px 5px; } }
    .shoes-images-container .shoes-images-col .shoes-images-div {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .shoes-images-container .shoes-images-col .shoes-images-div .shoes-image {
        width: 100%;
        height: 100%;
        margin-bottom: 10px;
        object-fit: contain; }
      .shoes-images-container .shoes-images-col .shoes-images-div .shoes-name {
        font-weight: 400;
        font-size: 12px;
        color: #333; }

.shirts-size-table {
  margin: 20vh 10px 100px 10px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  flex: 1; }
  @media only screen and (max-width: 575px) {
    .shirts-size-table {
      font-size: 10px; } }
  .shirts-size-table .shirts-size-header {
    flex: 1;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    line-height: 90px;
    border-bottom: solid 1px #999;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 16px; }
  .shirts-size-table .shirts-size-table-row {
    padding: 5px 0 5px 0;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 5px 0 5px 0; }
  .shirts-size-table .shirts-size-table-col {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center; }
  .shirts-size-table .shirts-size-notice {
    padding: 15px; }
    @media only screen and (max-width: 575px) {
      .shirts-size-table .shirts-size-notice {
        padding: 5px; } }

.goods-category {
  width: 100%;
  height: 50px;
  color: gray;
  margin: 5vh 0 2vh 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer; }
  .goods-category .goods-category-element {
    cursor: pointer;
    color: gray;
    font-size: 12px;
    padding: 5px 10px 5px 10px; }
    @media only screen and (max-width: 575px) {
      .goods-category .goods-category-element {
        padding: 7px; } }
  .goods-category .goods-category-element-activated {
    cursor: pointer;
    color: #333;
    font-size: 12px;
    padding: 5px 10px 5px 10px; }
    @media only screen and (max-width: 575px) {
      .goods-category .goods-category-element-activated {
        padding: 7px; } }

.grid-name-text {
  color: #333;
  font-weight: 300; }

.grid-name-text-bold {
  color: #333;
  font-weight: bold; }

.grid-price-text {
  font-weight: 00;
  margin: 0;
  padding: 0 5px 0 0;
  text-align: right; }

.goods {
  font-size: 12px; }

.home-icon {
  margin: 0 10px 0 0; }

.home-component {
  padding: 3%;
  margin: 7% 0 7% 0; }
  .home-component .component-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 2vh;
    font-weight: 500; }

.home-notice {
  display: flex;
  flex-direction: column;
  height: 350px;
  margin: 20px 0 20px 0; }
  .home-notice .home-notice-image {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 40vh; }
  .home-notice .home-notice-text-box {
    text-align: center;
    margin: 10px 0 10px 0;
    display: flex;
    flex-direction: column; }
    .home-notice .home-notice-text-box .detail {
      font-size: 12px;
      font-weight: 500;
      color: #1db9b9; }

.home-goods-list {
  min-height: 30vw;
  overflow-x: scroll;
  -ms-overflow-style: none; }
  .home-goods-list::-webkit-scrollbar {
    display: none !important; }
  .home-goods-list .home-goods {
    padding: 15px 30px 15px 0; }
    .home-goods-list .home-goods .home-goods-textbox {
      width: 100%;
      padding: 5px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start; }
    .home-goods-list .home-goods .home-goods-image {
      width: 25vw;
      height: 25vw;
      object-fit: cover;
      object-position: center;
      transition: 0.3s ease all; }
    .home-goods-list .home-goods .home-goods-image:hover {
      object-fit: contain;
      transition: 0.3s ease all; }

.tag-test .ReactTags__tagInput {
  font-size: 12px;
  padding: 5px;
  margin: 10px;
  width: 200px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: gray; }

.tag-test .ReactTags__tagInputField {
  border-style: none;
  background-color: #efefef;
  width: 100%;
  font-size: 12px;
  outline: none; }

.tag-test .ReactTags__selected {
  margin: 5px;
  margin-bottom: 15px; }

.tag-test .ReactTags__selected .ReactTags__tag {
  color: #326bb5;
  font-size: 12;
  font-weight: 700;
  padding: 10px;
  margin: 5px; }

.shop-header {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between; }
  .shop-header .header {
    width: 100%;
    font-size: 24px; }
    @media only screen and (max-width: 768px) {
      .shop-header .header {
        width: 100%;
        font-size: 15px; } }
  .shop-header .btn-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end; }
    .shop-header .btn-box .shop-btn {
      cursor: pointer;
      position: relative;
      z-index: 101;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 30px;
      border-color: #777;
      background-color: white;
      border-style: solid;
      border-width: 0.5px;
      border-radius: 30px;
      margin-right: 10px;
      color: #333;
      font-size: 12px;
      font-weight: 500; }
      @media only screen and (max-width: 768px) {
        .shop-header .btn-box .shop-btn {
          width: 60px;
          height: 30px;
          font-size: 12px; } }

.shop-margin-top {
  margin-top: 100px; }
  @media only screen and (max-width: 768px) {
    .shop-margin-top {
      margin-top: 100px; } }

.category-description {
  font-size: 24px; }
  @media only screen and (max-width: 768px) {
    .category-description {
      font-size: 15px; } }

.flex-right {
  display: flex;
  justify-content: flex-end;
  align-self: flex-end; }

.from-bottom-animation {
  animation: fromBottom 0.5s ease-out, opacity 1s ease; }

@keyframes fromBottom {
  from {
    transform: translate(0, 50px);
    opacity: 0.1; }
  to {
    transform: translate(0, 0px);
    opacity: 1; } }

.delete-btn {
  background-color: #333;
  padding: 0px 5px 0px 5px;
  color: white;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center; }

.text-underline {
  text-decoration: underline;
  text-decoration-color: black; }

.flex {
  display: flex; }

.justify-content-center {
  justify-content: center; }

.align-items-center {
  align-items: center; }

.route-wrapper {
  position: relative; }

.route-wrapper > div {
  position: absolute; }

.flex-center {
  display: flex;
  justify-content: flex-end; }

.flex-item-center {
  align-self: center; }

.flex-item-bottom {
  align-self: flex-end; }

.edit {
  position: relative;
  z-index: 101;
  display: flex;
  align-self: flex-end;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 30px;
  border-style: solid;
  border-color: #333333;
  border-width: 1px;
  border-radius: 20px;
  margin-right: 10px;
  background-color: #333;
  color: #fff;
  box-shadow: 0px 2px 20px 2px rgba(153, 153, 153, 0.5);
  font-size: 12px;
  font-weight: 300; }
  @media only screen and (max-width: 768px) {
    .edit {
      width: 60px;
      height: 30px;
      font-size: 12px; } }

.filter {
  display: flex;
  align-self: flex-end;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 50px;
  box-shadow: 0 2px 2px 10px rgba(51, 51, 51, 0.4);
  border-style: solid;
  border-color: #333333;
  border-width: 1px;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.6);
  font-size: 15px;
  font-weight: 300; }
  @media only screen and (max-width: 768px) {
    .filter {
      width: 60px;
      height: 30px;
      font-size: 12px; } }

.shop-grid-height {
  margin-top: 10vh; }
  @media only screen and (min-width: 768px) {
    .shop-grid-height {
      height: 35vw; } }
  @media only screen and (max-width: 767px) {
    .shop-grid-height {
      height: 40vw; } }

.shop-element {
  height: 35vw;
  border-style: solid;
  border-width: 20px;
  border-color: black;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center; }
  .shop-element .image {
    width: 100%;
    height: auto; }

.shipping-info-container {
  margin: 20vh 25px 100px 25px;
  background-color: rgba(255, 255, 255, 0.3); }
  .shipping-info-container .shipping-info-header {
    font-weight: 700;
    line-height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-color: #999;
    border-width: 0 0 1px 0; }
    @media only screen and (max-width: 767px) {
      .shipping-info-container .shipping-info-header {
        background-color: #efefef; } }
  .shipping-info-container .shipping-info-category {
    font-size: 12px;
    font-weight: 700;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-color: #999;
    border-width: 0 0px 1px 0px; }
    @media only screen and (max-width: 767px) {
      .shipping-info-container .shipping-info-category {
        background-color: #efefef;
        border-width: 0 0px 1px 0px; } }
  .shipping-info-container .shipping-info-detail {
    padding: 10px 10px 0px 10px;
    border-style: solid;
    border-color: #999;
    border-width: 0 0px 1px 0; }
    @media only screen and (max-width: 767px) {
      .shipping-info-container .shipping-info-detail {
        background-color: #efefef;
        border-width: 0 0 1px 0; } }
    .shipping-info-container .shipping-info-detail p {
      font-size: 11px;
      font-weight: 300;
      line-height: 25px; }

.goods-detail {
  padding: 0;
  margin: 0 auto;
  min-height: 80vh; }
  .goods-detail .goods-detail-row {
    height: 100%; }
  .goods-detail .image-box {
    padding: 5%; }
    .goods-detail .image-box .image {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .goods-detail .description-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 0 15% 0 15%; }
    @media only screen and (min-width: 992px) {
      .goods-detail .description-div {
        height: 100%;
        padding: 0 10% 0 10%; } }
    .goods-detail .description-div .goods-text-div {
      width: 100%; }
      .goods-detail .description-div .goods-text-div .title {
        font-size: 24px;
        font-weight: 300; }
      .goods-detail .description-div .goods-text-div .subtitle {
        font-size: 20px;
        font-weight: 300; }
      .goods-detail .description-div .goods-text-div .right-align {
        display: flex;
        justify-content: flex-end;
        align-items: center; }
      .goods-detail .description-div .goods-text-div .else-text {
        padding-left: 5px;
        font-size: 14px;
        font-weight: 700; }
    .goods-detail .description-div .option {
      font-size: 13px;
      font-weight: 500;
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%; }
      .goods-detail .description-div .option .select {
        background-color: #333;
        width: 49%;
        color: white;
        margin: 10px 0px 5px 0px;
        padding: 7px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center; }
      .goods-detail .description-div .option .select:hover {
        cursor: pointer;
        opacity: 0.8;
        transition: 0.2s ease-in all; }
    .goods-detail .description-div .demand {
      font-size: 12px;
      font-weight: 400;
      background-color: white;
      border-style: solid;
      border-color: #777;
      border-width: 0.5px;
      width: 100%;
      color: #333;
      margin: 12px 0 10px 0;
      padding: 10px;
      outline: none;
      transition: 0.3s ease-in; }
    .goods-detail .description-div .contact {
      font-size: 13px;
      font-weight: 500;
      text-align: center;
      background-color: white;
      border-style: solid;
      border-color: #777;
      border-width: 0.5px;
      justify-content: center;
      align-items: center;
      width: 100%;
      color: #333;
      margin: 10px 0 10px 0;
      padding: 7px; }
      @media only screen and (max-width: 991px) {
        .goods-detail .description-div .contact {
          width: 150px; } }
  .goods-detail .goods-detail-box {
    margin-top: 20vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden; }
    .goods-detail .goods-detail-box .goods-detail-paragraph {
      white-space: pre-wrap;
      margin-bottom: 50px;
      font-weight: 500;
      font-size: 16px; }
    .goods-detail .goods-detail-box .goods-detail-image {
      max-width: 100%;
      max-height: 100%; }
  .goods-detail .goods-description {
    margin-top: 20vh;
    width: 100%;
    margin: 20vh 25px 100px 25px; }
    .goods-detail .goods-description .goods-detail-header {
      font-weight: 700;
      line-height: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-style: solid;
      border-color: #999;
      border-width: 0 0 1px 0; }
      @media only screen and (max-width: 767px) {
        .goods-detail .goods-description .goods-detail-header {
          background-color: #efefef; } }
    .goods-detail .goods-description .goods-detail-contents {
      white-space: pre-wrap;
      font-size: 12px;
      background-color: rgba(255, 255, 255, 0.3);
      font-weight: 400;
      line-height: 25px;
      padding: 10px; }

.modal-loading-div {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(51, 51, 51, 0.7);
  display: flex;
  justify-content: center;
  align-items: center; }

.hashtags-container {
  width: 100%; }
  .hashtags-container .hashtag-span {
    font-size: 12px;
    padding: 3px;
    color: #326bb5;
    font-weight: 700; }

.artwork-detail {
  padding: 0;
  margin: 0 auto;
  min-height: 80vh; }
  .artwork-detail .artwork-detail-row {
    height: 100%; }
  .artwork-detail .roomchoice {
    margin-top: 20vh;
    width: 100%;
    height: 60vw;
    overflow: hidden;
    background-color: gray; }
    .artwork-detail .roomchoice .roomchoice-image {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .artwork-detail .roomchoice .roomchoice-image-src-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      top: -52vw;
      left: 0; }
      .artwork-detail .roomchoice .roomchoice-image-src-container .roomchoice-image-src {
        max-height: 20vw;
        width: auto;
        border-style: solid;
        border-width: 0.5vw;
        border-color: #fafafa;
        box-shadow: 0 2px 10px 2px #999; }
      .artwork-detail .roomchoice .roomchoice-image-src-container .roomchoice-image-src-small {
        max-height: 15vw;
        width: auto;
        border-style: solid;
        border-width: 0.5vw;
        border-color: #fafafa;
        box-shadow: 0 2px 10px 2px #999; }
  .artwork-detail .image-box {
    padding: 15%; }
    .artwork-detail .image-box .image {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .artwork-detail .description-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 0 15% 0 15%; }
    @media only screen and (min-width: 992px) {
      .artwork-detail .description-div {
        height: 100%;
        padding: 0 10% 0 10%; } }
    .artwork-detail .description-div .artwork-text-div {
      width: 100%; }
      .artwork-detail .description-div .artwork-text-div .title {
        font-size: 24px;
        font-weight: 300; }
      .artwork-detail .description-div .artwork-text-div .subtitle {
        font-size: 20px;
        font-weight: 300; }
      .artwork-detail .description-div .artwork-text-div .else-text {
        font-size: 12px;
        font-weight: 300; }
    .artwork-detail .description-div .contact {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      background-color: #efefef;
      border-style: solid;
      border-color: #555;
      border-width: 1px;
      justify-content: center;
      align-items: center;
      width: 100%;
      color: #333;
      margin: 10px 0 10px 0;
      padding: 7px; }
      @media only screen and (max-width: 991px) {
        .artwork-detail .description-div .contact {
          width: 150px; } }
  .artwork-detail .custom-container {
    margin: 20vh 25px 100px 25px; }
    .artwork-detail .custom-container .flag-header {
      display: flex;
      line-height: 90px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-style: solid;
      border-color: #999;
      border-width: 0px 0px 1px 0px;
      font-weight: 700; }
      @media only screen and (max-width: 767px) {
        .artwork-detail .custom-container .flag-header {
          border-width: 0; } }
    .artwork-detail .custom-container .custom-flag-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: 300; }
      .artwork-detail .custom-container .custom-flag-container .flag-element {
        border-style: solid;
        border-color: #999;
        border-width: 0 0px 1px 0px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center; }
        @media only screen and (max-width: 767px) {
          .artwork-detail .custom-container .custom-flag-container .flag-element {
            border-width: 0; } }
      @media only screen and (max-width: 767px) {
        .artwork-detail .custom-container .custom-flag-container .flag-border {
          border-width: 1px 0 1px 0; } }
      .artwork-detail .custom-container .custom-flag-container .flag-border-right {
        border-right-width: 1px; }
      .artwork-detail .custom-container .custom-flag-container .flag-border-left {
        border-left-width: 1px; }

.hashtags {
  margin: 5%;
  width: 90%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  -ms-overflow-style: none; }
  .hashtags::-webkit-scrollbar {
    display: none !important; }
  .hashtags .hash-span {
    font-size: 12px;
    color: #326bb5;
    padding: 5px;
    margin: 5px; }
  .hashtags .hash-span-selected {
    font-size: 12px;
    font-weight: 700;
    color: #326bb5;
    padding: 5px;
    margin: 5px; }

.artwork-grid {
  padding: 10px;
  background-color: #efefef;
  box-shadow: 0px 2px 20px 2px rgba(153, 153, 153, 0.5); }

.artwork-element {
  font-size: 12px;
  transition: all 0.3s; }
  .artwork-element .grid-name-text {
    font-weight: 400; }

.artwork-image {
  box-shadow: 0px 0px 20px 2px rgba(153, 153, 153, 0.2);
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #efefef; }

.grid-text-box {
  padding: 10px 0 10px 5px; }

.grid-admin-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-end; }
  .grid-admin-box .grid-admin-icon {
    padding: 5px 10px 5px 10px; }

.main-slider-container {
  position: absolute;
  display: flex;
  width: 100vw;
  top: 60px; }

.slider-container {
  margin-top: 15vh;
  min-height: 100vh; }
  .slider-container .home-goods-list {
    min-height: 30vw;
    overflow-x: scroll;
    -ms-overflow-style: none; }
    .slider-container .home-goods-list::-webkit-scrollbar {
      display: none !important; }
    .slider-container .home-goods-list .home-goods {
      padding: 15px 30px 15px 0; }
      .slider-container .home-goods-list .home-goods .home-goods-textbox {
        width: 100%;
        padding: 5px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start; }
      .slider-container .home-goods-list .home-goods .home-goods-image {
        width: 35vw;
        height: 35vw;
        object-fit: cover;
        object-position: center;
        transition: 0.3s ease all; }
      .slider-container .home-goods-list .home-goods .home-goods-image:hover {
        object-fit: contain;
        transition: 0.3s ease all; }
  @media only screen and (min-width: 767px) {
    .slider-container {
      margin-top: 15vh; } }
  .slider-container .image-container-center {
    display: table;
    margin: auto; }
  .slider-container .main-slider-image {
    max-height: 45vh;
    max-width: 50vw;
    width: auto;
    box-shadow: 0 0 20px 2px rgba(153, 153, 153, 0.84); }
    @media only screen and (max-width: 1125px) {
      .slider-container .main-slider-image {
        max-height: 35vh;
        max-width: 40vw; } }
  .slider-container .btn {
    position: relative;
    z-index: 3;
    width: 150px;
    border-width: 1px;
    border-style: solid;
    border-color: #333333;
    font-size: 18px;
    color: #333333;
    text-align: center; }
  .slider-container .right-box {
    margin: 0;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    z-index: 10; }
    @media only screen and (min-width: 768px) {
      .slider-container .right-box {
        margin-left: auto;
        margin-right: auto;
        margin-top: 25vh; } }
    @media only screen and (max-width: 767px) {
      .slider-container .right-box {
        margin-top: 65vh;
        background-color: rgba(255, 255, 255, 0.6);
        padding: 3vh 0 3vh 0; } }
    .slider-container .right-box .main-buttons-container {
      width: 140px;
      display: flex;
      flex-direction: row;
      border: none;
      margin-top: 10px; }
      .slider-container .right-box .main-buttons-container .main-button-div {
        display: flex;
        justify-content: center;
        font-size: 12px;
        font-weight: 400;
        background-color: rgba(255, 255, 255, 0.5);
        padding: 4px 4px 4px 4px;
        color: #333;
        cursor: pointer; }
      .slider-container .right-box .main-buttons-container .main-button-div:hover {
        text-decoration: none;
        background-color: #333;
        color: #efefef; }
      .slider-container .right-box .main-buttons-container .right-border {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-width: 1px 1px 1px 0;
        border-style: solid;
        border-color: gray;
        transition: 0.3s ease all; }
      .slider-container .right-box .main-buttons-container .left-border {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-width: 1px;
        border-style: solid;
        border-color: gray;
        transition: 0.3s ease all; }

.introduce-box {
  z-index: 3;
  padding-top: 5vh;
  padding-bottom: 5vh;
  position: relative;
  background-color: rgba(255, 255, 255, 0.6); }

.main-background-image {
  height: 80vh; }
  @media only screen and (min-height: 1080px) {
    .main-background-image {
      height: 100vh; } }
  @media only screen and (max-width: 576px) {
    .main-background-image {
      height: 70vh; } }

@media only screen and (min-width: 1200px) {
  .temp-margin-top {
    margin-top: 30vh; } }

@media only screen and (max-width: 768px) {
  .temp-margin-top {
    margin-top: -10vh; } }

.side-text {
  color: #333333;
  text-align: center;
  font-weight: 500; }
  @media only screen and (min-width: 1600px) {
    .side-text {
      font-size: 32px; } }
  @media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .side-text {
      font-size: 24px; } }
  @media only screen and (min-width: 576px) and (max-width: 1200px) {
    .side-text {
      font-size: 20px; } }
  @media only screen and (max-width: 576px) {
    .side-text {
      font-size: 16px; } }

.side-text-sub {
  margin-top: 30px;
  color: #333;
  font-weight: 400;
  text-align: center; }
  @media only screen and (min-width: 1600px) {
    .side-text-sub {
      font-size: 25px; } }
  @media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .side-text-sub {
      font-size: 20px; } }
  @media only screen and (min-width: 576px) and (max-width: 1200px) {
    .side-text-sub {
      font-size: 15px; } }
  @media only screen and (max-width: 576px) {
    .side-text-sub {
      font-size: 12px; } }

.text-stroke {
  -webkit-text-stroke: 0.5px white; }

@keyframes top-down-animation {
  0% {
    transform: translateY(-60vh); }
  50% {
    transform: translateY(10vh); }
  100% {
    transform: translateY(0); } }

.signin-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000; }
  @media only screen and (max-width: 767px) {
    .signin-modal {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto; } }
  @media only screen and (min-width: 768px) {
    .signin-modal {
      width: 30vw;
      height: 35vw; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .signin-modal {
      width: 60vw;
      height: 60vw; } }
  @media only screen and (max-width: 575px) {
    .signin-modal {
      width: 80vw;
      height: 80vw; } }
  .signin-modal .modal-container {
    animation: top-down-animation ease-in-out 1s;
    transition: ease-in 0.3s all;
    margin: 0;
    position: relative;
    z-index: 1100;
    background-color: #efefef;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    @media only screen and (min-width: 768px) {
      .signin-modal .modal-container {
        width: 30vw;
        height: 35vw; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .signin-modal .modal-container {
        width: 60vw;
        height: 60vw; } }
    @media only screen and (max-width: 575px) {
      .signin-modal .modal-container {
        width: 80vw;
        height: 80vw; } }
    .signin-modal .modal-container .success-login {
      animation: 3s fadein_out;
      transition: 3s ease-in all; }
    .signin-modal .modal-container .signin {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 20px;
      height: 30%;
      font-size: 12px; }
    .signin-modal .modal-container .signin-btn {
      height: 20%;
      font-size: 12px;
      padding-top: 15px;
      border-top-style: solid;
      border-top-color: lightgray;
      border-top-width: 1px;
      display: flex;
      justify-content: center;
      align-items: center; }

.custom-artwork-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 1000px;
  height: 800px;
  border-radius: 20px;
  transition: ease-in 0.3s all;
  background-color: #fefefe;
  padding: 10px; }
  .custom-artwork-modal .modal-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: scroll; }
    .custom-artwork-modal .modal-container .modal-header {
      width: 100%;
      border-bottom: 1px solid #777;
      display: flex;
      justify-content: center;
      padding: 10px;
      font-size: 14px;
      color: #333; }
    .custom-artwork-modal .modal-container .modal-content {
      width: 100%;
      padding: 50px 40px 20px 40px;
      background-color: #fefefe;
      border: none;
      overflow: auto; }
      .custom-artwork-modal .modal-container .modal-content .modal-grid {
        width: 100%;
        transition: 0.1s ease-in all;
        padding: 10px;
        background-color: #efefef;
        height: auto;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        box-shadow: 0px 0px 10px 2px rgba(153, 153, 153, 0.5);
        justify-content: center;
        align-items: center; }
        .custom-artwork-modal .modal-container .modal-content .modal-grid .grid-image {
          transition: 0.1s ease-in all;
          width: 100%;
          height: 150px;
          background-color: #efefef;
          object-fit: contain; }
        .custom-artwork-modal .modal-container .modal-content .modal-grid .grid-text {
          transition: 0.1s ease-in all;
          padding: 10px 0 0 0;
          color: #333;
          font-size: 12px; }
      .custom-artwork-modal .modal-container .modal-content .refresh-btn {
        margin: 30px 0 30px 0;
        display: flex;
        justify-content: center;
        align-items: center; }
        .custom-artwork-modal .modal-container .modal-content .refresh-btn .refresh-text {
          background-color: #333;
          padding: 10px 15px 10px 15px;
          box-shadow: 0px 0px 10px 2px rgba(153, 153, 153, 0.5);
          border-radius: 30px;
          font-size: 12px;
          cursor: pointer;
          color: white; }
    .custom-artwork-modal .modal-container .modal-confirm {
      width: 100%;
      border-top: 1px solid #777;
      display: flex;
      justify-content: center;
      padding: 10px;
      font-size: 14px;
      color: #333;
      cursor: pointer; }

.alert-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 250px;
  height: 200px;
  border-radius: 20px;
  transition: ease-in 0.3s all; }
  .alert-modal .modal-container {
    margin: 0;
    background-color: white;
    border-radius: 20px;
    width: 250px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .alert-modal .modal-container .alert {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 20px;
      height: 30%;
      font-size: 12px; }
    .alert-modal .modal-container .alert-btn {
      height: 20%;
      font-size: 12px;
      padding-top: 15px;
      border-top-style: solid;
      border-top-color: lightgray;
      border-top-width: 1px;
      display: flex;
      justify-content: center;
      align-items: center; }

.option-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 200px;
  height: 400px;
  border-radius: 20px;
  transition: ease-in 0.3s all; }
  .option-modal .modal-container {
    margin: 0;
    background-color: white;
    border-radius: 20px;
    width: 200px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .option-modal .modal-container .option {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      color: gray;
      font-size: 12px; }
    .option-modal .modal-container .option-btn-box {
      height: 75px;
      font-size: 12px;
      border-top-style: solid;
      border-top-color: lightgray;
      border-top-width: 1px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .option-modal .modal-container .option-btn-full-box {
      height: 300px;
      font-size: 11px;
      border-style: solid;
      border-width: 1px; }
      .option-modal .modal-container .option-btn-full-box .option-shoes-type {
        height: 37px;
        border-top-style: solid;
        border-top-color: lightgray;
        border-top-width: 1px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
      .option-modal .modal-container .option-btn-full-box .option-shoes-size {
        height: 40px;
        padding: 5px 0px 5px 0px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
      .option-modal .modal-container .option-btn-full-box .option-shoes-size-soldout {
        height: 40px;
        padding: 5px 0px 5px 0px;
        color: lightgray;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
    .option-modal .modal-container .option-btn {
      height: 40px;
      font-size: 12px;
      border-top-style: solid;
      border-top-color: lightgray;
      border-top-width: 1px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .option-modal .modal-container .option-tshirts-btn {
      height: 55px;
      font-size: 12px;
      cursor: pointer;
      border-top-style: solid;
      border-top-color: lightgray;
      border-top-width: 1px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .option-modal .modal-container .option-tshirts-btn-soldout {
      height: 55px;
      font-size: 12px;
      color: lightgray;
      border-top-style: solid;
      border-top-color: lightgray;
      border-top-width: 1px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .option-modal .modal-container .cancel-btn {
      height: 50px;
      font-size: 12px;
      color: gray;
      border-top-style: solid;
      border-top-color: lightgray;
      border-top-width: 1px;
      display: flex;
      justify-content: center;
      align-items: center; }

.scroll-hidden {
  overflow: scroll;
  -ms-overflow-style: none; }
  .scroll-hidden::-webkit-scrollbar {
    display: none !important; }

.address-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 40vw;
  height: 30vh; }

.detail-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  animation: fadein 0.5s; }
  @media only screen and (min-width: 1600px) {
    .detail-modal {
      width: 1152px;
      height: 768px; } }
  @media only screen and (min-width: 768px) and (max-width: 1600px) {
    .detail-modal {
      width: 72vw;
      height: 48vw; } }
  @media only screen and (max-width: 767px) {
    .detail-modal {
      width: 72vw;
      height: 96vw; } }
  .detail-modal .modal-container {
    margin: 0;
    background-color: white; }
    @media only screen and (min-width: 1600px) {
      .detail-modal .modal-container {
        width: 1152px;
        height: 768px; } }
    @media only screen and (min-width: 768px) and (max-width: 1600px) {
      .detail-modal .modal-container {
        width: 72vw;
        height: 48vw; } }
    @media only screen and (max-width: 767px) {
      .detail-modal .modal-container {
        width: 72vw;
        height: 96vw; } }
  .detail-modal .modal-image-container {
    position: relative; }
    @media only screen and (min-width: 1600px) {
      .detail-modal .modal-image-container {
        width: 768px;
        height: 768px; } }
    @media only screen and (min-width: 768px) and (max-width: 1600px) {
      .detail-modal .modal-image-container {
        width: 48vw;
        height: 48vw; } }
    @media only screen and (max-width: 767px) {
      .detail-modal .modal-image-container {
        width: 72vw;
        height: 72vw; } }
    .detail-modal .modal-image-container .image {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      height: auto; }
  .detail-modal .detail {
    padding: 0px 0px 0px 0px;
    background-color: white;
    overflow-y: scroll;
    -ms-overflow-style: none; }
    .detail-modal .detail::-webkit-scrollbar {
      display: none !important; }
    @media only screen and (min-width: 1600px) {
      .detail-modal .detail {
        height: 768px; } }
    @media only screen and (min-width: 768px) and (max-width: 1600px) {
      .detail-modal .detail {
        height: 48vw; } }
    @media only screen and (max-width: 767px) {
      .detail-modal .detail {
        height: 48vw; } }
    .detail-modal .detail .padding {
      padding: 20px; }
      @media only screen and (max-width: 992px) {
        .detail-modal .detail .padding {
          padding: 15px; } }

.email-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; }
  @media only screen and (min-width: 1600px) {
    .email-modal {
      width: 768px;
      height: 768px; } }
  @media only screen and (min-width: 768px) and (max-width: 1600px) {
    .email-modal {
      width: 64vw;
      height: 48vw; } }
  @media only screen and (max-width: 768px) {
    .email-modal {
      width: 80vw;
      height: 96vw; } }
  .email-modal .modal-container {
    padding-bottom: 20px;
    font-size: 15px;
    background-color: #fff;
    border-radius: 5px; }
    @media only screen and (max-width: 768px) {
      .email-modal .modal-container {
        padding-bottom: 10px 0;
        font-size: 12px; } }
    .email-modal .modal-container .modal-description {
      padding: 10px;
      margin-top: 10px;
      opacity: 0.7; }
    .email-modal .modal-container .modal-image {
      width: 30%;
      height: auto;
      margin: auto; }
    .email-modal .modal-container .modal-header {
      font-size: 18px; }
      @media only screen and (max-width: 768px) {
        .email-modal .modal-container .modal-header {
          font-size: 13px; } }
    .email-modal .modal-container .modal-body {
      display: flex;
      flex-direction: column; }
      .email-modal .modal-container .modal-body > form {
        margin-top: 40px;
        display: flex;
        flex-direction: column; }
        .email-modal .modal-container .modal-body > form .modal-input {
          border: none;
          border-bottom: 1px solid #efefef; }
        .email-modal .modal-container .modal-body > form .modal-input + .modal-input,
        .email-modal .modal-container .modal-body > form .modal-message {
          margin-top: 30px; }
        .email-modal .modal-container .modal-body > form .submitButton {
          border: none;
          margin-top: 30px; }
        @media only screen and (max-width: 768px) {
          .email-modal .modal-container .modal-body > form {
            margin-top: 20px; }
            .email-modal .modal-container .modal-body > form .modal-input + .modal-input,
            .email-modal .modal-container .modal-body > form .modal-message {
              margin-top: 10px; } }
    .email-modal .modal-container .w100p {
      width: 100%; }
    .email-modal .modal-container .h100px {
      font-weight: 700;
      border-style: solid;
      border-width: 0px;
      border-color: lightgray; }
    .email-modal .modal-container .border-bottom {
      border-bottom-width: 0.5px; }
    .email-modal .modal-container .border-top {
      border-top-width: 0.5px; }
    .email-modal .modal-container .height-rest {
      overflow-y: scroll;
      background-color: #fff; }
      @media only screen and (min-width: 1600px) {
        .email-modal .modal-container .height-rest {
          width: 768px;
          height: 668px; } }
      @media only screen and (min-width: 768px) and (max-width: 1600px) {
        .email-modal .modal-container .height-rest {
          width: 64vw;
          height: 42vw; } }
      @media only screen and (max-width: 768px) {
        .email-modal .modal-container .height-rest {
          width: 80vw;
          height: 90vw; } }

.upload-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  animation: fadein 0.5s; }
  @media only screen and (min-width: 1600px) {
    .upload-modal {
      width: 768px;
      height: 768px; } }
  @media only screen and (min-width: 768px) and (max-width: 1600px) {
    .upload-modal {
      width: 64vw;
      height: 48vw; } }
  @media only screen and (max-width: 768px) {
    .upload-modal {
      width: 80vw;
      height: 96vw; } }
  .upload-modal .modal-container {
    font-size: 15px;
    background-color: #efefef;
    border-radius: 5px; }
    .upload-modal .modal-container .w100p {
      width: 100%; }
    .upload-modal .modal-container .h100px {
      font-weight: 500;
      height: 50px;
      border-style: solid;
      border-width: 0px;
      border-color: lightgray; }
    .upload-modal .modal-container .border-bottom {
      border-bottom-width: 0.5px; }
    .upload-modal .modal-container .border-top {
      border-top-width: 0.5px; }
    .upload-modal .modal-container .height-rest {
      overflow-y: scroll;
      background-color: #efefef; }
      @media only screen and (min-width: 1600px) {
        .upload-modal .modal-container .height-rest {
          width: 768px;
          height: 668px; } }
      @media only screen and (min-width: 768px) and (max-width: 1600px) {
        .upload-modal .modal-container .height-rest {
          width: 64vw;
          height: 42vw; } }
      @media only screen and (max-width: 768px) {
        .upload-modal .modal-container .height-rest {
          width: 80vw;
          height: 90vw; } }
    .upload-modal .modal-container .category-btn {
      width: 90px;
      height: 45px;
      color: #777777;
      display: flex;
      justify-content: center;
      align-items: center; }
    .upload-modal .modal-container .category-selected {
      font-weight: 700;
      border-width: 2px;
      border-color: #333333;
      color: #333333; }
    .upload-modal .modal-container .upload-detail {
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: scroll;
      -ms-overflow-style: none;
      /* named upload */ }
      .upload-modal .modal-container .upload-detail::-webkit-scrollbar {
        display: none !important; }
      .upload-modal .modal-container .upload-detail span {
        font-size: 12px; }
      .upload-modal .modal-container .upload-detail .filebox input {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0; }
      .upload-modal .modal-container .upload-detail .image-display {
        max-width: 200px;
        height: auto;
        max-height: 200px; }
        @media only screen and (max-width: 768px) {
          .upload-modal .modal-container .upload-detail .image-display {
            max-width: 60vw;
            height: auto;
            max-height: 60vw; } }
      .upload-modal .modal-container .upload-detail .filebox label {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 220px;
        height: 220px;
        padding: 0.5em 0.75em;
        color: #777;
        font-size: 30px;
        font-weight: 300;
        line-height: normal;
        vertical-align: middle;
        background-color: #eeeeee;
        cursor: pointer;
        box-shadow: 0px 0px 10px 2px rgba(153, 153, 153, 0.84); }
        @media only screen and (max-width: 768px) {
          .upload-modal .modal-container .upload-detail .filebox label {
            width: 60vw;
            height: 60vw; } }
      .upload-modal .modal-container .upload-detail .filebox .upload-name {
        display: inline-block;
        padding: 0.5em 0.75em;
        /* label의 패딩값과 일치 */
        font-size: inherit;
        font-family: inherit;
        line-height: normal;
        vertical-align: middle;
        background-color: #f5f5f5;
        border: 1px solid #ebebeb;
        border-bottom-color: #e2e2e2;
        border-radius: 0.25em;
        -webkit-appearance: none;
        /* 네이티브 외형 감추기 */
        -moz-appearance: none;
        appearance: none; }
      .upload-modal .modal-container .upload-detail .textbox {
        background-color: #efefef;
        border-style: solid;
        border-width: 0px;
        border-bottom-width: 0.5px;
        border-color: lightgray;
        box-shadow: none;
        padding-left: 10px;
        margin-bottom: 30px; }
      .upload-modal .modal-container .upload-detail .input-text {
        font-size: 12px; }
      .upload-modal .modal-container .upload-detail .numberbox {
        width: 75px;
        height: 30px;
        margin-left: 10px;
        margin-right: 10px; }
      .upload-modal .modal-container .upload-detail .datebox {
        width: 150px;
        height: 30px;
        margin-left: 10px;
        margin-right: 10px; }
      .upload-modal .modal-container .upload-detail .image-title {
        width: 100%;
        height: 30px; }
      .upload-modal .modal-container .upload-detail .image-description {
        width: 100%;
        height: 100px;
        padding: 10px; }
      .upload-modal .modal-container .upload-detail .textbox:focus {
        outline: none;
        border-bottom-width: 1px;
        border-color: #326bb5; }
      .upload-modal .modal-container .upload-detail .inputbox-container {
        display: flex;
        background-color: #efefef;
        flex-direction: column;
        vertical-align: middle;
        margin-bottom: 20px; }
        .upload-modal .modal-container .upload-detail .inputbox-container .inputbox-element {
          height: 30px;
          display: flex;
          flex-direction: row;
          align-items: center; }
          .upload-modal .modal-container .upload-detail .inputbox-container .inputbox-element .inputbox-button {
            width: 18px;
            height: 18px;
            border-radius: 9px;
            border-style: solid;
            border-color: #aaa;
            border-width: 2px;
            margin-right: 5px;
            display: flex;
            justify-content: center;
            align-items: center; }
            .upload-modal .modal-container .upload-detail .inputbox-container .inputbox-element .inputbox-button .activated {
              width: 10px;
              height: 10px;
              border-radius: 5px;
              background-color: #326bb5;
              border-width: 0; }
          .upload-modal .modal-container .upload-detail .inputbox-container .inputbox-element .btn-text {
            color: #aaa;
            font-size: 12px; }
    @media only screen and (min-width: 1600px) {
      .upload-modal .modal-container {
        width: 768px;
        height: 768px; } }
    @media only screen and (min-width: 768px) and (max-width: 1600px) {
      .upload-modal .modal-container {
        width: 64vw;
        height: 48vw; } }
    @media only screen and (max-width: 768px) {
      .upload-modal .modal-container {
        width: 80vw;
        height: 96vw; } }

.image-icon-box {
  display: flex;
  flex-direction: column; }

.main-img-item {
  height: 30vh;
  width: auto;
  margin-bottom: 10px;
  border-style: solid;
  border-width: 10px;
  border-color: #efefef;
  box-shadow: 0 10px 20px 0px rgba(51, 51, 51, 0.5);
  transition: 0.5s ease-in all; }

.img-item {
  max-height: 15vh;
  max-width: 150px;
  margin-bottom: 10px;
  box-shadow: 0 10px 20px 0px rgba(51, 51, 51, 0.5);
  transition: 0.5s ease-in all; }

.item-container {
  width: 200px;
  text-align: center; }

.div-item {
  display: flex;
  padding: 15px;
  justify-content: center;
  align-items: center;
  flex-direction: row; }

.image-text {
  margin-bottom: 30px;
  width: 90%; }

.image-text-input {
  width: 100%;
  border-color: lightgray;
  border-width: 0.5px;
  padding: 10px;
  font-size: 12px;
  height: 70px;
  outline: none; }

.weight900 {
  font-weight: 700;
  white-space: pre-wrap; }

.preview-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  overflow: scroll;
  padding: 3vw 0 3vw 0; }

@keyframes appear {
  from {
    transform: translateX(-50%) scaleX(0.3); }
  to {
    transform: translateX(0) scaleX(1); } }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadein_out {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 105;
  animation: ease-in 0.3s fadein;
  background-color: rgba(33, 33, 33, 0.8); }

.test-border {
  border-width: 5px;
  border-style: solid;
  border-color: red; }

.notice_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 90%;
  max-width: 960px;
  margin: 0 auto; }

/* Column */
.notice_column {
  flex-basis: 33.33333%;
  width: 33.33333%;
  padding: 0 10px;
  box-sizing: border-box; }
  @media (max-width: 900px) {
    .notice_column {
      flex-basis: 50%;
      width: 50%; } }
  @media (max-width: 600px) {
    .notice_column {
      flex-basis: 100%;
      width: 100%; } }

/* Article (Component) */
.notice_article {
  background: #fff;
  margin: 0 0 20px;
  padding: 20px;
  border-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: 0.3s ease; }
  .notice_article:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.2); }
  .notice_article:active {
    box-shadow: none;
    transform-origin: center;
    transform: scale(0.98); }
  .notice_article__category {
    display: inline-block;
    padding: 8px 10px;
    margin: 0 0 10px;
    color: #fff;
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 0.075rem;
    text-transform: uppercase; }
  .notice_article__excerpt {
    color: #666;
    line-height: 1.5rem;
    font-size: 0.875rem; }
  .notice_article__title {
    margin: 0 0 10px;
    color: #444;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem; }

.video-body {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
  overflow-x: hidden; }

.fullscreen-video-wrap {
  position: absolute;
  top: 0;
  left: 0;
  overflow-x: scroll;
  -ms-overflow-style: none;
  width: 99vw;
  height: 99vh; }
  .fullscreen-video-wrap::-webkit-scrollbar {
    display: none !important; }

.video-box {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden; }

.fullscreen-video-wrap video {
  position: relative;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 20%; }

.header-content {
  margin: auto;
  z-index: 2; }
  @media only screen and (max-width: 770px) {
    .header-content img {
      display: none; } }
  @media only screen and (max-width: 770px) {
    .header-content {
      margin-top: 120px; } }
  @media only screen and (max-width: 600px) {
    .header-content .displayN {
      display: none; } }

.header-content h1 {
  font-size: 50px;
  margin-bottom: 0; }

.header-content p {
  font-size: 1.5rem;
  display: block;
  padding-bottom: 2rem; }

.cart-container {
  width: 100%;
  padding: 20px; }
  .cart-container .bold {
    font-weight: 700; }
  .cart-container .cart {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 5px; }
    .cart-container .cart .cart-header {
      width: 100%;
      color: #333;
      font-size: 14px;
      font-weight: 300;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 20px 20px 0px 20px; }
      .cart-container .cart .cart-header .cart-remove-all {
        font-size: 10px;
        background-color: #efefef;
        font-weight: 700;
        color: darkgray;
        padding: 5px 10px 5px 10px; }
    .cart-container .cart .cart-body {
      width: 100%;
      margin: 10px;
      padding: 10px; }
      .cart-container .cart .cart-body .cart-element {
        display: flex;
        flex-direction: row;
        border-top-style: solid;
        border-top-width: 1px;
        border-top-color: lightgray;
        padding: 20px 10px 20px 10px; }
        .cart-container .cart .cart-body .cart-element .cart-image {
          width: 20%; }
          .cart-container .cart .cart-body .cart-element .cart-image img {
            width: 100%;
            height: auto; }
        .cart-container .cart .cart-body .cart-element .cart-description {
          width: 70%;
          padding: 20px;
          display: flex;
          flex-direction: column;
          font-size: 12px;
          color: #333; }
        .cart-container .cart .cart-body .cart-element .cart-remove {
          width: 100px;
          display: flex;
          justify-content: flex-end;
          align-items: center; }
          .cart-container .cart .cart-body .cart-element .cart-remove .remove-btn {
            text-align: center;
            height: 25px;
            font-weight: 700;
            font-size: 10px;
            background-color: #efefef;
            color: darkgray;
            padding: 5px; }
      .cart-container .cart .cart-body .cart-price {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%; }
        @media only screen and (max-width: 767px) {
          .cart-container .cart .cart-body .cart-price {
            flex-direction: column;
            align-items: flex-end; } }
        .cart-container .cart .cart-body .cart-price div {
          width: 25%;
          display: flex;
          flex-direction: row;
          justify-items: center;
          align-items: center;
          margin: 10px 3% 15px 10px;
          font-size: 12px;
          color: darkgray; }
          @media only screen and (max-width: 767px) {
            .cart-container .cart .cart-body .cart-price div {
              margin: 0;
              width: 100%;
              justify-content: flex-end; } }
          .cart-container .cart .cart-body .cart-price div input {
            width: 40px;
            height: 20px;
            font-size: 12px;
            font-weight: 700;
            text-align: center;
            outline: none;
            border-style: none;
            border-color: lightgray;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            background-color: #fafafa;
            margin: 3px;
            margin-left: 10px;
            padding: 3px; }
          .cart-container .cart .cart-body .cart-price div input::-webkit-outer-spin-button,
          .cart-container .cart .cart-body .cart-price div input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0; }
  .cart-container .cart-summary {
    padding: 20px;
    display: flex;
    flex-direction: column; }
    .cart-container .cart-summary .cart-summary-header {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      padding: 10px;
      border-bottom-style: solid;
      border-bottom-width: 1px; }
    .cart-container .cart-summary .cart-summary-price {
      display: flex;
      padding: 20px 10px 20px 10px;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 700; }
    .cart-container .cart-summary .cart-order {
      background-color: #444;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-top: 20px;
      padding: 10px;
      font-size: 16px;
      font-weight: 700; }

.video-btn {
  background: #000;
  color: #e5e5e5;
  font-size: 1rem;
  padding: 1rem 2rem;
  text-decoration: none;
  margin: auto; }
  @media only screen and (max-width: 960px) {
    .video-btn {
      font-size: 0.8rem;
      padding: 0.8rem 1.6rem; } }
  @media only screen and (max-width: 560px) {
    .video-btn {
      margin-top: 20px; } }

.video-section {
  margin: 50px 0 0 0;
  position: relative; }

.viewPointContainer {
  display: relative;
  z-index: 10;
  margin-bottom: 50px; }

.video-section-b {
  background: #333;
  color: #fff; }

.section-img {
  display: relative;
  z-index: 1000;
  margin: auto; }

@media (max-width: 960px) {
  .video-container {
    padding-right: 3rem;
    padding-left: 3rem; } }

.tag-container {
  margin-top: 43px;
  background: 0 0;
  text-align: center;
  max-width: 1200px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;
  text-align: center; }

.tag-button {
  display: inline-block;
  padding: 0.3em 1em;
  text-decoration: none;
  color: #333;
  border: solid 2px #fff;
  border-radius: 3px;
  transition: 0.4s;
  font-weight: 600; }

.tag-button:hover {
  color: #777777; }

.admin-category {
  margin-top: 15vh;
  height: 50px;
  display: flex;
  flex-direction: row; }
  .admin-category .admin-category-element {
    padding: 5px 10px 5px 10px;
    color: gray;
    font-weight: 300; }

.custom {
  margin-top: 200px;
  width: 100%;
  min-height: 90vh;
  background-color: white;
  color: #555;
  display: flex;
  justify-content: center;
  align-items: flex-start; }
  .custom .custom-demand {
    max-width: 100%;
    margin: 30px;
    height: 100px;
    border: solid 1px #efefef;
    font-size: 13px;
    padding: 10px; }
  .custom .custom-demand:focus {
    outline: none; }
  .custom .custom-row {
    width: 100%; }
  .custom .custom-header {
    font-size: 15px;
    color: #333;
    height: 60px;
    font-weight: 400;
    border-bottom: solid 1px #999;
    padding: 20px 0px 20px 10px; }
  .custom .custom-step-header {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    padding: 10px;
    color: #333;
    font-weight: 500;
    font-size: 15px; }
    .custom .custom-step-header .header-sub {
      font-size: 12px;
      color: #777;
      margin-left: 10px; }
  .custom .custom-step-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative; }
  .custom .custom-step {
    min-height: 150px;
    display: flex;
    flex-direction: column;
    padding: 10px; }
    .custom .custom-step .add-cart-wrapper {
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: flex-end;
      padding: 20px 40px 20px 40px; }
    .custom .custom-step .next-btn {
      width: 120px;
      height: 40px;
      border-radius: 10px;
      font-size: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #333;
      color: white;
      border: solid 1px #333; }
    .custom .custom-step .option-container {
      width: 100%;
      height: 50px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center; }
      .custom .custom-step .option-container .option-artwork {
        padding: 5px 10px 5px 10px;
        color: #555;
        border: solid 1px #777;
        margin: 10px;
        font-size: 12px;
        cursor: pointer; }
      .custom .custom-step .option-container .option-text {
        display: flex;
        flex-direction: row;
        padding: 5px 10px 5px 10px;
        color: #555;
        border: solid 1px #777;
        margin: 10px;
        font-size: 12px;
        cursor: pointer; }
        .custom .custom-step .option-container .option-text .option-text-input {
          transition: 0.2s ease-in all;
          outline: none;
          border: none;
          border-bottom: 1px solid #999; }
        .custom .custom-step .option-container .option-text .option-text-confirm {
          background-color: #333;
          padding: 0px 5px 0px 5px;
          color: white;
          border-radius: 20px; }
    .custom .custom-step .custom-custom-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      min-height: 70vh; }
      .custom .custom-step .custom-custom-container .custom-paper {
        position: relative;
        width: 100%;
        height: 700px;
        background-color: #fefefe; }
      .custom .custom-step .custom-custom-container .custom-background {
        object-fit: contain;
        width: 100%;
        height: 100%; }
      .custom .custom-step .custom-custom-container .custom-draggable {
        border: solid red 1px; }
      .custom .custom-step .custom-custom-container .custom-image-wrapper {
        position: absolute;
        top: 0;
        left: -100px;
        padding: 50px; }
      .custom .custom-step .custom-custom-container .custom-text-wrapper {
        position: absolute;
        top: 0;
        left: -50px;
        padding: 50px; }
      .custom .custom-step .custom-custom-container .custom-center {
        width: 100px;
        height: 100px; }
      .custom .custom-step .custom-custom-container .controller-container {
        width: 100%;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        color: black; }
      .custom .custom-step .custom-custom-container .drag {
        color: black; }
  .custom .custom-category-btn {
    padding: 5px 10px 5px 10px;
    font-size: 12px;
    font-weight: 400;
    margin: 10px;
    color: gray;
    border: 1px solid lightgray; }

.custom-total {
  width: 100%;
  display: flex;
  min-height: 10vh;
  flex-direction: column;
  margin: 40px;
  border: solid 1px #efefef; }
  .custom-total .flex1 {
    flex: 1; }
  .custom-total .flex2 {
    flex: 2; }
  .custom-total .flex3 {
    flex: 3; }
  .custom-total .flex4 {
    flex: 4; }
  .custom-total .header {
    display: flex;
    flex-direction: row;
    height: 50px;
    padding: 15px;
    font-size: 13px;
    justify-content: flex-start;
    align-items: center;
    background-color: #efefef; }
  .custom-total .justify-content-end {
    display: flex;
    justify-content: flex-end; }
  .custom-total .justify-content-center {
    display: flex;
    justify-content: center; }
  .custom-total .custom-total-element {
    display: flex;
    font-size: 13px;
    flex-direction: row;
    width: 100%;
    min-height: 50px;
    padding: 15px;
    justify-content: flex-start;
    align-items: center;
    background-color: #fefefe;
    border-bottom: solid 1px #efefef; }
  .custom-total .detail {
    display: flex;
    font-size: 13px;
    flex-direction: row;
    height: 200px;
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: lightgray; }
  .custom-total .footer {
    flex-direction: row;
    height: 50px;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 15px; }

.custom-box-ex {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0);
  color: #fff; }

.admin {
  width: 100%;
  min-height: 90vh;
  background-color: white;
  color: #555;
  display: flex;
  justify-content: center;
  align-items: flex-start; }
  .admin .admin-row {
    width: 100%; }
    .admin .admin-row .admin-category-header {
      font-size: 15px;
      color: #333;
      height: 60px;
      font-weight: 400;
      border-bottom: solid 1px #999;
      padding: 20px 0px 20px 10px; }
    .admin .admin-row .admin-slider-container {
      min-height: 40vh;
      width: 100%;
      background-color: lightgray; }
      .admin .admin-row .admin-slider-container .admin-slider-scroll {
        min-width: 75vw;
        height: 100%;
        background-color: gray; }
    .admin .admin-row .admin-test-container {
      min-height: 60vh;
      width: 100%;
      background-color: #efefef;
      display: flex;
      justify-content: center;
      align-items: center; }
    .admin .admin-row .soldout-header {
      padding: 15px 0 10px 5px;
      font-size: 14px;
      color: #333; }
    .admin .admin-row .soldout-sub-header {
      margin-top: 30px;
      padding: 10px 0 10px 5px;
      font-size: 12px;
      color: #333; }
    .admin .admin-row .soldout-sub-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin: 5px;
      margin-top: 0;
      padding: 0 10px 20px 10px; }
      .admin .admin-row .soldout-sub-container .soldout-btn {
        position: relative;
        flex: 1;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        margin: 1%;
        font-size: 12px;
        color: #333;
        border: solid lightgray 1px; }
        .admin .admin-row .soldout-sub-container .soldout-btn .soldout-screen {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.6);
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          color: red; }
    .admin .admin-row .soldout-shoes-container {
      margin: 5px;
      margin-top: 0;
      padding: 0 10px 20px 10px; }
      .admin .admin-row .soldout-shoes-container .soldout-btn {
        position: relative;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        margin: 1%;
        font-size: 12px;
        color: #333;
        border: solid lightgray 1px; }
        .admin .admin-row .soldout-shoes-container .soldout-btn .soldout-screen {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.6);
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          color: red; }
  .admin .soldout-save-btn {
    margin: 30px 0 0 0;
    padding: 10px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: solid 1px lightgray;
    border-bottom: solid 1px lightgray;
    color: #166cbc;
    font-weight: 500;
    font-size: 14px; }

.max300 {
  max-width: 300px; }

.gallery-detail {
  padding: 0;
  margin-top: 100px;
  display: contents; }
  .gallery-detail .paddingTop {
    padding-top: 100px; }
    .gallery-detail .paddingTop .rightPadding {
      padding-top: 100px; }
      @media only screen and (max-width: 576px) {
        .gallery-detail .paddingTop .rightPadding {
          padding-top: 30px; } }
  .gallery-detail .image-box {
    height: 60vh; }
    .gallery-detail .image-box .image {
      width: auto;
      height: 90%;
      object-fit: contain;
      object-position: center; }
    .gallery-detail .image-box .padding {
      margin: 20px; }
      .gallery-detail .image-box .padding h5 {
        font-size: 10px;
        margin-bottom: 15px;
        font-weight: 700; }
      .gallery-detail .image-box .padding .detailRow {
        border-bottom: 1px #000 sol; }

.galleryModalTitle {
  font-size: 10px;
  display: inline-block;
  font-weight: 700;
  color: #333; }

.galleryModalSubtitle {
  font-size: 20px;
  font-weight: 300; }

.galleryModalDetail {
  font-size: 10px; }

.galleryIcons {
  display: inline-flex; }
  .galleryIcons .iconContainer {
    height: 40px;
    width: 40px;
    padding: auto;
    margin: 5px; }
  .galleryIcons .modalDot {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    font-weight: 500; }

.GoShopBtn {
  margin-top: 3px;
  color: #000;
  background: #fff;
  font-weight: bold;
  font-size: 11px;
  margin-left: 5px;
  border-radius: 4px;
  border: solid 1px #e5e5e5;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  padding: 4px 8px 4px 8px; }

.company-info {
  width: 100%;
  background-color: darkgray;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px 0 0 0; }
  .company-info span {
    font-size: 10px;
    font-weight: 500;
    padding-left: 10px; }

.card {
  font-weight: 500;
  width: 100%;
  border-radius: 0; }
  .card .footer-icon {
    margin: 0 10px 0 10px; }
  .card .card-header {
    font-weight: 500;
    display: flex;
    font-size: 12px;
    justify-content: center;
    background-color: white;
    color: #333;
    border-width: 0;
    outline: none; }
  .card .card-body {
    background-color: white;
    font-size: 10px;
    border-width: 0;
    display: flex;
    flex-direction: column;
    align-items: center; }

.footer-div {
  background-color: #efefef; }

.footer-container {
  padding-top: 80px;
  padding-bottom: 80px; }
  .footer-container .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .footer-container .footer .flex2 {
      flex: 2; }
    .footer-container .footer .footer-element {
      display: flex;
      align-items: center;
      width: 18%;
      display: flex;
      flex-direction: column;
      font-size: 10px;
      font-weight: 500; }
      .footer-container .footer .footer-element .left-align-div {
        display: flex;
        flex-direction: column;
        align-items: flex-start; }
      .footer-container .footer .footer-element .footer-image {
        width: 70px;
        height: auto;
        padding-bottom: 5px; }
      .footer-container .footer .footer-element .highlighted {
        margin-bottom: 5px;
        font-size: 12px;
        font-weight: 700; }
      .footer-container .footer .footer-element .indented {
        padding-left: 5px; }
    .footer-container .footer .footer-iconbox {
      display: flex;
      flex-direction: row; }
    .footer-container .footer .footer-icon {
      padding: 5px; }
    .footer-container .footer .footer-height {
      margin-left: auto;
      margin-right: auto; }
      @media only screen and (max-width: 1200px) {
        .footer-container .footer .footer-height {
          padding: 10px; } }
      .footer-container .footer .footer-height .paddingForLine {
        padding-left: 30px; }
        @media only screen and (max-width: 400px) {
          .footer-container .footer .footer-height .paddingForLine {
            padding-left: 12px; } }
    .footer-container .footer hr {
      background: #e7e7e7;
      width: 100%;
      height: 1px; }
    .footer-container .footer label {
      padding: 0;
      margin-top: 2.5vh;
      margin-bottom: 2.5vh; }
      @media only screen and (max-width: 1200px) {
        .footer-container .footer label {
          font-size: 13px; } }
      @media only screen and (max-width: 576px) {
        .footer-container .footer label {
          margin-top: 1vh;
          margin-bottom: 1vh;
          font-size: 13px; } }
      @media only screen and (max-width: 400px) {
        .footer-container .footer label {
          font-size: 11px; } }
    .footer-container .footer p {
      font-size: 11px;
      margin: -1px; }
      @media only screen and (max-width: 835px) and (min-width: 400px) {
        .footer-container .footer p {
          font-size: 0.6em; } }
      @media only screen and (max-width: 400px) {
        .footer-container .footer p {
          font-size: 0.3em; } }
      @media only screen and (max-width: 400px) {
        .footer-container .footer p span {
          display: none; } }
    @media only screen and (max-width: 768px) {
      .footer-container .footer .aboutUs {
        display: none; } }

.rightIcon {
  width: 34px;
  padding: 0;
  top: 85%;
  right: 20px;
  position: fixed;
  z-index: 99999;
  opacity: 0; }
  .rightIcon .rightContainer a .kakao {
    position: absolute;
    left: 0;
    top: 0px;
    width: 34px;
    height: 34px; }
  .rightIcon .rightContainer .rightEmail > img {
    width: 34px;
    height: 34px; }
  @media only screen and (max-width: 768px) {
    .rightIcon {
      top: 75%; } }

.instaCol {
  margin: auto; }

.instaContainer {
  flex-direction: column;
  justify-content: space-between;
  border-radius: 1px;
  margin: 10px 20px 10px 20px;
  height: auto; }

.myLeftContainer {
  padding: 15px; }
  .myLeftContainer h1 {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1;
    margin-bottom: 25px; }

.myRightContainer {
  background-color: #fff;
  padding: 30 30; }
  .myRightContainer .rightRow .eachInfo {
    padding: 30px; }
    .myRightContainer .rightRow .eachInfo h1 {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1;
      margin-bottom: 25px; }
    .myRightContainer .rightRow .eachInfo label {
      margin-top: 30px;
      top: -1.68067rem;
      font-weight: 500;
      font-size: 1.0084rem;
      color: #313131;
      opacity: 1;
      margin-bottom: 10px; }
    .myRightContainer .rightRow .eachInfo p {
      text-align: center;
      font-weight: 300;
      font-size: 0.9rem; }
    .myRightContainer .rightRow .eachInfo input {
      margin: auto;
      display: block;
      width: 70%;
      padding: 0.5rem 1.5rem;
      height: 25px;
      border-radius: 0;
      appearance: none;
      font-weight: 300;
      font-size: 1.0084rem;
      line-height: 1;
      color: #313131;
      letter-spacing: 0.026em;
      border: 1px solid #e7e7e7; }
    .myRightContainer .rightRow .eachInfo button {
      display: inline-block;
      box-sizing: border-box;
      padding: 4px 8px;
      border: 1px solid #d1d1d1;
      border-radius: 0px;
      font-size: 12px;
      line-height: 25px;
      font-weight: normal;
      text-decoration: none;
      vertical-align: middle;
      word-spacing: -0.5px;
      letter-spacing: 0px;
      text-align: center;
      white-space: nowrap;
      color: #222;
      background-color: #fff; }

.reform-description {
  width: 100%; }
  .reform-description .reform-detail-header {
    font-weight: 700;
    line-height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-color: #999;
    border-width: 0 0 1px 0; }
    @media only screen and (max-width: 767px) {
      .reform-description .reform-detail-header {
        background-color: #efefef; } }
  .reform-description .reform-detail-contents {
    white-space: pre-wrap;
    font-size: 12px;
    font-weight: 300;
    line-height: 25px;
    padding: 10px; }
  .reform-description .reform-textarea {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 40vw;
    height: 200px;
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background: 0 0 rgba(0, 0, 0, 0.07);
    border-image: none;
    border-radius: 6px 6px 6px 6px;
    border-style: none solid solid none;
    border-width: medium 1px 1px medium;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
    color: #555555;
    font-size: 12px;
    line-height: 1.4em;
    padding: 10px 8px;
    transition: background-color 0.2s ease 0s; }
    @media only screen and (max-width: 960px) {
      .reform-description .reform-textarea {
        width: 70vw; } }
    .reform-description .reform-textarea p {
      padding: 40px;
      line-height: 30px; }
  .reform-description .reform-textarea:focus {
    background: none repeat scroll 0 0 #ffffff;
    outline-width: 0; }

.reform-border-container {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.reform-border {
  color: #333333;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 500;
  margin: 10px 20px 50px 20px;
  padding: 20px 0px 20px 30px;
  border-bottom: solid 0.5px #ccc; }

.whiteSpace {
  white-space: pre-wrap;
  position: 'relative';
  z-index: 1; }

.goodsHigh-list {
  min-height: 30vw;
  overflow-x: scroll;
  -ms-overflow-style: none; }
  .goodsHigh-list::-webkit-scrollbar {
    display: none !important; }
  .goodsHigh-list .goodsHigh {
    padding: 15px 30px 15px 0; }
    .goodsHigh-list .goodsHigh .goodsHigh-textbox {
      width: 100%;
      padding: 5px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start; }
    .goodsHigh-list .goodsHigh .goodsHigh-image {
      width: 20vw;
      height: 20vw;
      object-fit: cover;
      object-position: center;
      transition: 0.3s ease all; }
    .goodsHigh-list .goodsHigh .goodsHigh-image:hover {
      object-fit: contain;
      transition: 0.3s ease all; }

.goods-component {
  margin: 0; }
  .goods-component .component-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 2vh;
    font-weight: 500; }

.soldout-circle {
  background: #f00;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  float: right;
  margin-top: 3.5px; }

.work-total {
  font-size: 30px;
  font-style: italic; }

.reactjs-coverflow_Main {
  position: relative;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.1);
  overflow: hidden; }

.reactjs-coverflow_Coverflow {
  width: 100%;
  height: 100%;
  display: flex;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-perspective: 500px;
  perspective: 500px;
  align-items: center;
  transition: transform 0.7s; }

.reactjs-coverflow_Element {
  position: relative;
  -webkit-box-reflect: below 1px -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1) 20%, transparent 30%, transparent);
  margin: auto 20px;
  transition: transform 0.7s; }

.search_body {
  width: 300px;
  margin: 0 auto;
  position: relative;
  display: inline-block;
  float: right;
  padding: 0 0 0 40px;
  background: #fff;
  border: none;
  border-radius: 5px; }
  .search_body input,
  .search_body button {
    position: relative;
    width: 200px;
    height: 45px;
    padding: 0;
    display: inline-block;
    float: left; }
  .search_body input {
    color: #666;
    z-index: 2;
    border: 0 none; }
  .search_body input:focus {
    outline: 0 none; }
  .search_body input:focus + button {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s; }
  .search_body input:focus + button .fa {
    -webkit-transform: translate(0px, 0);
    -ms-transform: translate(0px, 0);
    transform: translate(0px, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    color: #fff; }
  .search_body button {
    z-index: 1;
    width: 45px;
    border: 0 none;
    background: #29abe2;
    cursor: pointer;
    -webkit-transform: translate(-50px, 0);
    -ms-transform: translate(-50px, 0);
    transform: translate(-50px, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s; }
  .search_body .fa-search {
    font-size: 1.4rem;
    color: #29abe2;
    z-index: 3;
    top: 25%;
    -webkit-transform: translate(-190px, 0);
    -ms-transform: translate(-190px, 0);
    transform: translate(-190px, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out; }

.home_wrap {
  width: 70%;
  height: 70vh;
  background: linear-gradient(to right, #ddd6f3, #faaca8);
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  left: 0;
  display: table; }
  @media only screen and (max-width: 1040px) {
    .home_wrap {
      width: 100%;
      background: none; } }

.home_cont {
  width: 100%;
  max-width: 1400px;
  padding-left: 11%;
  display: table-cell;
  vertical-align: middle;
  margin: 0 auto;
  transition: all 1s; }

.home_background {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 70vh;
  top: 0px;
  right: 0px;
  width: 50%;
  height: 70vh;
  background-image: url("../images/home_image.png"); }
  @media only screen and (max-width: 1040px) {
    .home_background {
      width: 100%;
      opacity: 0.4; } }

.home_wrap .home_h_line {
  width: 80px;
  height: 1px;
  background-color: black;
  display: inline-block;
  margin: 5px 15px; }

.home_text1 {
  display: inline-block;
  color: black;
  font-weight: 300;
  font-size: 14px; }

.home_text2 {
  color: black;
  font-weight: 400;
  font-size: 90px;
  padding-top: 30px; }
  @media only screen and (max-width: 1600px) {
    .home_text2 {
      font-size: 70px; } }
  @media only screen and (max-width: 1040px) {
    .home_text2 {
      font-size: 70px;
      font-weight: 350; } }
  @media only screen and (max-width: 768px) {
    .home_text2 {
      font-size: 40px;
      font-weight: 350; } }

.home_text3 {
  color: #fff;
  font-weight: 500;
  font-size: 30px;
  line-height: 24px;
  padding-top: 20px; }
  @media only screen and (min-width: 576px) and (max-width: 1200px) {
    .home_text3 {
      font-size: 11px;
      font-weight: 200;
      padding-top: 15px; } }
  @media only screen and (max-width: 576px) {
    .home_text3 {
      font-size: 8px;
      padding-top: 5px;
      font-weight: 300; } }

.home_button_wrap {
  width: auto;
  height: auto;
  margin-top: 40px;
  display: inline-flex; }
  @media only screen and (max-width: 768px) {
    .home_button_wrap {
      margin-top: 60px; } }

.home_button {
  width: 10vw;
  padding: 18px 0;
  color: #212f3d;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 0.5s ease;
  position: relative;
  z-index: 2; }
  @media only screen and (max-width: 1040px) {
    .home_button {
      width: 20vw;
      font-weight: 700;
      margin: 2px;
      margin-top: 0px; } }
  @media only screen and (max-width: 600px) {
    .home_button {
      font-size: 15px; } }

.home_button:hover {
  color: rgba(255, 255, 255, 0.85);
  box-shadow: rgba(120, 116, 131, 0.7) 0 0px 0px 40px inset; }

.home_button.a > a {
  background-color: #fff;
  color: #a09f9f; }

@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,900);
@import url(https://fonts.googleapis.com/css?family=Lora|Roboto:400,500);
@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700|Playfair+Display:400,400i,700,900);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,900);
@import url(https://fonts.googleapis.com/css?family=Lora|Roboto:400,500);
body {
  margin: 0;
  padding: 0;
  background-color:#efefef;
  font-family: "Apple SD Gothic Neo", "Malgun Gothic", AppleGothic,  BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p{
  font-family: "Apple SD Gothic Neo", "Malgun Gothic", AppleGothic,  BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@charset "UTF-8";
.nav-container {
  position: fixed;
  z-index: 104;
  top: 10px;
  left: 1vw;
  padding: 0;
  margin: 0;
  width: 98vw;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 10px;
  transition: 0.3s ease all; }
  .nav-container .icon-box {
    width: 50px;
    padding: 10px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center; }
  .nav-container .logo-container {
    display: flex; }
  .nav-container .logo-center {
    justify-content: center;
    align-items: center;
    display: flex; }
  .nav-container .navi-container {
    display: flex;
    justify-content: flex-end; }
    .nav-container .navi-container .nav-element {
      width: 55px;
      font-size: 15px;
      font-weight: 500;
      color: #333; }

.nav-draw-container {
  position: fixed;
  z-index: 1000;
  top: 10px;
  left: 1vw;
  padding: 0;
  margin: 0;
  width: 98vw;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 10px 0 10px;
  transition: ease-in 0.3s all; }
  .nav-draw-container .nav-btn-box {
    position: absolute;
    top: 50px;
    left: 0;
    display: none;
    height: 250px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-animation: fadein ease-in 0.3s;
            animation: fadein ease-in 0.3s; }
    .nav-draw-container .nav-btn-box .nav-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      height: 14.28%;
      width: 100%; }
    .nav-draw-container .nav-btn-box .nav-btn:active {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      color: white;
      background-color: gray;
      height: 14.28%;
      width: 100%; }
  .nav-draw-container .draw-header {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
  .nav-draw-container .icon-box {
    width: 50px;
    padding: 10px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center; }
  .nav-draw-container .logo-container {
    display: flex; }
  .nav-draw-container .logo-center {
    justify-content: center;
    align-items: center;
    display: flex; }
  .nav-draw-container .navi-container {
    display: flex;
    justify-content: flex-end; }
    .nav-draw-container .navi-container .nav-element {
      width: 55px;
      font-size: 12px;
      font-weight: 500;
      color: #333; }

.image-icon-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

.custom-icon {
  padding: 5px 10px 5px 10px; }

.nav-toggle-container {
  position: fixed;
  z-index: 104;
  top: 10px;
  left: 1vw;
  padding: 0;
  margin: 0;
  width: 98vw;
  height: 50px;
  background-color: rgba(255, 255, 255, 0);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 20px;
  transition: 0.3s ease all; }
  .nav-toggle-container .logo-container {
    display: flex; }
  .nav-toggle-container .logo-center {
    justify-content: center;
    align-items: center;
    display: flex; }
  .nav-toggle-container .navi-container {
    display: flex;
    justify-content: flex-end; }
    .nav-toggle-container .navi-container .nav-element {
      width: 55px;
      font-size: 12px;
      font-weight: 300;
      color: #333; }

.shoes-images-container {
  margin: 20vh 25px 20vh 25px;
  background-color: rgba(255, 255, 255, 0.3); }
  @media only screen and (max-width: 575px) {
    .shoes-images-container {
      margin: 20vh 10px 20vh 10px; } }
  .shoes-images-container .shoes-images-row {
    width: 100%;
    padding: 15px 0 15px 0; }
  .shoes-images-container .shoes-images-header {
    padding: 20px;
    font-size: 15px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center; }
  .shoes-images-container .shoes-images-col {
    padding: 20px;
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    height: 50vw; }
    @media only screen and (max-width: 575px) {
      .shoes-images-container .shoes-images-col {
        padding: 20px 5px 20px 5px; } }
    .shoes-images-container .shoes-images-col .shoes-images-div {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .shoes-images-container .shoes-images-col .shoes-images-div .shoes-image {
        width: 100%;
        height: 100%;
        margin-bottom: 10px;
        object-fit: contain; }
      .shoes-images-container .shoes-images-col .shoes-images-div .shoes-name {
        font-weight: 400;
        font-size: 12px;
        color: #333; }

.shirts-size-table {
  margin: 20vh 10px 100px 10px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  flex: 1 1; }
  @media only screen and (max-width: 575px) {
    .shirts-size-table {
      font-size: 10px; } }
  .shirts-size-table .shirts-size-header {
    flex: 1 1;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    line-height: 90px;
    border-bottom: solid 1px #999;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 16px; }
  .shirts-size-table .shirts-size-table-row {
    padding: 5px 0 5px 0;
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 5px 0 5px 0; }
  .shirts-size-table .shirts-size-table-col {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center; }
  .shirts-size-table .shirts-size-notice {
    padding: 15px; }
    @media only screen and (max-width: 575px) {
      .shirts-size-table .shirts-size-notice {
        padding: 5px; } }

.goods-category {
  width: 100%;
  height: 50px;
  color: gray;
  margin: 5vh 0 2vh 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer; }
  .goods-category .goods-category-element {
    cursor: pointer;
    color: gray;
    font-size: 12px;
    padding: 5px 10px 5px 10px; }
    @media only screen and (max-width: 575px) {
      .goods-category .goods-category-element {
        padding: 7px; } }
  .goods-category .goods-category-element-activated {
    cursor: pointer;
    color: #333;
    font-size: 12px;
    padding: 5px 10px 5px 10px; }
    @media only screen and (max-width: 575px) {
      .goods-category .goods-category-element-activated {
        padding: 7px; } }

.grid-name-text {
  color: #333;
  font-weight: 300; }

.grid-name-text-bold {
  color: #333;
  font-weight: bold; }

.grid-price-text {
  font-weight: 00;
  margin: 0;
  padding: 0 5px 0 0;
  text-align: right; }

.goods {
  font-size: 12px; }

.home-icon {
  margin: 0 10px 0 0; }

.home-component {
  padding: 3%;
  margin: 7% 0 7% 0; }
  .home-component .component-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 2vh;
    font-weight: 500; }

.home-notice {
  display: flex;
  flex-direction: column;
  height: 350px;
  margin: 20px 0 20px 0; }
  .home-notice .home-notice-image {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 40vh; }
  .home-notice .home-notice-text-box {
    text-align: center;
    margin: 10px 0 10px 0;
    display: flex;
    flex-direction: column; }
    .home-notice .home-notice-text-box .detail {
      font-size: 12px;
      font-weight: 500;
      color: #1db9b9; }

.home-goods-list {
  min-height: 30vw;
  overflow-x: scroll;
  -ms-overflow-style: none; }
  .home-goods-list::-webkit-scrollbar {
    display: none !important; }
  .home-goods-list .home-goods {
    padding: 15px 30px 15px 0; }
    .home-goods-list .home-goods .home-goods-textbox {
      width: 100%;
      padding: 5px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start; }
    .home-goods-list .home-goods .home-goods-image {
      width: 25vw;
      height: 25vw;
      object-fit: cover;
      object-position: center;
      transition: 0.3s ease all; }
    .home-goods-list .home-goods .home-goods-image:hover {
      object-fit: contain;
      transition: 0.3s ease all; }

.tag-test .ReactTags__tagInput {
  font-size: 12px;
  padding: 5px;
  margin: 10px;
  width: 200px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: gray; }

.tag-test .ReactTags__tagInputField {
  border-style: none;
  background-color: #efefef;
  width: 100%;
  font-size: 12px;
  outline: none; }

.tag-test .ReactTags__selected {
  margin: 5px;
  margin-bottom: 15px; }

.tag-test .ReactTags__selected .ReactTags__tag {
  color: #326bb5;
  font-size: 12;
  font-weight: 700;
  padding: 10px;
  margin: 5px; }

.shop-header {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between; }
  .shop-header .header {
    width: 100%;
    font-size: 24px; }
    @media only screen and (max-width: 768px) {
      .shop-header .header {
        width: 100%;
        font-size: 15px; } }
  .shop-header .btn-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end; }
    .shop-header .btn-box .shop-btn {
      cursor: pointer;
      position: relative;
      z-index: 101;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 30px;
      border-color: #777;
      background-color: white;
      border-style: solid;
      border-width: 0.5px;
      border-radius: 30px;
      margin-right: 10px;
      color: #333;
      font-size: 12px;
      font-weight: 500; }
      @media only screen and (max-width: 768px) {
        .shop-header .btn-box .shop-btn {
          width: 60px;
          height: 30px;
          font-size: 12px; } }

.shop-margin-top {
  margin-top: 100px; }
  @media only screen and (max-width: 768px) {
    .shop-margin-top {
      margin-top: 100px; } }

.category-description {
  font-size: 24px; }
  @media only screen and (max-width: 768px) {
    .category-description {
      font-size: 15px; } }

.flex-right {
  display: flex;
  justify-content: flex-end;
  align-self: flex-end; }

.from-bottom-animation {
  -webkit-animation: fromBottom 0.5s ease-out, opacity 1s ease;
          animation: fromBottom 0.5s ease-out, opacity 1s ease; }

@-webkit-keyframes fromBottom {
  from {
    -webkit-transform: translate(0, 50px);
            transform: translate(0, 50px);
    opacity: 0.1; }
  to {
    -webkit-transform: translate(0, 0px);
            transform: translate(0, 0px);
    opacity: 1; } }

@keyframes fromBottom {
  from {
    -webkit-transform: translate(0, 50px);
            transform: translate(0, 50px);
    opacity: 0.1; }
  to {
    -webkit-transform: translate(0, 0px);
            transform: translate(0, 0px);
    opacity: 1; } }

.delete-btn {
  background-color: #333;
  padding: 0px 5px 0px 5px;
  color: white;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center; }

.text-underline {
  text-decoration: underline;
  -webkit-text-decoration-color: black;
          text-decoration-color: black; }

.flex {
  display: flex; }

.justify-content-center {
  justify-content: center; }

.align-items-center {
  align-items: center; }

.route-wrapper {
  position: relative; }

.route-wrapper > div {
  position: absolute; }

.flex-center {
  display: flex;
  justify-content: flex-end; }

.flex-item-center {
  align-self: center; }

.flex-item-bottom {
  align-self: flex-end; }

.edit {
  position: relative;
  z-index: 101;
  display: flex;
  align-self: flex-end;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 30px;
  border-style: solid;
  border-color: #333333;
  border-width: 1px;
  border-radius: 20px;
  margin-right: 10px;
  background-color: #333;
  color: #fff;
  box-shadow: 0px 2px 20px 2px rgba(153, 153, 153, 0.5);
  font-size: 12px;
  font-weight: 300; }
  @media only screen and (max-width: 768px) {
    .edit {
      width: 60px;
      height: 30px;
      font-size: 12px; } }

.filter {
  display: flex;
  align-self: flex-end;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 50px;
  box-shadow: 0 2px 2px 10px rgba(51, 51, 51, 0.4);
  border-style: solid;
  border-color: #333333;
  border-width: 1px;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.6);
  font-size: 15px;
  font-weight: 300; }
  @media only screen and (max-width: 768px) {
    .filter {
      width: 60px;
      height: 30px;
      font-size: 12px; } }

.shop-grid-height {
  margin-top: 10vh; }
  @media only screen and (min-width: 768px) {
    .shop-grid-height {
      height: 35vw; } }
  @media only screen and (max-width: 767px) {
    .shop-grid-height {
      height: 40vw; } }

.shop-element {
  height: 35vw;
  border-style: solid;
  border-width: 20px;
  border-color: black;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center; }
  .shop-element .image {
    width: 100%;
    height: auto; }

.shipping-info-container {
  margin: 20vh 25px 100px 25px;
  background-color: rgba(255, 255, 255, 0.3); }
  .shipping-info-container .shipping-info-header {
    font-weight: 700;
    line-height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-color: #999;
    border-width: 0 0 1px 0; }
    @media only screen and (max-width: 767px) {
      .shipping-info-container .shipping-info-header {
        background-color: #efefef; } }
  .shipping-info-container .shipping-info-category {
    font-size: 12px;
    font-weight: 700;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-color: #999;
    border-width: 0 0px 1px 0px; }
    @media only screen and (max-width: 767px) {
      .shipping-info-container .shipping-info-category {
        background-color: #efefef;
        border-width: 0 0px 1px 0px; } }
  .shipping-info-container .shipping-info-detail {
    padding: 10px 10px 0px 10px;
    border-style: solid;
    border-color: #999;
    border-width: 0 0px 1px 0; }
    @media only screen and (max-width: 767px) {
      .shipping-info-container .shipping-info-detail {
        background-color: #efefef;
        border-width: 0 0 1px 0; } }
    .shipping-info-container .shipping-info-detail p {
      font-size: 11px;
      font-weight: 300;
      line-height: 25px; }

.goods-detail {
  padding: 0;
  margin: 0 auto;
  min-height: 80vh; }
  .goods-detail .goods-detail-row {
    height: 100%; }
  .goods-detail .image-box {
    padding: 5%; }
    .goods-detail .image-box .image {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .goods-detail .description-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 0 15% 0 15%; }
    @media only screen and (min-width: 992px) {
      .goods-detail .description-div {
        height: 100%;
        padding: 0 10% 0 10%; } }
    .goods-detail .description-div .goods-text-div {
      width: 100%; }
      .goods-detail .description-div .goods-text-div .title {
        font-size: 24px;
        font-weight: 300; }
      .goods-detail .description-div .goods-text-div .subtitle {
        font-size: 20px;
        font-weight: 300; }
      .goods-detail .description-div .goods-text-div .right-align {
        display: flex;
        justify-content: flex-end;
        align-items: center; }
      .goods-detail .description-div .goods-text-div .else-text {
        padding-left: 5px;
        font-size: 14px;
        font-weight: 700; }
    .goods-detail .description-div .option {
      font-size: 13px;
      font-weight: 500;
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%; }
      .goods-detail .description-div .option .select {
        background-color: #333;
        width: 49%;
        color: white;
        margin: 10px 0px 5px 0px;
        padding: 7px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center; }
      .goods-detail .description-div .option .select:hover {
        cursor: pointer;
        opacity: 0.8;
        transition: 0.2s ease-in all; }
    .goods-detail .description-div .demand {
      font-size: 12px;
      font-weight: 400;
      background-color: white;
      border-style: solid;
      border-color: #777;
      border-width: 0.5px;
      width: 100%;
      color: #333;
      margin: 12px 0 10px 0;
      padding: 10px;
      outline: none;
      transition: 0.3s ease-in; }
    .goods-detail .description-div .contact {
      font-size: 13px;
      font-weight: 500;
      text-align: center;
      background-color: white;
      border-style: solid;
      border-color: #777;
      border-width: 0.5px;
      justify-content: center;
      align-items: center;
      width: 100%;
      color: #333;
      margin: 10px 0 10px 0;
      padding: 7px; }
      @media only screen and (max-width: 991px) {
        .goods-detail .description-div .contact {
          width: 150px; } }
  .goods-detail .goods-detail-box {
    margin-top: 20vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden; }
    .goods-detail .goods-detail-box .goods-detail-paragraph {
      white-space: pre-wrap;
      margin-bottom: 50px;
      font-weight: 500;
      font-size: 16px; }
    .goods-detail .goods-detail-box .goods-detail-image {
      max-width: 100%;
      max-height: 100%; }
  .goods-detail .goods-description {
    margin-top: 20vh;
    width: 100%;
    margin: 20vh 25px 100px 25px; }
    .goods-detail .goods-description .goods-detail-header {
      font-weight: 700;
      line-height: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-style: solid;
      border-color: #999;
      border-width: 0 0 1px 0; }
      @media only screen and (max-width: 767px) {
        .goods-detail .goods-description .goods-detail-header {
          background-color: #efefef; } }
    .goods-detail .goods-description .goods-detail-contents {
      white-space: pre-wrap;
      font-size: 12px;
      background-color: rgba(255, 255, 255, 0.3);
      font-weight: 400;
      line-height: 25px;
      padding: 10px; }

.modal-loading-div {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(51, 51, 51, 0.7);
  display: flex;
  justify-content: center;
  align-items: center; }

.hashtags-container {
  width: 100%; }
  .hashtags-container .hashtag-span {
    font-size: 12px;
    padding: 3px;
    color: #326bb5;
    font-weight: 700; }

.artwork-detail {
  padding: 0;
  margin: 0 auto;
  min-height: 80vh; }
  .artwork-detail .artwork-detail-row {
    height: 100%; }
  .artwork-detail .roomchoice {
    margin-top: 20vh;
    width: 100%;
    height: 60vw;
    overflow: hidden;
    background-color: gray; }
    .artwork-detail .roomchoice .roomchoice-image {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .artwork-detail .roomchoice .roomchoice-image-src-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      top: -52vw;
      left: 0; }
      .artwork-detail .roomchoice .roomchoice-image-src-container .roomchoice-image-src {
        max-height: 20vw;
        width: auto;
        border-style: solid;
        border-width: 0.5vw;
        border-color: #fafafa;
        box-shadow: 0 2px 10px 2px #999; }
      .artwork-detail .roomchoice .roomchoice-image-src-container .roomchoice-image-src-small {
        max-height: 15vw;
        width: auto;
        border-style: solid;
        border-width: 0.5vw;
        border-color: #fafafa;
        box-shadow: 0 2px 10px 2px #999; }
  .artwork-detail .image-box {
    padding: 15%; }
    .artwork-detail .image-box .image {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .artwork-detail .description-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 0 15% 0 15%; }
    @media only screen and (min-width: 992px) {
      .artwork-detail .description-div {
        height: 100%;
        padding: 0 10% 0 10%; } }
    .artwork-detail .description-div .artwork-text-div {
      width: 100%; }
      .artwork-detail .description-div .artwork-text-div .title {
        font-size: 24px;
        font-weight: 300; }
      .artwork-detail .description-div .artwork-text-div .subtitle {
        font-size: 20px;
        font-weight: 300; }
      .artwork-detail .description-div .artwork-text-div .else-text {
        font-size: 12px;
        font-weight: 300; }
    .artwork-detail .description-div .contact {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      background-color: #efefef;
      border-style: solid;
      border-color: #555;
      border-width: 1px;
      justify-content: center;
      align-items: center;
      width: 100%;
      color: #333;
      margin: 10px 0 10px 0;
      padding: 7px; }
      @media only screen and (max-width: 991px) {
        .artwork-detail .description-div .contact {
          width: 150px; } }
  .artwork-detail .custom-container {
    margin: 20vh 25px 100px 25px; }
    .artwork-detail .custom-container .flag-header {
      display: flex;
      line-height: 90px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-style: solid;
      border-color: #999;
      border-width: 0px 0px 1px 0px;
      font-weight: 700; }
      @media only screen and (max-width: 767px) {
        .artwork-detail .custom-container .flag-header {
          border-width: 0; } }
    .artwork-detail .custom-container .custom-flag-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: 300; }
      .artwork-detail .custom-container .custom-flag-container .flag-element {
        border-style: solid;
        border-color: #999;
        border-width: 0 0px 1px 0px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center; }
        @media only screen and (max-width: 767px) {
          .artwork-detail .custom-container .custom-flag-container .flag-element {
            border-width: 0; } }
      @media only screen and (max-width: 767px) {
        .artwork-detail .custom-container .custom-flag-container .flag-border {
          border-width: 1px 0 1px 0; } }
      .artwork-detail .custom-container .custom-flag-container .flag-border-right {
        border-right-width: 1px; }
      .artwork-detail .custom-container .custom-flag-container .flag-border-left {
        border-left-width: 1px; }

.hashtags {
  margin: 5%;
  width: 90%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  -ms-overflow-style: none; }
  .hashtags::-webkit-scrollbar {
    display: none !important; }
  .hashtags .hash-span {
    font-size: 12px;
    color: #326bb5;
    padding: 5px;
    margin: 5px; }
  .hashtags .hash-span-selected {
    font-size: 12px;
    font-weight: 700;
    color: #326bb5;
    padding: 5px;
    margin: 5px; }

.artwork-grid {
  padding: 10px;
  background-color: #efefef;
  box-shadow: 0px 2px 20px 2px rgba(153, 153, 153, 0.5); }

.artwork-element {
  font-size: 12px;
  transition: all 0.3s; }
  .artwork-element .grid-name-text {
    font-weight: 400; }

.artwork-image {
  box-shadow: 0px 0px 20px 2px rgba(153, 153, 153, 0.2);
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #efefef; }

.grid-text-box {
  padding: 10px 0 10px 5px; }

.grid-admin-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-end; }
  .grid-admin-box .grid-admin-icon {
    padding: 5px 10px 5px 10px; }

.main-slider-container {
  position: absolute;
  display: flex;
  width: 100vw;
  top: 60px; }

.slider-container {
  margin-top: 15vh;
  min-height: 100vh; }
  .slider-container .home-goods-list {
    min-height: 30vw;
    overflow-x: scroll;
    -ms-overflow-style: none; }
    .slider-container .home-goods-list::-webkit-scrollbar {
      display: none !important; }
    .slider-container .home-goods-list .home-goods {
      padding: 15px 30px 15px 0; }
      .slider-container .home-goods-list .home-goods .home-goods-textbox {
        width: 100%;
        padding: 5px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start; }
      .slider-container .home-goods-list .home-goods .home-goods-image {
        width: 35vw;
        height: 35vw;
        object-fit: cover;
        object-position: center;
        transition: 0.3s ease all; }
      .slider-container .home-goods-list .home-goods .home-goods-image:hover {
        object-fit: contain;
        transition: 0.3s ease all; }
  @media only screen and (min-width: 767px) {
    .slider-container {
      margin-top: 15vh; } }
  .slider-container .image-container-center {
    display: table;
    margin: auto; }
  .slider-container .main-slider-image {
    max-height: 45vh;
    max-width: 50vw;
    width: auto;
    box-shadow: 0 0 20px 2px rgba(153, 153, 153, 0.84); }
    @media only screen and (max-width: 1125px) {
      .slider-container .main-slider-image {
        max-height: 35vh;
        max-width: 40vw; } }
  .slider-container .btn {
    position: relative;
    z-index: 3;
    width: 150px;
    border-width: 1px;
    border-style: solid;
    border-color: #333333;
    font-size: 18px;
    color: #333333;
    text-align: center; }
  .slider-container .right-box {
    margin: 0;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    z-index: 10; }
    @media only screen and (min-width: 768px) {
      .slider-container .right-box {
        margin-left: auto;
        margin-right: auto;
        margin-top: 25vh; } }
    @media only screen and (max-width: 767px) {
      .slider-container .right-box {
        margin-top: 65vh;
        background-color: rgba(255, 255, 255, 0.6);
        padding: 3vh 0 3vh 0; } }
    .slider-container .right-box .main-buttons-container {
      width: 140px;
      display: flex;
      flex-direction: row;
      border: none;
      margin-top: 10px; }
      .slider-container .right-box .main-buttons-container .main-button-div {
        display: flex;
        justify-content: center;
        font-size: 12px;
        font-weight: 400;
        background-color: rgba(255, 255, 255, 0.5);
        padding: 4px 4px 4px 4px;
        color: #333;
        cursor: pointer; }
      .slider-container .right-box .main-buttons-container .main-button-div:hover {
        text-decoration: none;
        background-color: #333;
        color: #efefef; }
      .slider-container .right-box .main-buttons-container .right-border {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-width: 1px 1px 1px 0;
        border-style: solid;
        border-color: gray;
        transition: 0.3s ease all; }
      .slider-container .right-box .main-buttons-container .left-border {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-width: 1px;
        border-style: solid;
        border-color: gray;
        transition: 0.3s ease all; }

.introduce-box {
  z-index: 3;
  padding-top: 5vh;
  padding-bottom: 5vh;
  position: relative;
  background-color: rgba(255, 255, 255, 0.6); }

.main-background-image {
  height: 80vh; }
  @media only screen and (min-height: 1080px) {
    .main-background-image {
      height: 100vh; } }
  @media only screen and (max-width: 576px) {
    .main-background-image {
      height: 70vh; } }

@media only screen and (min-width: 1200px) {
  .temp-margin-top {
    margin-top: 30vh; } }

@media only screen and (max-width: 768px) {
  .temp-margin-top {
    margin-top: -10vh; } }

.side-text {
  color: #333333;
  text-align: center;
  font-weight: 500; }
  @media only screen and (min-width: 1600px) {
    .side-text {
      font-size: 32px; } }
  @media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .side-text {
      font-size: 24px; } }
  @media only screen and (min-width: 576px) and (max-width: 1200px) {
    .side-text {
      font-size: 20px; } }
  @media only screen and (max-width: 576px) {
    .side-text {
      font-size: 16px; } }

.side-text-sub {
  margin-top: 30px;
  color: #333;
  font-weight: 400;
  text-align: center; }
  @media only screen and (min-width: 1600px) {
    .side-text-sub {
      font-size: 25px; } }
  @media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .side-text-sub {
      font-size: 20px; } }
  @media only screen and (min-width: 576px) and (max-width: 1200px) {
    .side-text-sub {
      font-size: 15px; } }
  @media only screen and (max-width: 576px) {
    .side-text-sub {
      font-size: 12px; } }

.text-stroke {
  -webkit-text-stroke: 0.5px white; }

@-webkit-keyframes top-down-animation {
  0% {
    -webkit-transform: translateY(-60vh);
            transform: translateY(-60vh); }
  50% {
    -webkit-transform: translateY(10vh);
            transform: translateY(10vh); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes top-down-animation {
  0% {
    -webkit-transform: translateY(-60vh);
            transform: translateY(-60vh); }
  50% {
    -webkit-transform: translateY(10vh);
            transform: translateY(10vh); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.signin-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000; }
  @media only screen and (max-width: 767px) {
    .signin-modal {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto; } }
  @media only screen and (min-width: 768px) {
    .signin-modal {
      width: 30vw;
      height: 35vw; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .signin-modal {
      width: 60vw;
      height: 60vw; } }
  @media only screen and (max-width: 575px) {
    .signin-modal {
      width: 80vw;
      height: 80vw; } }
  .signin-modal .modal-container {
    -webkit-animation: top-down-animation ease-in-out 1s;
            animation: top-down-animation ease-in-out 1s;
    transition: ease-in 0.3s all;
    margin: 0;
    position: relative;
    z-index: 1100;
    background-color: #efefef;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    @media only screen and (min-width: 768px) {
      .signin-modal .modal-container {
        width: 30vw;
        height: 35vw; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .signin-modal .modal-container {
        width: 60vw;
        height: 60vw; } }
    @media only screen and (max-width: 575px) {
      .signin-modal .modal-container {
        width: 80vw;
        height: 80vw; } }
    .signin-modal .modal-container .success-login {
      -webkit-animation: 3s fadein_out;
              animation: 3s fadein_out;
      transition: 3s ease-in all; }
    .signin-modal .modal-container .signin {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 20px;
      height: 30%;
      font-size: 12px; }
    .signin-modal .modal-container .signin-btn {
      height: 20%;
      font-size: 12px;
      padding-top: 15px;
      border-top-style: solid;
      border-top-color: lightgray;
      border-top-width: 1px;
      display: flex;
      justify-content: center;
      align-items: center; }

.custom-artwork-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 1000px;
  height: 800px;
  border-radius: 20px;
  transition: ease-in 0.3s all;
  background-color: #fefefe;
  padding: 10px; }
  .custom-artwork-modal .modal-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: scroll; }
    .custom-artwork-modal .modal-container .modal-header {
      width: 100%;
      border-bottom: 1px solid #777;
      display: flex;
      justify-content: center;
      padding: 10px;
      font-size: 14px;
      color: #333; }
    .custom-artwork-modal .modal-container .modal-content {
      width: 100%;
      padding: 50px 40px 20px 40px;
      background-color: #fefefe;
      border: none;
      overflow: auto; }
      .custom-artwork-modal .modal-container .modal-content .modal-grid {
        width: 100%;
        transition: 0.1s ease-in all;
        padding: 10px;
        background-color: #efefef;
        height: auto;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        box-shadow: 0px 0px 10px 2px rgba(153, 153, 153, 0.5);
        justify-content: center;
        align-items: center; }
        .custom-artwork-modal .modal-container .modal-content .modal-grid .grid-image {
          transition: 0.1s ease-in all;
          width: 100%;
          height: 150px;
          background-color: #efefef;
          object-fit: contain; }
        .custom-artwork-modal .modal-container .modal-content .modal-grid .grid-text {
          transition: 0.1s ease-in all;
          padding: 10px 0 0 0;
          color: #333;
          font-size: 12px; }
      .custom-artwork-modal .modal-container .modal-content .refresh-btn {
        margin: 30px 0 30px 0;
        display: flex;
        justify-content: center;
        align-items: center; }
        .custom-artwork-modal .modal-container .modal-content .refresh-btn .refresh-text {
          background-color: #333;
          padding: 10px 15px 10px 15px;
          box-shadow: 0px 0px 10px 2px rgba(153, 153, 153, 0.5);
          border-radius: 30px;
          font-size: 12px;
          cursor: pointer;
          color: white; }
    .custom-artwork-modal .modal-container .modal-confirm {
      width: 100%;
      border-top: 1px solid #777;
      display: flex;
      justify-content: center;
      padding: 10px;
      font-size: 14px;
      color: #333;
      cursor: pointer; }

.alert-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 250px;
  height: 200px;
  border-radius: 20px;
  transition: ease-in 0.3s all; }
  .alert-modal .modal-container {
    margin: 0;
    background-color: white;
    border-radius: 20px;
    width: 250px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .alert-modal .modal-container .alert {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 20px;
      height: 30%;
      font-size: 12px; }
    .alert-modal .modal-container .alert-btn {
      height: 20%;
      font-size: 12px;
      padding-top: 15px;
      border-top-style: solid;
      border-top-color: lightgray;
      border-top-width: 1px;
      display: flex;
      justify-content: center;
      align-items: center; }

.option-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 200px;
  height: 400px;
  border-radius: 20px;
  transition: ease-in 0.3s all; }
  .option-modal .modal-container {
    margin: 0;
    background-color: white;
    border-radius: 20px;
    width: 200px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .option-modal .modal-container .option {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      color: gray;
      font-size: 12px; }
    .option-modal .modal-container .option-btn-box {
      height: 75px;
      font-size: 12px;
      border-top-style: solid;
      border-top-color: lightgray;
      border-top-width: 1px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .option-modal .modal-container .option-btn-full-box {
      height: 300px;
      font-size: 11px;
      border-style: solid;
      border-width: 1px; }
      .option-modal .modal-container .option-btn-full-box .option-shoes-type {
        height: 37px;
        border-top-style: solid;
        border-top-color: lightgray;
        border-top-width: 1px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
      .option-modal .modal-container .option-btn-full-box .option-shoes-size {
        height: 40px;
        padding: 5px 0px 5px 0px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
      .option-modal .modal-container .option-btn-full-box .option-shoes-size-soldout {
        height: 40px;
        padding: 5px 0px 5px 0px;
        color: lightgray;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
    .option-modal .modal-container .option-btn {
      height: 40px;
      font-size: 12px;
      border-top-style: solid;
      border-top-color: lightgray;
      border-top-width: 1px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .option-modal .modal-container .option-tshirts-btn {
      height: 55px;
      font-size: 12px;
      cursor: pointer;
      border-top-style: solid;
      border-top-color: lightgray;
      border-top-width: 1px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .option-modal .modal-container .option-tshirts-btn-soldout {
      height: 55px;
      font-size: 12px;
      color: lightgray;
      border-top-style: solid;
      border-top-color: lightgray;
      border-top-width: 1px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .option-modal .modal-container .cancel-btn {
      height: 50px;
      font-size: 12px;
      color: gray;
      border-top-style: solid;
      border-top-color: lightgray;
      border-top-width: 1px;
      display: flex;
      justify-content: center;
      align-items: center; }

.scroll-hidden {
  overflow: scroll;
  -ms-overflow-style: none; }
  .scroll-hidden::-webkit-scrollbar {
    display: none !important; }

.address-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 40vw;
  height: 30vh; }

.detail-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-animation: fadein 0.5s;
          animation: fadein 0.5s; }
  @media only screen and (min-width: 1600px) {
    .detail-modal {
      width: 1152px;
      height: 768px; } }
  @media only screen and (min-width: 768px) and (max-width: 1600px) {
    .detail-modal {
      width: 72vw;
      height: 48vw; } }
  @media only screen and (max-width: 767px) {
    .detail-modal {
      width: 72vw;
      height: 96vw; } }
  .detail-modal .modal-container {
    margin: 0;
    background-color: white; }
    @media only screen and (min-width: 1600px) {
      .detail-modal .modal-container {
        width: 1152px;
        height: 768px; } }
    @media only screen and (min-width: 768px) and (max-width: 1600px) {
      .detail-modal .modal-container {
        width: 72vw;
        height: 48vw; } }
    @media only screen and (max-width: 767px) {
      .detail-modal .modal-container {
        width: 72vw;
        height: 96vw; } }
  .detail-modal .modal-image-container {
    position: relative; }
    @media only screen and (min-width: 1600px) {
      .detail-modal .modal-image-container {
        width: 768px;
        height: 768px; } }
    @media only screen and (min-width: 768px) and (max-width: 1600px) {
      .detail-modal .modal-image-container {
        width: 48vw;
        height: 48vw; } }
    @media only screen and (max-width: 767px) {
      .detail-modal .modal-image-container {
        width: 72vw;
        height: 72vw; } }
    .detail-modal .modal-image-container .image {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      height: auto; }
  .detail-modal .detail {
    padding: 0px 0px 0px 0px;
    background-color: white;
    overflow-y: scroll;
    -ms-overflow-style: none; }
    .detail-modal .detail::-webkit-scrollbar {
      display: none !important; }
    @media only screen and (min-width: 1600px) {
      .detail-modal .detail {
        height: 768px; } }
    @media only screen and (min-width: 768px) and (max-width: 1600px) {
      .detail-modal .detail {
        height: 48vw; } }
    @media only screen and (max-width: 767px) {
      .detail-modal .detail {
        height: 48vw; } }
    .detail-modal .detail .padding {
      padding: 20px; }
      @media only screen and (max-width: 992px) {
        .detail-modal .detail .padding {
          padding: 15px; } }

.email-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; }
  @media only screen and (min-width: 1600px) {
    .email-modal {
      width: 768px;
      height: 768px; } }
  @media only screen and (min-width: 768px) and (max-width: 1600px) {
    .email-modal {
      width: 64vw;
      height: 48vw; } }
  @media only screen and (max-width: 768px) {
    .email-modal {
      width: 80vw;
      height: 96vw; } }
  .email-modal .modal-container {
    padding-bottom: 20px;
    font-size: 15px;
    background-color: #fff;
    border-radius: 5px; }
    @media only screen and (max-width: 768px) {
      .email-modal .modal-container {
        padding-bottom: 10px 0;
        font-size: 12px; } }
    .email-modal .modal-container .modal-description {
      padding: 10px;
      margin-top: 10px;
      opacity: 0.7; }
    .email-modal .modal-container .modal-image {
      width: 30%;
      height: auto;
      margin: auto; }
    .email-modal .modal-container .modal-header {
      font-size: 18px; }
      @media only screen and (max-width: 768px) {
        .email-modal .modal-container .modal-header {
          font-size: 13px; } }
    .email-modal .modal-container .modal-body {
      display: flex;
      flex-direction: column; }
      .email-modal .modal-container .modal-body > form {
        margin-top: 40px;
        display: flex;
        flex-direction: column; }
        .email-modal .modal-container .modal-body > form .modal-input {
          border: none;
          border-bottom: 1px solid #efefef; }
        .email-modal .modal-container .modal-body > form .modal-input + .modal-input,
        .email-modal .modal-container .modal-body > form .modal-message {
          margin-top: 30px; }
        .email-modal .modal-container .modal-body > form .submitButton {
          border: none;
          margin-top: 30px; }
        @media only screen and (max-width: 768px) {
          .email-modal .modal-container .modal-body > form {
            margin-top: 20px; }
            .email-modal .modal-container .modal-body > form .modal-input + .modal-input,
            .email-modal .modal-container .modal-body > form .modal-message {
              margin-top: 10px; } }
    .email-modal .modal-container .w100p {
      width: 100%; }
    .email-modal .modal-container .h100px {
      font-weight: 700;
      border-style: solid;
      border-width: 0px;
      border-color: lightgray; }
    .email-modal .modal-container .border-bottom {
      border-bottom-width: 0.5px; }
    .email-modal .modal-container .border-top {
      border-top-width: 0.5px; }
    .email-modal .modal-container .height-rest {
      overflow-y: scroll;
      background-color: #fff; }
      @media only screen and (min-width: 1600px) {
        .email-modal .modal-container .height-rest {
          width: 768px;
          height: 668px; } }
      @media only screen and (min-width: 768px) and (max-width: 1600px) {
        .email-modal .modal-container .height-rest {
          width: 64vw;
          height: 42vw; } }
      @media only screen and (max-width: 768px) {
        .email-modal .modal-container .height-rest {
          width: 80vw;
          height: 90vw; } }

.upload-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-animation: fadein 0.5s;
          animation: fadein 0.5s; }
  @media only screen and (min-width: 1600px) {
    .upload-modal {
      width: 768px;
      height: 768px; } }
  @media only screen and (min-width: 768px) and (max-width: 1600px) {
    .upload-modal {
      width: 64vw;
      height: 48vw; } }
  @media only screen and (max-width: 768px) {
    .upload-modal {
      width: 80vw;
      height: 96vw; } }
  .upload-modal .modal-container {
    font-size: 15px;
    background-color: #efefef;
    border-radius: 5px; }
    .upload-modal .modal-container .w100p {
      width: 100%; }
    .upload-modal .modal-container .h100px {
      font-weight: 500;
      height: 50px;
      border-style: solid;
      border-width: 0px;
      border-color: lightgray; }
    .upload-modal .modal-container .border-bottom {
      border-bottom-width: 0.5px; }
    .upload-modal .modal-container .border-top {
      border-top-width: 0.5px; }
    .upload-modal .modal-container .height-rest {
      overflow-y: scroll;
      background-color: #efefef; }
      @media only screen and (min-width: 1600px) {
        .upload-modal .modal-container .height-rest {
          width: 768px;
          height: 668px; } }
      @media only screen and (min-width: 768px) and (max-width: 1600px) {
        .upload-modal .modal-container .height-rest {
          width: 64vw;
          height: 42vw; } }
      @media only screen and (max-width: 768px) {
        .upload-modal .modal-container .height-rest {
          width: 80vw;
          height: 90vw; } }
    .upload-modal .modal-container .category-btn {
      width: 90px;
      height: 45px;
      color: #777777;
      display: flex;
      justify-content: center;
      align-items: center; }
    .upload-modal .modal-container .category-selected {
      font-weight: 700;
      border-width: 2px;
      border-color: #333333;
      color: #333333; }
    .upload-modal .modal-container .upload-detail {
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: scroll;
      -ms-overflow-style: none;
      /* named upload */ }
      .upload-modal .modal-container .upload-detail::-webkit-scrollbar {
        display: none !important; }
      .upload-modal .modal-container .upload-detail span {
        font-size: 12px; }
      .upload-modal .modal-container .upload-detail .filebox input {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0; }
      .upload-modal .modal-container .upload-detail .image-display {
        max-width: 200px;
        height: auto;
        max-height: 200px; }
        @media only screen and (max-width: 768px) {
          .upload-modal .modal-container .upload-detail .image-display {
            max-width: 60vw;
            height: auto;
            max-height: 60vw; } }
      .upload-modal .modal-container .upload-detail .filebox label {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 220px;
        height: 220px;
        padding: 0.5em 0.75em;
        color: #777;
        font-size: 30px;
        font-weight: 300;
        line-height: normal;
        vertical-align: middle;
        background-color: #eeeeee;
        cursor: pointer;
        box-shadow: 0px 0px 10px 2px rgba(153, 153, 153, 0.84); }
        @media only screen and (max-width: 768px) {
          .upload-modal .modal-container .upload-detail .filebox label {
            width: 60vw;
            height: 60vw; } }
      .upload-modal .modal-container .upload-detail .filebox .upload-name {
        display: inline-block;
        padding: 0.5em 0.75em;
        /* label의 패딩값과 일치 */
        font-size: inherit;
        font-family: inherit;
        line-height: normal;
        vertical-align: middle;
        background-color: #f5f5f5;
        border: 1px solid #ebebeb;
        border-bottom-color: #e2e2e2;
        border-radius: 0.25em;
        -webkit-appearance: none;
        /* 네이티브 외형 감추기 */
        appearance: none; }
      .upload-modal .modal-container .upload-detail .textbox {
        background-color: #efefef;
        border-style: solid;
        border-width: 0px;
        border-bottom-width: 0.5px;
        border-color: lightgray;
        box-shadow: none;
        padding-left: 10px;
        margin-bottom: 30px; }
      .upload-modal .modal-container .upload-detail .input-text {
        font-size: 12px; }
      .upload-modal .modal-container .upload-detail .numberbox {
        width: 75px;
        height: 30px;
        margin-left: 10px;
        margin-right: 10px; }
      .upload-modal .modal-container .upload-detail .datebox {
        width: 150px;
        height: 30px;
        margin-left: 10px;
        margin-right: 10px; }
      .upload-modal .modal-container .upload-detail .image-title {
        width: 100%;
        height: 30px; }
      .upload-modal .modal-container .upload-detail .image-description {
        width: 100%;
        height: 100px;
        padding: 10px; }
      .upload-modal .modal-container .upload-detail .textbox:focus {
        outline: none;
        border-bottom-width: 1px;
        border-color: #326bb5; }
      .upload-modal .modal-container .upload-detail .inputbox-container {
        display: flex;
        background-color: #efefef;
        flex-direction: column;
        vertical-align: middle;
        margin-bottom: 20px; }
        .upload-modal .modal-container .upload-detail .inputbox-container .inputbox-element {
          height: 30px;
          display: flex;
          flex-direction: row;
          align-items: center; }
          .upload-modal .modal-container .upload-detail .inputbox-container .inputbox-element .inputbox-button {
            width: 18px;
            height: 18px;
            border-radius: 9px;
            border-style: solid;
            border-color: #aaa;
            border-width: 2px;
            margin-right: 5px;
            display: flex;
            justify-content: center;
            align-items: center; }
            .upload-modal .modal-container .upload-detail .inputbox-container .inputbox-element .inputbox-button .activated {
              width: 10px;
              height: 10px;
              border-radius: 5px;
              background-color: #326bb5;
              border-width: 0; }
          .upload-modal .modal-container .upload-detail .inputbox-container .inputbox-element .btn-text {
            color: #aaa;
            font-size: 12px; }
    @media only screen and (min-width: 1600px) {
      .upload-modal .modal-container {
        width: 768px;
        height: 768px; } }
    @media only screen and (min-width: 768px) and (max-width: 1600px) {
      .upload-modal .modal-container {
        width: 64vw;
        height: 48vw; } }
    @media only screen and (max-width: 768px) {
      .upload-modal .modal-container {
        width: 80vw;
        height: 96vw; } }

.image-icon-box {
  display: flex;
  flex-direction: column; }

.main-img-item {
  height: 30vh;
  width: auto;
  margin-bottom: 10px;
  border-style: solid;
  border-width: 10px;
  border-color: #efefef;
  box-shadow: 0 10px 20px 0px rgba(51, 51, 51, 0.5);
  transition: 0.5s ease-in all; }

.img-item {
  max-height: 15vh;
  max-width: 150px;
  margin-bottom: 10px;
  box-shadow: 0 10px 20px 0px rgba(51, 51, 51, 0.5);
  transition: 0.5s ease-in all; }

.item-container {
  width: 200px;
  text-align: center; }

.div-item {
  display: flex;
  padding: 15px;
  justify-content: center;
  align-items: center;
  flex-direction: row; }

.image-text {
  margin-bottom: 30px;
  width: 90%; }

.image-text-input {
  width: 100%;
  border-color: lightgray;
  border-width: 0.5px;
  padding: 10px;
  font-size: 12px;
  height: 70px;
  outline: none; }

.weight900 {
  font-weight: 700;
  white-space: pre-wrap; }

.preview-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  overflow: scroll;
  padding: 3vw 0 3vw 0; }

@-webkit-keyframes appear {
  from {
    -webkit-transform: translateX(-50%) scaleX(0.3);
            transform: translateX(-50%) scaleX(0.3); }
  to {
    -webkit-transform: translateX(0) scaleX(1);
            transform: translateX(0) scaleX(1); } }

@keyframes appear {
  from {
    -webkit-transform: translateX(-50%) scaleX(0.3);
            transform: translateX(-50%) scaleX(0.3); }
  to {
    -webkit-transform: translateX(0) scaleX(1);
            transform: translateX(0) scaleX(1); } }

@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadein_out {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadein_out {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 105;
  -webkit-animation: ease-in 0.3s fadein;
          animation: ease-in 0.3s fadein;
  background-color: rgba(33, 33, 33, 0.8); }

.test-border {
  border-width: 5px;
  border-style: solid;
  border-color: red; }

.notice_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 90%;
  max-width: 960px;
  margin: 0 auto; }

/* Column */
.notice_column {
  flex-basis: 33.33333%;
  width: 33.33333%;
  padding: 0 10px;
  box-sizing: border-box; }
  @media (max-width: 900px) {
    .notice_column {
      flex-basis: 50%;
      width: 50%; } }
  @media (max-width: 600px) {
    .notice_column {
      flex-basis: 100%;
      width: 100%; } }

/* Article (Component) */
.notice_article {
  background: #fff;
  margin: 0 0 20px;
  padding: 20px;
  border-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: 0.3s ease; }
  .notice_article:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.2); }
  .notice_article:active {
    box-shadow: none;
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: scale(0.98);
            transform: scale(0.98); }
  .notice_article__category {
    display: inline-block;
    padding: 8px 10px;
    margin: 0 0 10px;
    color: #fff;
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 0.075rem;
    text-transform: uppercase; }
  .notice_article__excerpt {
    color: #666;
    line-height: 1.5rem;
    font-size: 0.875rem; }
  .notice_article__title {
    margin: 0 0 10px;
    color: #444;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem; }

.video-body {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
  overflow-x: hidden; }

.fullscreen-video-wrap {
  position: absolute;
  top: 0;
  left: 0;
  overflow-x: scroll;
  -ms-overflow-style: none;
  width: 99vw;
  height: 99vh; }
  .fullscreen-video-wrap::-webkit-scrollbar {
    display: none !important; }

.video-box {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden; }

.fullscreen-video-wrap video {
  position: relative;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 20%; }

.header-content {
  margin: auto;
  z-index: 2; }
  @media only screen and (max-width: 770px) {
    .header-content img {
      display: none; } }
  @media only screen and (max-width: 770px) {
    .header-content {
      margin-top: 120px; } }
  @media only screen and (max-width: 600px) {
    .header-content .displayN {
      display: none; } }

.header-content h1 {
  font-size: 50px;
  margin-bottom: 0; }

.header-content p {
  font-size: 1.5rem;
  display: block;
  padding-bottom: 2rem; }

.cart-container {
  width: 100%;
  padding: 20px; }
  .cart-container .bold {
    font-weight: 700; }
  .cart-container .cart {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 5px; }
    .cart-container .cart .cart-header {
      width: 100%;
      color: #333;
      font-size: 14px;
      font-weight: 300;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 20px 20px 0px 20px; }
      .cart-container .cart .cart-header .cart-remove-all {
        font-size: 10px;
        background-color: #efefef;
        font-weight: 700;
        color: darkgray;
        padding: 5px 10px 5px 10px; }
    .cart-container .cart .cart-body {
      width: 100%;
      margin: 10px;
      padding: 10px; }
      .cart-container .cart .cart-body .cart-element {
        display: flex;
        flex-direction: row;
        border-top-style: solid;
        border-top-width: 1px;
        border-top-color: lightgray;
        padding: 20px 10px 20px 10px; }
        .cart-container .cart .cart-body .cart-element .cart-image {
          width: 20%; }
          .cart-container .cart .cart-body .cart-element .cart-image img {
            width: 100%;
            height: auto; }
        .cart-container .cart .cart-body .cart-element .cart-description {
          width: 70%;
          padding: 20px;
          display: flex;
          flex-direction: column;
          font-size: 12px;
          color: #333; }
        .cart-container .cart .cart-body .cart-element .cart-remove {
          width: 100px;
          display: flex;
          justify-content: flex-end;
          align-items: center; }
          .cart-container .cart .cart-body .cart-element .cart-remove .remove-btn {
            text-align: center;
            height: 25px;
            font-weight: 700;
            font-size: 10px;
            background-color: #efefef;
            color: darkgray;
            padding: 5px; }
      .cart-container .cart .cart-body .cart-price {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%; }
        @media only screen and (max-width: 767px) {
          .cart-container .cart .cart-body .cart-price {
            flex-direction: column;
            align-items: flex-end; } }
        .cart-container .cart .cart-body .cart-price div {
          width: 25%;
          display: flex;
          flex-direction: row;
          justify-items: center;
          align-items: center;
          margin: 10px 3% 15px 10px;
          font-size: 12px;
          color: darkgray; }
          @media only screen and (max-width: 767px) {
            .cart-container .cart .cart-body .cart-price div {
              margin: 0;
              width: 100%;
              justify-content: flex-end; } }
          .cart-container .cart .cart-body .cart-price div input {
            width: 40px;
            height: 20px;
            font-size: 12px;
            font-weight: 700;
            text-align: center;
            outline: none;
            border-style: none;
            border-color: lightgray;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            background-color: #fafafa;
            margin: 3px;
            margin-left: 10px;
            padding: 3px; }
          .cart-container .cart .cart-body .cart-price div input::-webkit-outer-spin-button,
          .cart-container .cart .cart-body .cart-price div input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0; }
  .cart-container .cart-summary {
    padding: 20px;
    display: flex;
    flex-direction: column; }
    .cart-container .cart-summary .cart-summary-header {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      padding: 10px;
      border-bottom-style: solid;
      border-bottom-width: 1px; }
    .cart-container .cart-summary .cart-summary-price {
      display: flex;
      padding: 20px 10px 20px 10px;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 700; }
    .cart-container .cart-summary .cart-order {
      background-color: #444;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-top: 20px;
      padding: 10px;
      font-size: 16px;
      font-weight: 700; }

.video-btn {
  background: #000;
  color: #e5e5e5;
  font-size: 1rem;
  padding: 1rem 2rem;
  text-decoration: none;
  margin: auto; }
  @media only screen and (max-width: 960px) {
    .video-btn {
      font-size: 0.8rem;
      padding: 0.8rem 1.6rem; } }
  @media only screen and (max-width: 560px) {
    .video-btn {
      margin-top: 20px; } }

.video-section {
  margin: 50px 0 0 0;
  position: relative; }

.viewPointContainer {
  display: relative;
  z-index: 10;
  margin-bottom: 50px; }

.video-section-b {
  background: #333;
  color: #fff; }

.section-img {
  display: relative;
  z-index: 1000;
  margin: auto; }

@media (max-width: 960px) {
  .video-container {
    padding-right: 3rem;
    padding-left: 3rem; } }

.tag-container {
  margin-top: 43px;
  background: 0 0;
  text-align: center;
  max-width: 1200px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;
  text-align: center; }

.tag-button {
  display: inline-block;
  padding: 0.3em 1em;
  text-decoration: none;
  color: #333;
  border: solid 2px #fff;
  border-radius: 3px;
  transition: 0.4s;
  font-weight: 600; }

.tag-button:hover {
  color: #777777; }

.admin-category {
  margin-top: 15vh;
  height: 50px;
  display: flex;
  flex-direction: row; }
  .admin-category .admin-category-element {
    padding: 5px 10px 5px 10px;
    color: gray;
    font-weight: 300; }

.custom {
  margin-top: 200px;
  width: 100%;
  min-height: 90vh;
  background-color: white;
  color: #555;
  display: flex;
  justify-content: center;
  align-items: flex-start; }
  .custom .custom-demand {
    max-width: 100%;
    margin: 30px;
    height: 100px;
    border: solid 1px #efefef;
    font-size: 13px;
    padding: 10px; }
  .custom .custom-demand:focus {
    outline: none; }
  .custom .custom-row {
    width: 100%; }
  .custom .custom-header {
    font-size: 15px;
    color: #333;
    height: 60px;
    font-weight: 400;
    border-bottom: solid 1px #999;
    padding: 20px 0px 20px 10px; }
  .custom .custom-step-header {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    padding: 10px;
    color: #333;
    font-weight: 500;
    font-size: 15px; }
    .custom .custom-step-header .header-sub {
      font-size: 12px;
      color: #777;
      margin-left: 10px; }
  .custom .custom-step-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative; }
  .custom .custom-step {
    min-height: 150px;
    display: flex;
    flex-direction: column;
    padding: 10px; }
    .custom .custom-step .add-cart-wrapper {
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: flex-end;
      padding: 20px 40px 20px 40px; }
    .custom .custom-step .next-btn {
      width: 120px;
      height: 40px;
      border-radius: 10px;
      font-size: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #333;
      color: white;
      border: solid 1px #333; }
    .custom .custom-step .option-container {
      width: 100%;
      height: 50px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center; }
      .custom .custom-step .option-container .option-artwork {
        padding: 5px 10px 5px 10px;
        color: #555;
        border: solid 1px #777;
        margin: 10px;
        font-size: 12px;
        cursor: pointer; }
      .custom .custom-step .option-container .option-text {
        display: flex;
        flex-direction: row;
        padding: 5px 10px 5px 10px;
        color: #555;
        border: solid 1px #777;
        margin: 10px;
        font-size: 12px;
        cursor: pointer; }
        .custom .custom-step .option-container .option-text .option-text-input {
          transition: 0.2s ease-in all;
          outline: none;
          border: none;
          border-bottom: 1px solid #999; }
        .custom .custom-step .option-container .option-text .option-text-confirm {
          background-color: #333;
          padding: 0px 5px 0px 5px;
          color: white;
          border-radius: 20px; }
    .custom .custom-step .custom-custom-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      min-height: 70vh; }
      .custom .custom-step .custom-custom-container .custom-paper {
        position: relative;
        width: 100%;
        height: 700px;
        background-color: #fefefe; }
      .custom .custom-step .custom-custom-container .custom-background {
        object-fit: contain;
        width: 100%;
        height: 100%; }
      .custom .custom-step .custom-custom-container .custom-draggable {
        border: solid red 1px; }
      .custom .custom-step .custom-custom-container .custom-image-wrapper {
        position: absolute;
        top: 0;
        left: -100px;
        padding: 50px; }
      .custom .custom-step .custom-custom-container .custom-text-wrapper {
        position: absolute;
        top: 0;
        left: -50px;
        padding: 50px; }
      .custom .custom-step .custom-custom-container .custom-center {
        width: 100px;
        height: 100px; }
      .custom .custom-step .custom-custom-container .controller-container {
        width: 100%;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        color: black; }
      .custom .custom-step .custom-custom-container .drag {
        color: black; }
  .custom .custom-category-btn {
    padding: 5px 10px 5px 10px;
    font-size: 12px;
    font-weight: 400;
    margin: 10px;
    color: gray;
    border: 1px solid lightgray; }

.custom-total {
  width: 100%;
  display: flex;
  min-height: 10vh;
  flex-direction: column;
  margin: 40px;
  border: solid 1px #efefef; }
  .custom-total .flex1 {
    flex: 1 1; }
  .custom-total .flex2 {
    flex: 2 1; }
  .custom-total .flex3 {
    flex: 3 1; }
  .custom-total .flex4 {
    flex: 4 1; }
  .custom-total .header {
    display: flex;
    flex-direction: row;
    height: 50px;
    padding: 15px;
    font-size: 13px;
    justify-content: flex-start;
    align-items: center;
    background-color: #efefef; }
  .custom-total .justify-content-end {
    display: flex;
    justify-content: flex-end; }
  .custom-total .justify-content-center {
    display: flex;
    justify-content: center; }
  .custom-total .custom-total-element {
    display: flex;
    font-size: 13px;
    flex-direction: row;
    width: 100%;
    min-height: 50px;
    padding: 15px;
    justify-content: flex-start;
    align-items: center;
    background-color: #fefefe;
    border-bottom: solid 1px #efefef; }
  .custom-total .detail {
    display: flex;
    font-size: 13px;
    flex-direction: row;
    height: 200px;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    background-color: lightgray; }
  .custom-total .footer {
    flex-direction: row;
    height: 50px;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 15px; }

.custom-box-ex {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0);
  color: #fff; }

.admin {
  width: 100%;
  min-height: 90vh;
  background-color: white;
  color: #555;
  display: flex;
  justify-content: center;
  align-items: flex-start; }
  .admin .admin-row {
    width: 100%; }
    .admin .admin-row .admin-category-header {
      font-size: 15px;
      color: #333;
      height: 60px;
      font-weight: 400;
      border-bottom: solid 1px #999;
      padding: 20px 0px 20px 10px; }
    .admin .admin-row .admin-slider-container {
      min-height: 40vh;
      width: 100%;
      background-color: lightgray; }
      .admin .admin-row .admin-slider-container .admin-slider-scroll {
        min-width: 75vw;
        height: 100%;
        background-color: gray; }
    .admin .admin-row .admin-test-container {
      min-height: 60vh;
      width: 100%;
      background-color: #efefef;
      display: flex;
      justify-content: center;
      align-items: center; }
    .admin .admin-row .soldout-header {
      padding: 15px 0 10px 5px;
      font-size: 14px;
      color: #333; }
    .admin .admin-row .soldout-sub-header {
      margin-top: 30px;
      padding: 10px 0 10px 5px;
      font-size: 12px;
      color: #333; }
    .admin .admin-row .soldout-sub-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin: 5px;
      margin-top: 0;
      padding: 0 10px 20px 10px; }
      .admin .admin-row .soldout-sub-container .soldout-btn {
        position: relative;
        flex: 1 1;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        margin: 1%;
        font-size: 12px;
        color: #333;
        border: solid lightgray 1px; }
        .admin .admin-row .soldout-sub-container .soldout-btn .soldout-screen {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.6);
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          color: red; }
    .admin .admin-row .soldout-shoes-container {
      margin: 5px;
      margin-top: 0;
      padding: 0 10px 20px 10px; }
      .admin .admin-row .soldout-shoes-container .soldout-btn {
        position: relative;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        margin: 1%;
        font-size: 12px;
        color: #333;
        border: solid lightgray 1px; }
        .admin .admin-row .soldout-shoes-container .soldout-btn .soldout-screen {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.6);
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          color: red; }
  .admin .soldout-save-btn {
    margin: 30px 0 0 0;
    padding: 10px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: solid 1px lightgray;
    border-bottom: solid 1px lightgray;
    color: #166cbc;
    font-weight: 500;
    font-size: 14px; }

.max300 {
  max-width: 300px; }

.gallery-detail {
  padding: 0;
  margin-top: 100px;
  display: contents; }
  .gallery-detail .paddingTop {
    padding-top: 100px; }
    .gallery-detail .paddingTop .rightPadding {
      padding-top: 100px; }
      @media only screen and (max-width: 576px) {
        .gallery-detail .paddingTop .rightPadding {
          padding-top: 30px; } }
  .gallery-detail .image-box {
    height: 60vh; }
    .gallery-detail .image-box .image {
      width: auto;
      height: 90%;
      object-fit: contain;
      object-position: center; }
    .gallery-detail .image-box .padding {
      margin: 20px; }
      .gallery-detail .image-box .padding h5 {
        font-size: 10px;
        margin-bottom: 15px;
        font-weight: 700; }
      .gallery-detail .image-box .padding .detailRow {
        border-bottom: 1px #000 sol; }

.galleryModalTitle {
  font-size: 10px;
  display: inline-block;
  font-weight: 700;
  color: #333; }

.galleryModalSubtitle {
  font-size: 20px;
  font-weight: 300; }

.galleryModalDetail {
  font-size: 10px; }

.galleryIcons {
  display: inline-flex; }
  .galleryIcons .iconContainer {
    height: 40px;
    width: 40px;
    padding: auto;
    margin: 5px; }
  .galleryIcons .modalDot {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    font-weight: 500; }

.GoShopBtn {
  margin-top: 3px;
  color: #000;
  background: #fff;
  font-weight: bold;
  font-size: 11px;
  margin-left: 5px;
  border-radius: 4px;
  border: solid 1px #e5e5e5;
  border-radius: 4px;
  padding: 4px 8px 4px 8px; }

.company-info {
  width: 100%;
  background-color: darkgray;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px 0 0 0; }
  .company-info span {
    font-size: 10px;
    font-weight: 500;
    padding-left: 10px; }

.card {
  font-weight: 500;
  width: 100%;
  border-radius: 0; }
  .card .footer-icon {
    margin: 0 10px 0 10px; }
  .card .card-header {
    font-weight: 500;
    display: flex;
    font-size: 12px;
    justify-content: center;
    background-color: white;
    color: #333;
    border-width: 0;
    outline: none; }
  .card .card-body {
    background-color: white;
    font-size: 10px;
    border-width: 0;
    display: flex;
    flex-direction: column;
    align-items: center; }

.footer-div {
  background-color: #efefef; }

.footer-container {
  padding-top: 80px;
  padding-bottom: 80px; }
  .footer-container .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .footer-container .footer .flex2 {
      flex: 2 1; }
    .footer-container .footer .footer-element {
      display: flex;
      align-items: center;
      width: 18%;
      display: flex;
      flex-direction: column;
      font-size: 10px;
      font-weight: 500; }
      .footer-container .footer .footer-element .left-align-div {
        display: flex;
        flex-direction: column;
        align-items: flex-start; }
      .footer-container .footer .footer-element .footer-image {
        width: 70px;
        height: auto;
        padding-bottom: 5px; }
      .footer-container .footer .footer-element .highlighted {
        margin-bottom: 5px;
        font-size: 12px;
        font-weight: 700; }
      .footer-container .footer .footer-element .indented {
        padding-left: 5px; }
    .footer-container .footer .footer-iconbox {
      display: flex;
      flex-direction: row; }
    .footer-container .footer .footer-icon {
      padding: 5px; }
    .footer-container .footer .footer-height {
      margin-left: auto;
      margin-right: auto; }
      @media only screen and (max-width: 1200px) {
        .footer-container .footer .footer-height {
          padding: 10px; } }
      .footer-container .footer .footer-height .paddingForLine {
        padding-left: 30px; }
        @media only screen and (max-width: 400px) {
          .footer-container .footer .footer-height .paddingForLine {
            padding-left: 12px; } }
    .footer-container .footer hr {
      background: #e7e7e7;
      width: 100%;
      height: 1px; }
    .footer-container .footer label {
      padding: 0;
      margin-top: 2.5vh;
      margin-bottom: 2.5vh; }
      @media only screen and (max-width: 1200px) {
        .footer-container .footer label {
          font-size: 13px; } }
      @media only screen and (max-width: 576px) {
        .footer-container .footer label {
          margin-top: 1vh;
          margin-bottom: 1vh;
          font-size: 13px; } }
      @media only screen and (max-width: 400px) {
        .footer-container .footer label {
          font-size: 11px; } }
    .footer-container .footer p {
      font-size: 11px;
      margin: -1px; }
      @media only screen and (max-width: 835px) and (min-width: 400px) {
        .footer-container .footer p {
          font-size: 0.6em; } }
      @media only screen and (max-width: 400px) {
        .footer-container .footer p {
          font-size: 0.3em; } }
      @media only screen and (max-width: 400px) {
        .footer-container .footer p span {
          display: none; } }
    @media only screen and (max-width: 768px) {
      .footer-container .footer .aboutUs {
        display: none; } }

.rightIcon {
  width: 34px;
  padding: 0;
  top: 85%;
  right: 20px;
  position: fixed;
  z-index: 99999;
  opacity: 0; }
  .rightIcon .rightContainer a .kakao {
    position: absolute;
    left: 0;
    top: 0px;
    width: 34px;
    height: 34px; }
  .rightIcon .rightContainer .rightEmail > img {
    width: 34px;
    height: 34px; }
  @media only screen and (max-width: 768px) {
    .rightIcon {
      top: 75%; } }

.instaCol {
  margin: auto; }

.instaContainer {
  flex-direction: column;
  justify-content: space-between;
  border-radius: 1px;
  margin: 10px 20px 10px 20px;
  height: auto; }

.myLeftContainer {
  padding: 15px; }
  .myLeftContainer h1 {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1;
    margin-bottom: 25px; }

.myRightContainer {
  background-color: #fff;
  padding: 30 30; }
  .myRightContainer .rightRow .eachInfo {
    padding: 30px; }
    .myRightContainer .rightRow .eachInfo h1 {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1;
      margin-bottom: 25px; }
    .myRightContainer .rightRow .eachInfo label {
      margin-top: 30px;
      top: -1.68067rem;
      font-weight: 500;
      font-size: 1.0084rem;
      color: #313131;
      opacity: 1;
      margin-bottom: 10px; }
    .myRightContainer .rightRow .eachInfo p {
      text-align: center;
      font-weight: 300;
      font-size: 0.9rem; }
    .myRightContainer .rightRow .eachInfo input {
      margin: auto;
      display: block;
      width: 70%;
      padding: 0.5rem 1.5rem;
      height: 25px;
      border-radius: 0;
      -webkit-appearance: none;
              appearance: none;
      font-weight: 300;
      font-size: 1.0084rem;
      line-height: 1;
      color: #313131;
      letter-spacing: 0.026em;
      border: 1px solid #e7e7e7; }
    .myRightContainer .rightRow .eachInfo button {
      display: inline-block;
      box-sizing: border-box;
      padding: 4px 8px;
      border: 1px solid #d1d1d1;
      border-radius: 0px;
      font-size: 12px;
      line-height: 25px;
      font-weight: normal;
      text-decoration: none;
      vertical-align: middle;
      word-spacing: -0.5px;
      letter-spacing: 0px;
      text-align: center;
      white-space: nowrap;
      color: #222;
      background-color: #fff; }

.reform-description {
  width: 100%; }
  .reform-description .reform-detail-header {
    font-weight: 700;
    line-height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-color: #999;
    border-width: 0 0 1px 0; }
    @media only screen and (max-width: 767px) {
      .reform-description .reform-detail-header {
        background-color: #efefef; } }
  .reform-description .reform-detail-contents {
    white-space: pre-wrap;
    font-size: 12px;
    font-weight: 300;
    line-height: 25px;
    padding: 10px; }
  .reform-description .reform-textarea {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 40vw;
    height: 200px;
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background: 0 0 rgba(0, 0, 0, 0.07);
    border-image: none;
    border-radius: 6px 6px 6px 6px;
    border-style: none solid solid none;
    border-width: medium 1px 1px medium;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
    color: #555555;
    font-size: 12px;
    line-height: 1.4em;
    padding: 10px 8px;
    transition: background-color 0.2s ease 0s; }
    @media only screen and (max-width: 960px) {
      .reform-description .reform-textarea {
        width: 70vw; } }
    .reform-description .reform-textarea p {
      padding: 40px;
      line-height: 30px; }
  .reform-description .reform-textarea:focus {
    background: none repeat scroll 0 0 #ffffff;
    outline-width: 0; }

.reform-border-container {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.reform-border {
  color: #333333;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 500;
  margin: 10px 20px 50px 20px;
  padding: 20px 0px 20px 30px;
  border-bottom: solid 0.5px #ccc; }

.whiteSpace {
  white-space: pre-wrap;
  position: 'relative';
  z-index: 1; }

.goodsHigh-list {
  min-height: 30vw;
  overflow-x: scroll;
  -ms-overflow-style: none; }
  .goodsHigh-list::-webkit-scrollbar {
    display: none !important; }
  .goodsHigh-list .goodsHigh {
    padding: 15px 30px 15px 0; }
    .goodsHigh-list .goodsHigh .goodsHigh-textbox {
      width: 100%;
      padding: 5px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start; }
    .goodsHigh-list .goodsHigh .goodsHigh-image {
      width: 20vw;
      height: 20vw;
      object-fit: cover;
      object-position: center;
      transition: 0.3s ease all; }
    .goodsHigh-list .goodsHigh .goodsHigh-image:hover {
      object-fit: contain;
      transition: 0.3s ease all; }

.goods-component {
  margin: 0; }
  .goods-component .component-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 2vh;
    font-weight: 500; }

.soldout-circle {
  background: #f00;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  float: right;
  margin-top: 3.5px; }

.work-total {
  font-size: 30px;
  font-style: italic; }

.reactjs-coverflow_Main {
  position: relative;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.1);
  overflow: hidden; }

.reactjs-coverflow_Coverflow {
  width: 100%;
  height: 100%;
  display: flex;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-perspective: 500px;
  perspective: 500px;
  align-items: center;
  transition: -webkit-transform 0.7s;
  transition: transform 0.7s;
  transition: transform 0.7s, -webkit-transform 0.7s; }

.reactjs-coverflow_Element {
  position: relative;
  -webkit-box-reflect: below 1px -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1) 20%, transparent 30%, transparent);
  margin: auto 20px;
  transition: -webkit-transform 0.7s;
  transition: transform 0.7s;
  transition: transform 0.7s, -webkit-transform 0.7s; }

.search_body {
  width: 300px;
  margin: 0 auto;
  position: relative;
  display: inline-block;
  float: right;
  padding: 0 0 0 40px;
  background: #fff;
  border: none;
  border-radius: 5px; }
  .search_body input,
  .search_body button {
    position: relative;
    width: 200px;
    height: 45px;
    padding: 0;
    display: inline-block;
    float: left; }
  .search_body input {
    color: #666;
    z-index: 2;
    border: 0 none; }
  .search_body input:focus {
    outline: 0 none; }
  .search_body input:focus + button {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    transition-duration: 0.3s; }
  .search_body input:focus + button .fa {
    -webkit-transform: translate(0px, 0);
    transform: translate(0px, 0);
    transition-duration: 0.3s;
    color: #fff; }
  .search_body button {
    z-index: 1;
    width: 45px;
    border: 0 none;
    background: #29abe2;
    cursor: pointer;
    -webkit-transform: translate(-50px, 0);
    transform: translate(-50px, 0);
    transition-duration: 0.3s; }
  .search_body .fa-search {
    font-size: 1.4rem;
    color: #29abe2;
    z-index: 3;
    top: 25%;
    -webkit-transform: translate(-190px, 0);
    transform: translate(-190px, 0);
    transition-duration: 0.3s;
    transition: all 0.1s ease-in-out; }

.home_wrap {
  width: 70%;
  height: 70vh;
  background: linear-gradient(to right, #ddd6f3, #faaca8);
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  left: 0;
  display: table; }
  @media only screen and (max-width: 1040px) {
    .home_wrap {
      width: 100%;
      background: none; } }

.home_cont {
  width: 100%;
  max-width: 1400px;
  padding-left: 11%;
  display: table-cell;
  vertical-align: middle;
  margin: 0 auto;
  transition: all 1s; }

.home_background {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 70vh;
  top: 0px;
  right: 0px;
  width: 50%;
  height: 70vh;
  background-image: url(/static/media/home_image.40925e91.png); }
  @media only screen and (max-width: 1040px) {
    .home_background {
      width: 100%;
      opacity: 0.4; } }

.home_wrap .home_h_line {
  width: 80px;
  height: 1px;
  background-color: black;
  display: inline-block;
  margin: 5px 15px; }

.home_text1 {
  display: inline-block;
  color: black;
  font-weight: 300;
  font-size: 14px; }

.home_text2 {
  color: black;
  font-weight: 400;
  font-size: 90px;
  padding-top: 30px; }
  @media only screen and (max-width: 1600px) {
    .home_text2 {
      font-size: 70px; } }
  @media only screen and (max-width: 1040px) {
    .home_text2 {
      font-size: 70px;
      font-weight: 350; } }
  @media only screen and (max-width: 768px) {
    .home_text2 {
      font-size: 40px;
      font-weight: 350; } }

.home_text3 {
  color: #fff;
  font-weight: 500;
  font-size: 30px;
  line-height: 24px;
  padding-top: 20px; }
  @media only screen and (min-width: 576px) and (max-width: 1200px) {
    .home_text3 {
      font-size: 11px;
      font-weight: 200;
      padding-top: 15px; } }
  @media only screen and (max-width: 576px) {
    .home_text3 {
      font-size: 8px;
      padding-top: 5px;
      font-weight: 300; } }

.home_button_wrap {
  width: auto;
  height: auto;
  margin-top: 40px;
  display: inline-flex; }
  @media only screen and (max-width: 768px) {
    .home_button_wrap {
      margin-top: 60px; } }

.home_button {
  width: 10vw;
  padding: 18px 0;
  color: #212f3d;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 0.5s ease;
  position: relative;
  z-index: 2; }
  @media only screen and (max-width: 1040px) {
    .home_button {
      width: 20vw;
      font-weight: 700;
      margin: 2px;
      margin-top: 0px; } }
  @media only screen and (max-width: 600px) {
    .home_button {
      font-size: 15px; } }

.home_button:hover {
  color: rgba(255, 255, 255, 0.85);
  box-shadow: rgba(120, 116, 131, 0.7) 0 0px 0px 40px inset; }

.home_button.a > a {
  background-color: #fff;
  color: #a09f9f; }

.bg-blue {
  background-color: #326bb5; }

.bg-dark {
  background-color: #222222; }

.bg-mydark {
  background-color: #222222; }

.bg-darkgray {
  background-color: #333333; }

.bg-lightgray {
  background-color: #e3e3e3; }

.bg-gray {
  background-color: gray; }

.bg-white {
  background-color: #efefef; }

.bg-red {
  background-color: red; }

.section {
  display: flex;
  justify-content: center;
  align-items: center; }

.border-test {
  border: 1px solid #333333; }

.zIndex1 {
  z-index: 5; }

.label {
  font-size: 13px;
  font-weight: 500;
  color: #000000; }

.detail {
  font-size: 13px;
  font-weight: 100;
  color: #000000; }

.nav-dropdown {
  background-color: transparent;
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%; }

.navbar-transparent {
  z-index: 100;
  padding-left: 20px;
  padding-right: 20px;
  transition: 0.3s;
  background-color: rgba(255, 255, 255, 0); }
  @media only screen and (max-width: 992px) {
    .navbar-transparent {
      box-shadow: 0px 0px 20px 2px rgba(76, 76, 76, 0.3);
      background-color: rgba(255, 255, 255, 0.8); } }
  .navbar-transparent .navbtn {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 12px;
    font-weight: 100;
    color: #000000; }
  .navbar-transparent .logo-text {
    font-size: 30px;
    font-weight: 200;
    color: #000000; }

.logo-box {
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center; }

.nav-left {
  width: 50%;
  height: 100%; }

.nav-right {
  width: 30%;
  height: 100%; }

.h100p {
  height: 100%; }

.w50p {
  width: 50%; }

.w30p {
  width: 30%; }

.flex-row {
  display: flex;
  flex-direction: row; }

.nav {
  position: fixed;
  z-index: 100;
  top: 5px;
  left: 0.5%;
  width: 99%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  @media only screen and (max-width: 768px) {
    .nav {
      left: 2%;
      width: 96%; } }

.nav-toggle {
  display: none; }
  @media only screen and (max-width: 768px) {
    .nav-toggle {
      display: flex;
      justify-content: center;
      align-items: center; } }

.darkness {
  position: fixed;
  top: -5px;
  left: -1%;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.5); }

.nav-element {
  height: 100%;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media only screen and (max-width: 768px) {
    .nav-element {
      display: none; } }

.nav-dropdown {
  position: fixed;
  top: 10px;
  display: block;
  z-index: 10;
  left: 0.5%;
  width: 99%;
  background-color: black;
  height: 100px;
  opacity: 1;
  -webkit-transform: translateY(150px) scaleY(4);
          transform: translateY(150px) scaleY(4);
  transition: 0.5s ease-in-out; }
  @media only screen and (max-width: 768px) {
    .nav-dropdown {
      left: 2%;
      width: 96%; } }

.navbar-white {
  z-index: 104;
  padding-left: 20px;
  padding-right: 20px;
  transition: 0.3s;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 20px 2px rgba(51, 51, 51, 0.3); }
  @media only screen and (max-width: 992px) {
    .navbar-white {
      background-color: rgba(255, 255, 255, 0.9); } }
  .navbar-white .navbtn {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 12px;
    font-weight: 100;
    color: #000000; }
  .navbar-white .logo-text {
    font-size: 30px;
    font-weight: 200;
    color: #000000; }
  .navbar-white .logo-text {
    font-size: 30px;
    font-weight: 200;
    color: #000000; }

.none-hover :hover {
  text-decoration: none; }

.highlight {
  text-decoration: underline;
  font-weight: 300 !important; }

.shop-btn-highlight {
  font-size: 23px;
  text-decoration: underline;
  font-weight: 500 !important;
  color: #000000; }
  @media only screen and (max-width: 760px) {
    .shop-btn-highlight {
      font-size: 16px; } }

.shop-btn {
  font-size: 23px;
  font-weight: 300; }
  @media only screen and (max-width: 1000px) {
    .shop-btn {
      font-size: 16px; } }

.abs-test {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(/static/media/home1.e503b2f7.jpg);
  background-repeat: repeat;
  z-index: 0;
  height: 100vh;
  width: 100vw; }

.aspect-ratio-1-1 {
  width: 100%;
  padding-top: 100%;
  /* 1:1 Aspect Ratio */
  position: relative;
  /* If you want text inside of it */ }

.aspect-ratio-4-3 {
  width: 100%;
  padding-top: 75%;
  /* 1:1 Aspect Ratio */
  position: relative;
  /* If you want text inside of it */ }

.aspect-ratio-16-9 {
  width: 100%;
  padding-top: 56.25%;
  /* 1:1 Aspect Ratio */
  position: relative;
  /* If you want text inside of it */ }

.inline-box {
  position: absolute;
  top: 0;
  left: 0;
  color: #ffffff; }

.vh100 {
  height: 100vh; }

.pointer {
  cursor: pointer;
  transition: ease-out 0.5s;
  opacity: 1; }

.pointer:hover {
  opacity: 0.3;
  transition: ease-out 0.5s; }

.homeTransition-appear {
  opacity: 0; }

.homeTransition-appear.homeTransition-appear-active {
  opacity: 0;
  transition: all 0.5s ease-in-out; }

.alert-appear {
  opacity: 0;
  -webkit-transform: scale(0.9);
          transform: scale(0.9); }

.alert-appear-active {
  opacity: 1;
  -webkit-transform: translateX(0px);
          transform: translateX(0px);
  transition: all 0.5s ease-in-out; }

.fadein {
  -webkit-animation: fadein 2s;
  /* Safari, Chrome and Opera > 12.1 */
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: fadein 2s; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

/* Internet Explorer */

/* Opera < 12.1 */

.custom {
  -webkit-animation: custom 2s;
          animation: custom 2s; }

@-webkit-keyframes custom {
  from {
    width: 0%;
    height: 0%; }
  to {
    width: 100%;
    height: 100%; } }

@keyframes custom {
  from {
    width: 0%;
    height: 0%; }
  to {
    width: 100%;
    height: 100%; } }

.fadeout {
  -webkit-animation: fadeout 2s;
  /* Safari, Chrome and Opera > 12.1 */
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: fadeout 2s; }

@keyframes fadeout {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

/* Internet Explorer */

/* Opera < 12.1 */

.button {
  -webkit-appearance: none;
  appearance: none;
  -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all 0.5s ease;
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  transition: all 0.5s ease;
  border: none;
  background: #fff;
  border-radius: 30px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 15px 40px;
  font-weight: 400;
  font-size: 12px;
  cursor: cursor; }

button:hover {
  color: #ffffff;
  background: #222222;
  -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2)); }

.slider-wrapper {
  height: 100vh;
  min-width: 100vw; }

.slide {
  height: 100vh;
  background-size: cover !important; }

.slide::before {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
  bottom: 0;
  left: 0; }

.previousButton,
.nextButton {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 10;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  cursor: pointer; }

.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
  transform: rotate(180deg) translateY(calc(50% + 0px)); }

.previousButton:hover {
  left: -10px; }

.nextButton {
  right: 0; }

.nextButton:hover {
  right: -10px; }

.slider-content {
  text-align: center; }

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.slider-content .inner button {
  transition-delay: 0s; }

.slider-content .inner h1 {
  font-weight: 400;
  margin: 0 auto;
  max-width: 840px;
  color: #f1e8e8;
  font-size: 48px;
  line-height: 1; }

.slider-content .inner p {
  color: #ffffff;
  font-size: 12px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px; }

.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px; }

.slider-content section span {
  color: #ffffff; }

.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px; }

.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle; }

.slider-content section span strong {
  color: #ffffff;
  font-size: 12px;
  display: block; }

@media (max-height: 500px) {
  .slider-wrapper,
  .slide {
    height: calc(100vh - 75px); } }

@media (max-width: 640px) {
  .slider-wrapper,
  .slide {
    height: calc(80vh - 75px); } }

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px; } }

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px; } }

.slide h1 {
  transition: all 0.2s ease;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  opacity: 0; }

.slide button {
  transition: all 0.2s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0; }

.slide p {
  transition: all 0.2s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0; }

.slide section * {
  transition: all 0.2s ease; }

.slide section img {
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
  opacity: 0; }

.slide section span {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  opacity: 0; }

.slide section span strong {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0; }

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  transition-delay: 0.9s;
  opacity: 1; }

.slide.animateIn.previous p,
.slide.animateIn.next p {
  transition-delay: 1.1s; }

.slide.animateIn.previous button,
.slide.animateIn.next button {
  transition-delay: 1.3s; }

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
  transition-delay: 1.3s; }

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
  transition-delay: 1.4s; }

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
  transition-delay: 1.5s; }

.slide.animateOut h1 {
  transition-delay: 0.2s; }

.slide.animateOut p {
  transition-delay: 0.2s; }

.slide.animateOut section span {
  transition-delay: 0.1s; }

.slide.animateOut section span strong {
  transition-delay: 0s; }

.text-black {
  color: rgba(0, 0, 0, 0.9); }

.text-muted {
  color: rgba(0, 0, 0, 0.3); }

.header-container {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.header-title {
  color: #333333;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 500;
  margin: 100px 20px 50px 20px;
  padding: 20px 0px 20px 30px;
  border-bottom: solid 0.5px #ccc; }

.header-line {
  width: 100px;
  height: 1px;
  background: #606060;
  margin: 0 auto; }

.consult-row {
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
  text-align: center; }

.consult-image {
  width: 100%;
  height: 100%; }

.consult-button {
  color: #333333;
  font-size: 12px;
  font-weight: 200;
  height: 20px;
  letter-spacing: 1px;
  line-height: 30px;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5vh;
  padding-left: 15px;
  padding-top: 30px; }

.notice-card-image {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: auto !important;
  object-fit: cover;
  object-position: center;
  display: block;
  height: auto;
  transition: 0.2s ease-in all; }

.notice-card-image:hover {
  transition: 0.2s ease-in all;
  opacity: 0.6;
  cursor: pointer; }

.image-container {
  margin: auto;
  width: 19vw;
  height: 19vw;
  display: flex; }
  @media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .image-container {
      width: 19vw;
      height: 19vw; } }
  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    .image-container {
      width: 19vw;
      height: 19vw; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .image-container {
      width: 45vw;
      height: 45vw; } }
  @media only screen and (max-width: 576px) {
    .image-container {
      width: 45vw;
      height: 45vw; } }

.image-container-consult {
  margin: auto;
  width: 27vw;
  height: 27vw;
  display: flex;
  transition: 0.2s ease-in all; }
  @media only screen and (max-width: 767px) {
    .image-container-consult {
      width: 79vw;
      height: 79vw; } }

.image-container-consult:hover {
  opacity: 0.6; }

.image-container-class {
  margin: auto;
  width: 27vw;
  height: 27vw;
  display: flex;
  text-align: center; }
  @media only screen and (max-width: 767px) {
    .image-container-class {
      width: 79vw;
      height: 79vw; } }

.temp-text-box {
  height: 150px;
  width: 100%;
  padding: 20px 4vw 20px 4vw; }
  @media only screen and (min-width: 768px) {
    .temp-text-box {
      padding: 10px 3vw 10px 3vw; } }

.card-Style {
  overflow: hidden;
  border-radius: 1px;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0; }
  .card-Style .text-box {
    height: 150px;
    width: 100%;
    padding: 10px 3vw 10px 3vw; }
    @media only screen and (max-width: 767px) {
      .card-Style .text-box {
        padding: 10px 10vw 10px 10vw; } }

.card-title {
  font-weight: normal;
  color: rgba(0, 0, 0, 0.9);
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 1rem;
  line-height: 1.25; }

.card-desc {
  margin-bottom: 1rem;
  font-size: 12px; }

.card-specific {
  text-transform: uppercase;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  margin-left: 2rem;
  text-align: right; }

.card-subtitle {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: rgba(0, 0, 0, 0.4); }

.see-more {
  width: 100%;
  display: flex;
  padding-right: 3vw;
  font-size: 15px;
  font-weight: 500;
  justify-content: flex-end; }

.card-button {
  text-align: right;
  margin-right: 10%; }

.card-footer {
  text-align: right; }

.card-heading {
  font-size: 12px; }

.styled-link {
  text-decoration: none;
  outline: none;
  color: #333;
  transition: all 0.25s ease-in; }

.styled-link:hover,
.styled-link:focus,
.styled-link:active {
  color: #333; }

.shadow-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15); }

.blue-hover {
  transition: all 0.25s ease-in; }

.mb-1 {
  margin-bottom: 1rem; }

.px-2 {
  padding-left: 2rem;
  padding-right: 2rem; }

.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.ModalSize {
  width: 100vw;
  font-size: 12px;
  padding: 10px; }

.ModalTitle {
  font-weight: 500; }

.ModalImage {
  width: 100%;
  height: auto; }

.ModalBtnContainer {
  min-height: 30px;
  margin: 10px 0 10px;
  text-align: center;
  display: block; }

.ModalBtn {
  margin-left: -15px;
  display: inline-block;
  box-sizing: border-box;
  padding: 4px 8px;
  border: 1px solid #d1d1d1;
  border-radius: 0px;
  font-size: 12px;
  line-height: 25px;
  font-weight: normal;
  text-decoration: none;
  vertical-align: middle;
  word-spacing: -0.5px;
  letter-spacing: 0px;
  text-align: center;
  white-space: nowrap;
  color: #222;
  background-color: #fff; }

#wrap {
  width: 100%;
  height: 100%; }

.body {
  overflow-x: hidden; }

.b {
  font-size: 16px; }

.main_wrap {
  width: 100%;
  height: 100%;
  background-image: url(/static/media/story_back.941e2170.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  display: table; }
  @media only screen and (max-width: 720px) {
    .main_wrap {
      background-image: url("https://cdn.pixabay.com/photo/2019/06/05/14/08/nature-4253751_960_720.jpg"); } }

#navbar a {
  width: 25%;
  float: left;
  display: block;
  color: #2c2e65;
  padding: 25px 0px;
  text-align: center;
  text-decoration: none;
  font-size: 14px; }

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 100%; }

.main_wrap .main_h_line {
  width: 80px;
  height: 1px;
  background-color: #fff;
  display: inline-block;
  margin: 5px 0px; }

.main_cont {
  width: 100%;
  max-width: 1400px;
  padding-left: 13%;
  display: table-cell;
  vertical-align: middle;
  margin: 0 auto;
  transition: all 1s; }

p.text1 {
  display: inline-block;
  color: #fff;
  font-weight: 300;
  font-size: 14px; }

h1.text1 b {
  font-weight: 500; }
  @media only screen and (max-width: 1200px) {
    h1.text1 b {
      font-weight: 400; } }
  @media only screen and (max-width: 576) {
    h1.text1 b {
      font-weight: 300; } }

h1.text1 {
  color: #fff;
  font-weight: 300;
  font-size: 120px;
  padding-top: 30px; }
  @media only screen and (min-width: 1200px) and (max-width: 1600px) {
    h1.text1 {
      font-size: 90px;
      font-weight: 80; } }
  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    h1.text1 {
      font-size: 65px;
      font-weight: 70;
      padding-top: 15px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    h1.text1 {
      padding-top: 0px;
      font-size: 50px;
      font-weight: 60; } }
  @media only screen and (max-width: 576px) {
    h1.text1 {
      font-size: 40px;
      padding-top: 0px;
      font-weight: 40; } }

p.text2 {
  color: #fff;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  padding-top: 20px; }
  @media only screen and (min-width: 576px) and (max-width: 1200px) {
    p.text2 {
      font-size: 11px;
      font-weight: 200;
      padding-top: 15px; } }
  @media only screen and (max-width: 576px) {
    p.text2 {
      font-size: 8px;
      padding-top: 5px;
      font-weight: 300; } }

.button_wrap {
  width: auto;
  height: auto;
  margin-top: 40px;
  display: inline-flex; }
  @media only screen and (min-width: 550px) and (max-width: 1200px) {
    .button_wrap {
      margin-top: 10px; } }
  @media only screen and (max-width: 550px) {
    .button_wrap {
      display: none; } }

.main_button2 {
  width: 10vw;
  padding: 18px 0;
  color: #fff;
  font-size: 15px;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 0.5s ease;
  position: relative;
  z-index: 2; }
  @media only screen and (max-width: 767px) {
    .main_button2 {
      font-size: 12px;
      width: 10vw; } }

.main_button2:hover {
  color: rgba(255, 255, 255, 0.85);
  box-shadow: rgba(120, 116, 131, 0.7) 0 0px 0px 40px inset; }

.main_button.a > a {
  background-color: #fff;
  color: #a09f9f; }

.thick {
  color: #333; }

.timeline_container {
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  -webkit-letter-spacing: 0.05em;
  -moz-letter-spacing: 0.05em;
  -ms-letter-spacing: 0.05em;
  letter-spacing: 0.05em; }

.timeline_ul {
  position: relative;
  max-width: 95%;
  list-style: none; }

.timeline_line {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background-color: black;
  content: '';
  margin-left: -1px;
  position: absolute;
  top: 0;
  left: 2em;
  width: 2px;
  height: 100%; }

.timeline_li {
  position: relative; }

.timeline_content {
  padding: 2em 2em 0 2em;
  position: relative;
  top: -1.875em;
  left: 2em;
  width: 95%; }
  @media only screen and (max-width: 768px) {
    .timeline_content {
      padding: 1em 0 0;
      left: 1em; } }

.timeline_content_title {
  color: #606060;
  font-style: oblique;
  display: inline-block;
  font-size: 120%;
  font-weight: 500;
  margin-bottom: 1.2em;
  padding: 0.25em 1em 0.2em 1em; }

.timeline_content_p {
  color: #333;
  font-weight: 300; }

.thin {
  color: #818182; }

.zoom {
  cursor: zoom-in; }

@media (min-width: 768px) {
  .gallery_container {
    padding-left: 11px;
    padding-right: 11px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    max-width: 1320px; } }

#layout {
  color: #fff;
  width: 50%;
  height: 50%;
  margin: 0 auto; }

#navbar {
  width: 90%; }

.stars {
  z-index: 0;
  position: absolute;
  top: 0;
  background-repeat: repeat-x, repeat-x repeat-y;
  -webkit-transform: translate3D(0em, 0em, 0);
          transform: translate3D(0em, 0em, 0);
  -webkit-animation: stars 21s ease;
          animation: stars 21s ease;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d; }

.right_img {
  position: absolute;
  right: 15%;
  top: 40%;
  transition: all 1s; }

.v_line {
  width: 1px;
  height: 25px;
  margin: 0 0 0 100px;
  background-color: #fff; }

.r_text {
  color: #fff;
  font-size: 12px;
  text-align: right;
  line-height: 30px;
  font-weight: 300;
  margin: 20px 0; }

/*animate_box*/
#wrap_box {
  width: 100%;
  height: 50px; }

.animate {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  position: relative;
  bottom: 80px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.22); }

.top_line {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 1px;
  background: #fff; }

.bottom_line {
  position: absolute;
  bottom: 0;
  left: 100%;
  width: 100%;
  height: 1px;
  background: #fff; }

.right_line {
  position: absolute;
  width: 1px;
  height: 100%;
  right: 0;
  top: -100%;
  background: #fff; }

.left_line {
  position: absolute;
  width: 1px;
  height: 100%;
  left: 0;
  top: 100%;
  background: #fff; }

@media (max-width: 1455px) {
  .main_cont {
    padding-left: 7%; } }

@media (max-width: 1008px) {
  .main_cont {
    padding-left: 7%; } }

@media (max-width: 608px) {
  .main_cont {
    width: 100%;
    padding-left: 5%; } }

.contents_wrap {
  width: 100%;
  height: auto;
  border: 1px solid #f00; }

.section {
  width: 94%;
  max-width: 1200px;
  height: auto;
  margin: 0 auto; }

.section .secline {
  margin-top: 60px;
  width: 50px;
  height: 3px;
  background-color: #666;
  margin-bottom: 10px; }

.sec1,
.sec2,
.sec3 {
  padding: 40px 0px 50px 0; }

.sec1-Profile {
  padding: 40px 0px 50px 0;
  background-color: #fff; }

.section .title {
  font-size: 24px;
  display: inline-block;
  font-weight: 300;
  color: #dee2e6; }

.section .s_title {
  font-size: 12px;
  display: inline-block;
  font-weight: 300;
  color: #818182; }

.sec_box_wrap {
  width: 100%;
  height: auto;
  margin-top: 70px; }

.sec_box_wrap ul li:nth-child(1) img {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  display: block;
  margin-top: 20px; }

.sec_box_wrap ul li:nth-child(1) p {
  text-align: center;
  font-size: 14px;
  color: #818182;
  font-weight: 300;
  line-height: 0; }

.sec_box_wrap ul li:nth-child(1) p:nth-child(2) {
  text-align: center;
  font-size: 16px;
  color: #dee2e6;
  line-height: 40px;
  font-weight: 500;
  padding-top: 10px; }

.sec_box_wrap ul li:nth-child(2) h5 {
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
  margin-top: 10px; }

.sec_box_wrap ul li:nth-child(2) p {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px; }

@media (max-width: 608px) {
  .sec_box_wrap ul li:nth-child(2) h5 {
    text-align: center; }
  .sec_box_wrap ul li:nth-child(2) {
    padding: 30px 4% 0px;
    box-sizing: border-box;
    text-align: center;
    position: relative; } }

/* =====================================CSS RESET===================================== */
a {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  color: inherit;
  display: inline-block; }

img {
  vertical-align: middle; }

table {
  border-collapse: collapse; }

* {
  box-sizing: border-box; }

/* =====================================GRID SYSTEM===================================== */
.center {
  width: 1200px;
  margin: 0 auto; }

[class*='col-'] {
  width: 100%;
  float: left;
  border: 5px #fff;
  box-sizing: border-box;
  padding: 0px 0px; }

.Timeline {
  position: relative;
  max-width: 95%;
  list-style: none; }

.TimelineBefore {
  background-color: black;
  content: '';
  margin-left: -1px;
  position: absolute;
  top: 0;
  left: 2em;
  width: 2px;
  height: 100%; }

.TimelineContainer {
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.05em; }

.TimelineContainer * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

.TimelineEvent {
  position: relative; }

.TimelineIcon {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  background-color: black;
  outline: 10px solid white;
  display: block;
  margin: 0.5em 0.5em 0.5em -0.5em;
  position: absolute;
  top: 0;
  left: 2em;
  width: 1em;
  height: 1em; }

.TimelineBody {
  padding: 2em 2em 0 2em;
  position: relative;
  top: -1.875em;
  left: 2em;
  width: 95%; }

.TimelineBody .h3 {
  font-size: 1.75em; }

.TimelineBody .h4 {
  font-size: 1.2em;
  margin-bottom: 1.2em; }

.TimelineDate {
  color: white;
  background-color: black;
  box-shadow: inset 0 0 0 0em #ef795a;
  display: inline-block;
  margin-bottom: 1.2em;
  padding: 0.25em 1em 0.2em 1em; }

.TimelineDescription .strong {
  font-weight: 700; }

.TimelineDescription .p {
  padding-bottom: 1.2em; }

@media only screen and (max-width: 1024px) {
  .Profile .profile-picture-block .profile-picture {
    -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
            transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 0.5; }
  .Profile .container {
    width: 90vw; }
  .Profile .container .title {
    margin-left: 0;
    width: 100%; }
  .Profile .container .content {
    width: 80%;
    font-size: 2vmax;
    line-height: 150%; }
  .Profile .container .button,
  .Profile .container .content {
    margin-top: 4vmax;
    margin-left: 0; }
  .Profile .container .update {
    margin-top: 2vmax;
    margin-left: 0;
    font-size: 1.3vmax;
    color: #aaa; } }

@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .Profile .container .content {
    width: 80%;
    font-size: 1.75vmax; }
  .Profile .container .update {
    display: none; } }

.NotFound {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0; }

.NotFound .animate {
  -webkit-transform: translateY(10%);
          transform: translateY(10%);
  opacity: 0; }

.NotFound .container {
  position: absolute;
  width: 100%; }

.NotFound .container .content {
  margin: 0 auto;
  width: 80vw;
  padding: 11.24vw 0 2.81vw; }

.NotFound .container .content .title {
  width: 35vw; }

.NotFound .container .content .description {
  margin-top: 4vw;
  width: 50%;
  line-height: 150%;
  word-break: break-all; }

.NotFound .container .content .button-block {
  margin-top: 2vw; }

@media only screen and (max-width: 1024px) {
  .NotFound .container .content {
    padding: 10vmax 0 12vmax; } }

.story-gallery {
  max-width: 70vw;
  margin: auto; }
  @media only screen and (max-width: 768px) {
    .story-gallery {
      max-width: 90vw; } }

.story-title-container {
  width: 94%;
  max-width: 1200px;
  height: auto;
  margin: 0 auto;
  padding: 40px 0px 50px 0; }

.story-title {
  font-size: 24px;
  display: inline-block;
  font-weight: 300;
  color: #333; }

.story-stitle {
  font-size: 12px;
  display: inline-block;
  font-weight: 300;
  color: #818182; }

.title-line {
  margin-top: 60px;
  width: 50px;
  height: 3px;
  background-color: #666;
  margin-bottom: 10px; }

.clearfix:after {
  content: '';
  display: block;
  clear: both; }

.display-block {
  display: block; }

.blog-card {
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
  box-shadow: 0 3px 7px -1px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.6%;
  background: #fff;
  line-height: 1.4;
  border-radius: 5px;
  overflow: hidden;
  z-index: 0; }
  .blog-card a {
    color: inherit; }
    .blog-card a:hover {
      color: #333; }
  .blog-card:hover .photo {
    -webkit-transform: scale(1.3) rotate(3deg);
            transform: scale(1.3) rotate(3deg); }
  .blog-card .meta {
    position: relative;
    z-index: 0;
    height: 200px; }
  .blog-card .photo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s; }
  .blog-card .details,
  .blog-card .details ul {
    margin: auto;
    padding: 0;
    list-style: none; }
  .blog-card .details {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    transition: left 0.2s;
    color: #fff;
    padding: 10px;
    width: 100%;
    font-size: 0.7rem; }
    .blog-card .details a {
      -webkit-text-decoration: dotted underline;
              text-decoration: dotted underline; }
    .blog-card .details ul li {
      display: inline-block; }
    .blog-card .details .author:before {
      margin-right: 10px;
      content: '\F007'; }
    .blog-card .details .date:before {
      margin-right: 10px;
      content: '\F133'; }
    .blog-card .details .tags ul:before {
      content: '\F02B';
      margin-right: 10px; }
    .blog-card .details .tags li {
      margin-right: 2px; }
      .blog-card .details .tags li:first-child {
        margin-left: -4px; }
  .blog-card .description {
    padding: 1rem;
    background: #fff;
    position: relative;
    z-index: 1; }
    .blog-card .description h1,
    .blog-card .description h2 {
      font-weight: 500; }
    .blog-card .description h1 {
      line-height: 1;
      margin: 0;
      font-size: 1.2rem; }
    .blog-card .description h2 {
      font-size: 1rem;
      font-weight: 300;
      text-transform: uppercase;
      color: #a2a2a2;
      margin-top: 5px; }
    .blog-card .description .read-more {
      text-align: right; }
      .blog-card .description .read-more a {
        color: #333;
        display: inline-block;
        position: relative; }
        .blog-card .description .read-more a:after {
          margin-left: -10px;
          opacity: 0;
          vertical-align: middle;
          transition: margin 0.3s, opacity 0.3s; }
        .blog-card .description .read-more a:hover:after {
          margin-left: 5px;
          opacity: 1; }
  .blog-card p {
    position: relative;
    margin: 1rem 0 0; }
    .blog-card p:first-of-type {
      margin-top: 1.25rem; }
      .blog-card p:first-of-type:before {
        content: '';
        position: absolute;
        height: 5px;
        background: #333;
        width: 70px;
        top: -0.75rem;
        border-radius: 3px; }
  .blog-card:hover .details {
    left: 0%; }
  @media (min-width: 640px) {
    .blog-card {
      flex-direction: row;
      max-width: 700px; }
      .blog-card .meta {
        flex-basis: 40%;
        height: auto; }
      .blog-card .description {
        flex-basis: 60%; }
        .blog-card .description:before {
          -webkit-transform: skewX(-3deg);
                  transform: skewX(-3deg);
          content: '';
          background: #fff;
          width: 30px;
          position: absolute;
          left: -10px;
          top: 0;
          bottom: 0;
          z-index: -1; }
      .blog-card.alt {
        flex-direction: row-reverse; }
        .blog-card.alt .description:before {
          left: inherit;
          right: -10px;
          -webkit-transform: skew(3deg);
                  transform: skew(3deg); }
        .blog-card.alt .details {
          padding-left: 25px; } }

.background-profile1 {
  background-image: url(/static/media/1.8f3be53a.jpg); }

.background-profile2 {
  background-image: url(/static/media/logo.f56bb084.jpg); }

.story-body {
  margin-left: initial;
  margin-right: initial; }

@media only screen and (max-width: 768px) {
  .story-col {
    padding-right: 0; } }

.major-image-container {
  width: 100%; }

.major-subtitle {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4); }

.major-image {
  box-shadow: 0px 0px 20px 2px rgba(153, 153, 153, 0.2);
  object-fit: cover;
  max-width: 300px;
  max-height: 300px;
  margin: auto;
  transition: 0.2s ease-in all; }
  @media only screen and (max-width: 1200px) {
    .major-image {
      max-width: 160px;
      max-height: 160px; } }

.major-image:hover {
  transition: 0.2s ease-in all;
  opacity: 0.6;
  cursor: pointer; }

.Wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px solid #000;
  min-height: 85vh;
  -webkit-animation: top-down-animation;
          animation: top-down-animation;
  transition: 0.3s all ease-in; }

.titleWrap {
  width: 90%;
  margin: 0 5% 0 5%;
  line-height: 40px;
  text-align: center;
  border-bottom-style: solid;
  border-bottom-width: 1px; }

.titleWrap .title {
  width: 100%;
  text-align: center;
  height: 50px;
  margin: auto; }

.titleWrap .title .member {
  font-weight: 700;
  font-size: 16px;
  color: #333; }

.loginWrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #3f3f3f;
  font-size: 12px; }

.loginInput {
  width: 90%;
  overflow: hidden;
  margin-bottom: 2px;
  line-height: 40px; }

.loginInput input {
  outline: none;
  width: 100%;
  height: 40px;
  font-size: 12px;
  padding: 2px 10px 2px 10px;
  margin-top: 10px;
  line-height: 40px;
  background-color: #fff; }

.loginInput input:focus {
  outline: none; }

.loginButtonWrap {
  left: 20px;
  top: 155px;
  width: 100%;
  margin: 10px 0 0;
  border: 1px #d8d6d6; }

.loginButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin-top: 15px;
  height: 40px;
  background-color: #333;
  color: #fff;
  font-size: 12px;
  font-weight: 700; }

.signWrap {
  left: 428px;
  top: 0px;
  width: 300px;
  text-align: center;
  border: 0px solid #000; }

.signMsg {
  text-align: left;
  padding: 5px 0;
  line-height: 20px;
  color: #333;
  text-decoration: none;
  font-size: 12px; }

.signInBtn {
  width: 300px;
  line-height: 14px;
  padding: 10px 0;
  font-size: 11px;
  text-align: center;
  margin: 0;
  letter-spacing: 0.5px;
  display: inline-block;
  background: #f2f2f2;
  color: #000000;
  margin-bottom: 15px; }

@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
    margin-top: 100px; }
  .Login form {
    margin: 0 auto;
    max-width: 320px; } }

.payWrap {
  position: relative;
  width: 1240px;
  margin: 150px auto 0; }

.payTitle {
  min-height: 30px;
  margin: 10px 0 100px;
  text-align: center;
  display: block; }
  .payTitle h2 {
    display: block;
    margin: 0;
    padding: 0 0 10px;
    font-size: 20px;
    color: #606060;
    font-weight: 700;
    *display: inline;
    *zoom: 1; }

.tempText {
  font-size: 12px; }

.SignUpContainer {
  margin-top: 25px; }
  .SignUpContainer .SignUpTitle {
    position: relative;
    width: 100%;
    height: 50px;
    margin: 0;
    padding: 0 0 0 9px;
    line-height: 50px; }
    .SignUpContainer .SignUpTitle h3 {
      display: inline-block;
      vertical-align: middle;
      color: #353535;
      font-size: 12px;
      font-weight: 700; }
    .SignUpContainer .SignUpTitle .required {
      margin: -15px 0 0;
      color: #353535;
      text-align: right; }
  .SignUpContainer .SignUpTable {
    margin-top: 10px;
    border: 1px solid #e8e8e8;
    border-width: 1px 0;
    display: contents;
    border-color: inherit; }
    .SignUpContainer .SignUpTable .tableCol {
      padding: 11px 0 10px;
      border-bottom: 1px solid #e8e8e8;
      color: #353535;
      font-weight: 500;
      background: #fafafa; }
  .SignUpContainer .SignUpContent {
    display: contents;
    margin: 10px 0 0;
    line-height: 1.5;
    border-bottom: 1px solid #e8e8e8; }
    .SignUpContainer .SignUpContent .contentCol {
      margin: auto;
      padding: 8px 10px 7px;
      background-color: #fff; }
      .SignUpContainer .SignUpContent .contentCol .contentImg {
        width: 100%;
        height: auto;
        padding: 5px; }
  .SignUpContainer .required {
    margin: -15px 0 0;
    color: #353535;
    text-align: right; }

.SignUpForm {
  margin-top: 25px;
  border: 1px solid #e8e8e8;
  border-width: 1px 0; }
  .SignUpForm .inputLine {
    height: auto; }
    .SignUpForm .inputLine .left {
      padding: 11px 0 10px 18px;
      border: 1px solid #e8e8e8;
      color: #353535;
      text-align: left;
      font-weight: normal; }
      @media only screen and (max-width: 360px) {
        .SignUpForm .inputLine .left {
          width: 320px; } }
    .SignUpForm .inputLine .right {
      padding: 11px 10px 10px;
      border-top: 1px solid #e8e8e8;
      color: #353535;
      vertical-align: middle;
      word-break: break-all;
      word-wrap: break-word; }
    .SignUpForm .inputLine .inputStyle {
      line-height: 27px;
      padding: 2px 4px;
      border: 1px solid #d5d5d5;
      color: #353535;
      font-size: 12px; }

.SignUpContent {
  display: contents;
  margin: 10px 0 0;
  line-height: 1.5;
  border-bottom: 1px solid #e8e8e8; }
  .SignUpContent .contentCol {
    margin: auto;
    padding: 8px 10px 7px;
    background-color: #fff; }
    .SignUpContent .contentCol .agreeHeader {
      position: relative;
      z-index: 2;
      border-width: 1px;
      border-color: #d7d5d5;
      padding: 20px;
      margin-top: 50px;
      margin-left: auto;
      margin-right: auto;
      border: 1px solid #e8e8e8;
      color: #404040;
      width: 100%; }
      .SignUpContent .contentCol .agreeHeader .agreeP {
        margin: auto;
        font-size: 14px;
        color: #353535;
        line-height: 22px; }
        .SignUpContent .contentCol .agreeHeader .agreeP .checkSpan {
          display: inline-block;
          position: relative;
          margin: 0 8px 0 0;
          width: 22px;
          height: 22px;
          vertical-align: top;
          cursor: pointer; }
          .SignUpContent .contentCol .agreeHeader .agreeP .checkSpan .checkEm {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url(//img.echosting.cafe24.com/skin/base/common/bg_join_check.png) no-repeat 0 0; }
  .SignUpContent .SignUpForm {
    width: 1200px;
    margin-top: 25px;
    border: 1px solid #e8e8e8;
    border-width: 1px 0; }
    .SignUpContent .SignUpForm .inputLine {
      display: flex;
      height: auto; }
      .SignUpContent .SignUpForm .inputLine .left {
        padding: 11px 0 10px 18px;
        border: 1px solid #e8e8e8;
        color: #353535;
        text-align: left;
        font-weight: normal;
        background-color: #efefef;
        vertical-align: top; }
      .SignUpContent .SignUpForm .inputLine .right {
        padding: 11px 10px 10px;
        border-top: 1px solid #e8e8e8;
        color: #353535;
        vertical-align: middle;
        word-break: break-all;
        word-wrap: break-word; }
      .SignUpContent .SignUpForm .inputLine .inputStyle {
        line-height: 27px;
        padding: 2px 4px;
        border: 1px solid #d5d5d5;
        color: #353535;
        font-size: 12px; }
  .SignUpContent .searchBtn {
    margin-left: 5px;
    display: inline-block;
    box-sizing: border-box;
    padding: 4px 8px;
    border: 1px solid #d1d1d1;
    border-radius: 0px;
    font-size: 12px;
    line-height: 25px;
    font-weight: normal;
    text-decoration: none;
    vertical-align: middle;
    word-spacing: -0.5px;
    letter-spacing: 0px;
    text-align: center;
    white-space: nowrap;
    color: #222;
    background-color: #fff; }
  .SignUpContent .agreeBody {
    color: #747474;
    line-height: 20px;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #e8e8e8;
    border-width: 1px;
    border-color: #d7d5d5; }
    .SignUpContent .agreeBody .agreeBodyTitle {
      margin: 0 0 10px;
      font-size: 12px;
      color: #353535; }
    .SignUpContent .agreeBody .agreeBodyContent {
      overflow: auto;
      height: 110px;
      padding: 20px;
      border: 1px solid #d5d5d5;
      background: #fff;
      font-size: 11px; }
      .SignUpContent .agreeBody .agreeBodyContent .contents {
        padding: 0 0 17px; }
      .SignUpContent .agreeBody .agreeBodyContent .privacyTitle {
        font-weight: 700; }

.agreeBody {
  color: #747474;
  line-height: 20px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #e8e8e8;
  border-width: 1px;
  border-color: #d7d5d5;
  background-color: #f5f5f5; }
  .agreeBody .agreeBodyTitle {
    margin: 0 0 10px;
    font-size: 12px;
    color: #353535; }
  .agreeBody .agreeBodyContent {
    overflow: auto;
    height: 110px;
    padding: 20px;
    border: 1px solid #d5d5d5;
    background: #fff;
    font-size: 11px; }
    .agreeBody .agreeBodyContent .contents {
      padding: 0 0 17px; }
    .agreeBody .agreeBodyContent .privacyTitle {
      font-weight: 700; }

.agreeFooter {
  padding: 7px 0 0 0;
  color: #202020;
  font-size: 12px; }
  .agreeFooter .footerCheck {
    margin-top: px;
    width: 13px;
    height: 13px;
    border: 0; }
  .agreeFooter .footerLabel {
    margin: 0 4px 0 2px; }

.signUpBtn {
  width: 120px;
  padding-left: 8px;
  padding-right: 8px;
  display: inline-block;
  box-sizing: border-box;
  padding: 10px 16px;
  border: 1px solid transparent;
  border-radius: 0px;
  font-size: 12px;
  line-height: 18px;
  font-weight: normal;
  text-decoration: none;
  vertical-align: middle;
  word-spacing: -0.5px;
  letter-spacing: 0px;
  text-align: center;
  white-space: nowrap;
  color: #fff;
  background-color: #434343; }

.notValid {
  font-size: 10px;
  color: #ff0000; }

.notValid2 {
  font-size: 10px;
  color: #606060; }

.payWrap {
  position: relative;
  width: 1240px;
  margin: 150px auto 0; }

.payTitle {
  min-height: 30px;
  margin: 10px 0 100px;
  text-align: center;
  display: block; }
  .payTitle h2 {
    display: block;
    margin: 0;
    padding: 0 0 10px;
    font-size: 20px;
    color: #606060;
    font-weight: 700;
    *display: inline;
    *zoom: 1; }

.tempText {
  font-size: 12px; }

.orderContainer {
  width: 1200px; }
  .orderContainer .orderTitle {
    margin-bottom: 30px;
    position: relative;
    width: 100%;
    height: 50px;
    padding: 0 0 0 9px;
    border: 1px solid #d7d5d5;
    border-bottom: 0;
    line-height: 50px;
    background: #f6f6f6; }
    .orderContainer .orderTitle h3 {
      display: inline-block;
      vertical-align: middle;
      color: #353535;
      font-size: 12px;
      font-weight: 700; }
    .orderContainer .orderTitle .required {
      margin: -15px 0 0;
      color: #353535;
      text-align: right; }
  .orderContainer .orderTable {
    margin-top: 10px;
    border-bottom: 1px solid #e8e8e8;
    border-width: 1px 0;
    display: flex;
    border-color: inherit; }
    .orderContainer .orderTable .tableCol {
      padding: 11px 0 10px;
      border-bottom: 1px solid #e8e8e8;
      color: #353535;
      font-weight: 500;
      background: #efefef; }
  .orderContainer .orderContent {
    width: 1240px;
    display: flex;
    margin: 10px 0 0;
    line-height: 1.5;
    border-bottom: 1px solid #e8e8e8; }
    .orderContainer .orderContent .contentCol {
      margin: auto;
      padding: 8px 10px 7px;
      background-color: #efefef; }
      .orderContainer .orderContent .contentCol .contentImg {
        width: 60%;
        height: auto;
        padding: 5px; }

.orderForm {
  width: 100%;
  margin-top: 25px;
  border: 1px solid #e8e8e8;
  border-width: 1px 0; }
  .orderForm .inputLine {
    display: flex;
    height: auto; }
    .orderForm .inputLine .left {
      width: 300px;
      padding: 11px 0 10px 18px;
      border: 1px solid #e8e8e8;
      color: #353535;
      text-align: left;
      font-weight: normal;
      background-color: #efefef;
      vertical-align: top; }
    .orderForm .inputLine .right {
      padding: 11px 10px 10px;
      border-top: 1px solid #e8e8e8;
      color: #353535;
      vertical-align: middle;
      word-break: break-all;
      word-wrap: break-word; }
    .orderForm .inputLine .inputStyle {
      line-height: 27px;
      padding: 2px 4px;
      border: 1px solid #d5d5d5;
      color: #353535;
      font-size: 12px; }

.searchBtn {
  margin-left: 5px;
  display: inline-block;
  box-sizing: border-box;
  padding: 4px 8px;
  border: 1px solid #d1d1d1;
  border-radius: 0px;
  font-size: 12px;
  line-height: 25px;
  font-weight: normal;
  text-decoration: none;
  vertical-align: middle;
  word-spacing: -0.5px;
  letter-spacing: 0px;
  text-align: center;
  white-space: nowrap;
  color: #222;
  background-color: #fff; }

.agreeContainer {
  width: 100%;
  overflow: hidden;
  margin: 20px 0 20px;
  padding: 10px 14px 0 4px;
  border: 1px solid #d7d5d5; }
  .agreeContainer .checkLine {
    width: 100%;
    min-height: 14px;
    padding: 10px;
    margin: 10px -10px 10px 10px;
    background: #fbfafa; }
  .agreeContainer .agreeContent {
    width: 100%;
    padding: 10px 0 10px; }
    .agreeContainer .agreeContent .agreeBox {
      position: relative;
      margin: 10px 10px 20px;
      padding-left: 160px; }
      .agreeContainer .agreeContent .agreeBox .agreeTitle {
        position: absolute;
        top: 0;
        left: 0;
        width: 150px;
        font-size: 12px;
        font-weight: 700; }

.payMethodContainer {
  overflow: hidden;
  position: relative;
  padding: 0 241px 0 0;
  border: 1px solid #777;
  color: #353535;
  line-height: 1.5; }
  .payMethodContainer .payment {
    float: left;
    width: 100%; }
  .payMethodContainer .methodHeader {
    padding: 17px 20px 15px;
    font-weight: bold;
    border-bottom: 3px double #dedede; }
  .payMethodContainer .methodSpan {
    display: inline-block;
    margin: 0 15px 5px 0; }

.text-black {
  color: rgba(0, 0, 0, 0.9); }

.text-muted {
  color: rgba(0, 0, 0, 0.3); }

.header-container {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.header-title {
  color: #333333;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 500;
  margin: 100px 20px 50px 20px;
  padding: 20px 0px 20px 30px;
  border-bottom: solid 0.5px #ccc; }

.header-line {
  width: 100px;
  height: 1px;
  background: #606060;
  margin: 0 auto; }

.consult-row {
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
  text-align: center; }

.consult-image {
  width: 100%;
  height: 100%; }

.consult-button {
  color: #333333;
  font-size: 12px;
  font-weight: 200;
  height: 20px;
  letter-spacing: 1px;
  line-height: 30px;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5vh;
  padding-left: 15px;
  padding-top: 30px; }

.card-image {
  box-shadow: 0px 0px 20px 2px rgba(153, 153, 153, 0.2);
  object-fit: cover;
  object-position: center;
  width: auto;
  max-width: 75%;
  max-height: 75%;
  margin: auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  transition: 0.2s ease-in all; }

.card-image:hover {
  transition: 0.2s ease-in all;
  opacity: 0.6;
  cursor: pointer; }

.image-container {
  width: 20vw;
  display: flex;
  text-align: center; }
  @media only screen and (max-width: 767px) {
    .image-container {
      width: 40vw; } }

.title-box {
  margin-top: 10px;
  text-align: center;
  height: 80px;
  width: 100%;
  padding: 8px 1vw 8px 1vw; }
  @media only screen and (min-width: 768px) {
    .title-box {
      padding: 8px 1vw 8px 1vw; } }

.card-style {
  display: flex;
  box-shadow: 2px 5px 10px 2px rgba(153, 153, 153, 0.5);
  padding: 10px;
  overflow: hidden;
  border-radius: 30px;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 10px 0px; }
  .card-style .text-box {
    height: 60px;
    width: 100%;
    padding: 10px 3vw 10px 3vw; }
    @media only screen and (max-width: 767px) {
      .card-style .text-box {
        padding: 10px 10vw 10px 10vw; } }

.card-title {
  font-weight: normal;
  color: rgba(0, 0, 0, 0.9);
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 1rem;
  line-height: 1.25; }

.card-desc {
  margin-bottom: 1rem;
  font-size: 12px; }

.card-specific {
  text-transform: uppercase;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  margin-left: 2rem;
  text-align: right; }

.card-subtitle {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: rgba(0, 0, 0, 0.4); }

.see-more {
  width: 100%;
  display: flex;
  padding-right: 3vw;
  font-size: 15px;
  font-weight: 500;
  justify-content: flex-end; }

.card-button {
  text-align: right;
  margin-right: 10%; }

.card-footer {
  text-align: right; }

.card-heading {
  font-size: 12px; }

.styled-link {
  text-decoration: none;
  outline: none;
  color: #333;
  transition: all 0.25s ease-in; }

.styled-link:hover,
.styled-link:focus,
.styled-link:active {
  color: #333; }

.shadow-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15); }

.blue-hover {
  transition: all 0.25s ease-in; }

.mb-1 {
  margin-bottom: 1rem; }

.px-2 {
  padding-left: 2rem;
  padding-right: 2rem; }

.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.ModalSize {
  width: 100vw;
  font-size: 12px;
  padding: 10px; }

.ModalTitle {
  font-weight: 500; }

.ModalImage {
  width: 100%;
  height: auto; }

.ModalBtnContainer {
  min-height: 30px;
  margin: 10px 0 10px;
  text-align: center;
  display: block; }

.ModalBtn {
  margin-left: -15px;
  display: inline-block;
  box-sizing: border-box;
  padding: 4px 8px;
  border: 1px solid #d1d1d1;
  border-radius: 0px;
  font-size: 12px;
  line-height: 25px;
  font-weight: normal;
  text-decoration: none;
  vertical-align: middle;
  word-spacing: -0.5px;
  letter-spacing: 0px;
  text-align: center;
  white-space: nowrap;
  color: #222;
  background-color: #fff; }

.display-container {
  padding-top: 50px; }

div[disabled] {
  pointer-events: none;
  opacity: 0.7; }

.react-paginate {
  width: -webkit-fill-available;
  max-width: 1020px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  margin: 20px 5px;
  border-radius: 2rem;
  background: #ede5e6;
  box-shadow: 0 0.8rem 2rem rgba(90, 97, 129, 0.05);
  padding: initial; }
  .react-paginate .previousBtn {
    color: darkgrey; }
    .react-paginate .previousBtn.active {
      color: darkgrey;
      pointer-events: initial; }
      .react-paginate .previousBtn.active:hover {
        color: #fff;
        font-weight: bold; }
  .react-paginate .nextBtn {
    color: darkgrey; }
    .react-paginate .nextBtn.active {
      color: darkgrey;
      pointer-events: initial; }
      .react-paginate .nextBtn.active:hover {
        color: #fff;
        font-weight: bold; }
  .react-paginate .currentPage {
    font-weight: bold;
    color: black; }

.paginate-container {
  text-align: center; }

.react-paginate li {
  display: inherit;
  justify-content: center;
  align-items: center;
  margin: 1%;
  font-size: 0.9rem;
  cursor: pointer;
  width: 1.8rem;
  height: 2.6rem;
  border-radius: 0.4rem;
  color: darkgrey; }
  @media only screen and (max-width: 760px) {
    .react-paginate li {
      font-size: 0.8rem; } }
  .react-paginate li a {
    text-decoration: none; }
  .react-paginate li:hover {
    color: black; }
  .react-paginate li.active {
    color: #ffffff;
    font-weight: 600;
    border: 1px solid var(--primary); }

.soldout-circle {
  background: #f00;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  float: right;
  margin-top: 3.5px; }


@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');
@import url(https://fonts.googleapis.com/css?family=Lora|Roboto:400,500);

.button {
  -webkit-appearance: none;
  appearance: none;
  -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all 0.5s ease;
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border: none;
  background: #fff;
  border-radius: 30px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 15px 40px;
  font-weight: 400;
  font-size: 12px;
  cursor: cursor;
}

button:hover {
  color: #ffffff;
  background: #222222;
  -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
}

.slider-wrapper {
  height: 100vh;
  min-width: 100vw;
}

.slide {
  height: 100vh;
  background-size: cover !important;
}

.slide::before {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(rgba(0, 0, 0, 0.9))
  );
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
  bottom: 0;
  left: 0;
}

.previousButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  // background:url("https://www.freepik.com/free-icon/right-arrow_870117.html")  no-repeat center center /
  //   16px;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  cursor: pointer;
}

.previousButton:hover,
.nextButton:hover {
  // // background: url("http://svgshare.com/i/41_.svg") no-repeat center center /
  //   16px;
}

.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
  transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton:hover {
  left: -10px;
}

.nextButton {
  right: 0;
}

.nextButton:hover {
  right: -10px;
}

.slider-content {
  text-align: center;
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slider-content .inner button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.slider-content .inner h1 {
  font-weight: 400;
  margin: 0 auto;
  max-width: 840px;
  color: #f1e8e8;
  font-size: 48px;
  line-height: 1;
}

.slider-content .inner p {
  color: #ffffff;
  font-size: 12px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
}

.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.slider-content section span {
  color: #ffffff;
}

.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}

.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}

.slider-content section span strong {
  color: #ffffff;
  font-size: 12px;
  display: block;
}
@media (max-height: 500px) {
  .slider-wrapper,
  .slide {
    height: calc(100vh - 75px);
  }
}

@media (max-width: 640px) {
  .slider-wrapper,
  .slide {
    height: calc(80vh - 75px);
  }
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

.slide h1 {
  transition: all 0.2s ease;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  opacity: 0;
}

.slide button {
  transition: all 0.2s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.slide p {
  transition: all 0.2s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.slide section * {
  transition: all 0.2s ease;
}

.slide section img {
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
  opacity: 0;
}

.slide section span {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  opacity: 0;
}

.slide section span strong {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0;
}

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
  opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
  -webkit-transition-delay: 1.4s;
  transition-delay: 1.4s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
}

.slide.animateOut h1 {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.slide.animateOut p {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.slide.animateOut section span {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.slide.animateOut section span strong {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

//###############Notice Board
//  body {
//     padding: 0;
//     font-size: 16px;
//     line-height: 1.5;
//     text-rendering: optimizeLegibility;
//     font-variant-ligatures: none;
//     box-sizing: border-box;
//     font-family: 'Roboto', sans-serif;
//     background-color: #efefef;
//  }
//  body::before,
//  body::after {
//    content: "";
//    display: table;
//    clear: both;
//  }
//  body * {
//    box-sizing: inherit;
//  }
//  p, h1 {
//    margin: 0; padding: 0;
// }

//   body, .text-light-black {
//     color: rgba(0,0,0,0.6);
//  }
.text-black {
  color: rgba(0, 0, 0, 0.9);
}
.text-muted {
  color: rgba(0, 0, 0, 0.3);
}

.header-container {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.header-title {
  color: rgb(51, 51, 51);
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 500;
  margin: 100px 20px 50px 20px;
  padding: 20px 0px 20px 30px;
  border-bottom: solid 0.5px #ccc;
}
.header-line {
  width: 100px;
  height: 1px;
  background: #606060;
  margin: 0 auto;
}
.consult-row {
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
  text-align: center;
}
.consult-image {
  width: 100%;
  height: 100%;
}
.consult-button {
  color: rgb(51, 51, 51);
  font-size: 12px;
  font-weight: 200;
  height: 20px;
  letter-spacing: 1px;
  line-height: 30px;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5vh;
  padding-left: 15px;
  padding-top: 30px;
}

.notice-card-image {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: auto !important;
  object-fit: cover;
  object-position: center;
  display: block;

  height: auto;
  transition: 0.2s ease-in all;
}
.notice-card-image:hover {
  transition: 0.2s ease-in all;
  opacity: 0.6;
  cursor: pointer;
}

.image-container {
  margin: auto;
  width: 19vw;
  height: 19vw;
  display: flex;

  @media only screen and (min-width: 1200px) and (max-width: 1600px) {
    width: 19vw;
    height: 19vw;
  }
  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    width: 19vw;
    height: 19vw;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    width: 45vw;
    height: 45vw;
  }
  @media only screen and (max-width: 576px) {
    width: 45vw;
    height: 45vw;
  }
}

.image-container-consult {
  margin: auto;
  width: 27vw;
  height: 27vw;
  display: flex;
  transition: 0.2s ease-in all;

  @media only screen and (max-width: 767px) {
    width: 79vw;
    height: 79vw;
  }
}

.image-container-consult:hover {
  opacity: 0.6;
}
.image-container-class {
  margin: auto;
  width: 27vw;
  height: 27vw;
  display: flex;
  text-align: center;

  @media only screen and (max-width: 767px) {
    width: 79vw;
    height: 79vw;
  }
}
.temp-text-box {
  height: 150px;
  width: 100%;
  padding: 20px 4vw 20px 4vw;
  @media only screen and (min-width: 768px) {
    padding: 10px 3vw 10px 3vw;
  }
}
.card-Style {
  overflow: hidden;
  border-radius: 1px;
  position: relative;
  //display:block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  .text-box {
    height: 150px;
    width: 100%;
    padding: 10px 3vw 10px 3vw;
    @media only screen and (max-width: 767px) {
      padding: 10px 10vw 10px 10vw;
    }
  }
}
.card-title {
  font-weight: normal;
  color: rgba(0, 0, 0, 0.9);
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 1rem;
  line-height: 1.25;
}
.card-desc {
  margin-bottom: 1rem;
  font-size: 12px;
}
.card-specific {
  text-transform: uppercase;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  margin-left: 2rem;
  text-align: right;
}
.card-subtitle {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: rgba(0, 0, 0, 0.4);
}
.see-more {
  width: 100%;
  display: flex;
  padding-right: 3vw;
  font-size: 15px;
  font-weight: 500;
  justify-content: flex-end;
}
.card-button {
  text-align: right;
  margin-right: 10%;
}
.card-footer {
  text-align: right;
}
.card-heading {
  font-size: 12px;
}
.styled-link {
  text-decoration: none;
  outline: none;
  color: #333;
  transition: all 0.25s ease-in;
}
.styled-link:hover,
.styled-link:focus,
.styled-link:active {
  color: #333;
}
.shadow-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
}
.blue-hover {
  transition: all 0.25s ease-in;
}

.mb-1 {
  margin-bottom: 1rem;
}

.px-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

//#########modal#########/

.ModalSize {
  width: 100vw;
  font-size: 12px;
  padding: 10px;
}

// /* popup */

// .popup{
//   width: 100%;
//   height: 100%;
//   display: none;
//   top: 0;
//   right: 0;
// }

// #popup-article:target{
//   display:flex;
// }

// .popup:before{
//   content: "";
//   box-sizing: border-box;
//   width: 100%;
//   background-color: #333;

//   position: fixed;
//   left: 0;
//   top: 30%;
//   will-change: height, top;
//   animation: open-animation .6s cubic-bezier(0.83, 0.04, 0, 1.16) .65s both;
// }

// .popup:after{
//   content: "";
//   width: 0;
//   height: 2px;
//   background-color: #f0f0f0;

//   will-change: width, opacity;
//   animation: line-animation .6s cubic-bezier(0.83, 0.04, 0, 1.16) both;
//   left: 0;
//   margin-top: -1px;
// }

// @keyframes line-animation{

//   0%{
//     width: 0;
//     opacity: 1;
//   }

//   99%{
//     width: 100%;
//     opacity: 1;
//   }

//   100%{
//     width: 100%;
//     opacity: 0;
//   }
// }

// @keyframes open-animation{

//   0%{
//     height: 0;
//     top: 50%;
//   }

//   100%{
//     height: 100vh;
//     top: 0;
//   }
// }

// .popup__block{
//   height: calc(100vh - 40px);
//   padding: 5% 15%;
//   box-sizing: border-box;
//   position: relative;

//   margin: auto;
//   overflow: auto;
//   animation: fade .5s ease-out 1.3s both;
// }

// @keyframes fade{

//   0%{
//     opacity: 0;
//   }

//   100%{
//     opacity: 1;
//   }
// }

// .popup__title{
//   font-size: 2.5rem;
//   margin: 0 0 1em;
// }

// .popup__close{
//   width: 3.2rem;
//   height: 3.2rem;
//   text-indent: -9999px;

//   position: fixed;
//   top: 20px;
//   right: 20px;

//   background-repeat: no-repeat;
//   background-position: center center;
//   background-size: contain;
//   background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTE5IDYuNDFMMTcuNTkgNSAxMiAxMC41OSA2LjQxIDUgNSA2LjQxIDEwLjU5IDEyIDUgMTcuNTkgNi40MSAxOSAxMiAxMy40MSAxNy41OSAxOSAxOSAxNy41OSAxMy40MSAxMnoiLz4gICAgPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==);
// }

// .open-popup{
//   color: #fff;
//   text-transform: uppercase;
//   padding: 1rem 2rem;
//   border: 1px solid;
// }

.ModalTitle {
  font-weight: 500;
}
.ModalImage {
  width: 100%;
  height: auto;
}
.ModalBtnContainer {
  min-height: 30px;
  margin: 10px 0 10px;
  text-align: center;
  display: block;
}
.ModalBtn {
  margin-left: -15px;
  display: inline-block;
  box-sizing: border-box;
  padding: 4px 8px;
  border: 1px solid #d1d1d1;
  border-radius: 0px;
  font-size: 12px;
  line-height: 25px;
  font-weight: normal;
  text-decoration: none;
  vertical-align: middle;
  word-spacing: -0.5px;
  letter-spacing: 0px;
  text-align: center;
  white-space: nowrap;
  color: #222;
  background-color: #fff;
}

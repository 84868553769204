@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');
@import url(https://fonts.googleapis.com/css?family=Lora|Roboto:400,500);

//###############Notice Board
.text-black {
  color: rgba(0, 0, 0, 0.9);
}
.text-muted {
  color: rgba(0, 0, 0, 0.3);
}

.header-container {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.header-title {
  color: rgb(51, 51, 51);
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 500;
  margin: 100px 20px 50px 20px;
  padding: 20px 0px 20px 30px;
  border-bottom: solid 0.5px #ccc;
}
.header-line {
  width: 100px;
  height: 1px;
  background: #606060;
  margin: 0 auto;
}
.consult-row {
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
  text-align: center;
}
.consult-image {
  width: 100%;
  height: 100%;
}
.consult-button {
  color: rgb(51, 51, 51);
  font-size: 12px;
  font-weight: 200;
  height: 20px;
  letter-spacing: 1px;
  line-height: 30px;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5vh;
  padding-left: 15px;
  padding-top: 30px;
}

.card-image {
  box-shadow: 0px 0px 20px 2px rgba(153, 153, 153, 0.2);
  object-fit: cover;
  object-position: center;
  width: auto;
  max-width: 75%;
  max-height: 75%;
  margin: auto;
  height: fit-content;
  transition: 0.2s ease-in all;
}
.card-image:hover {
  transition: 0.2s ease-in all;
  opacity: 0.6;
  cursor: pointer;
}

.image-container {
  width: 20vw;
  display: flex;
  text-align: center;
  @media only screen and (max-width: 767px) {
    width: 40vw;
  }
}
.title-box {
  margin-top: 10px;
  text-align: center;
  height: 80px;
  width: 100%;
  padding: 8px 1vw 8px 1vw;
  @media only screen and (min-width: 768px) {
    padding: 8px 1vw 8px 1vw;
  }
}
.card-style {
  display: flex;
  box-shadow: 2px 5px 10px 2px rgba(153, 153, 153, 0.5);
  padding: 10px;
  overflow: hidden;
  border-radius: 30px;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 10px 0px;
  .text-box {
    height: 60px;
    width: 100%;
    padding: 10px 3vw 10px 3vw;
    @media only screen and (max-width: 767px) {
      padding: 10px 10vw 10px 10vw;
    }
  }
}
.card-title {
  font-weight: normal;
  color: rgba(0, 0, 0, 0.9);
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 1rem;
  line-height: 1.25;
}
.card-desc {
  margin-bottom: 1rem;
  font-size: 12px;
}
.card-specific {
  text-transform: uppercase;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  margin-left: 2rem;
  text-align: right;
}
.card-subtitle {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: rgba(0, 0, 0, 0.4);
}
.see-more {
  width: 100%;
  display: flex;
  padding-right: 3vw;
  font-size: 15px;
  font-weight: 500;
  justify-content: flex-end;
}
.card-button {
  text-align: right;
  margin-right: 10%;
}
.card-footer {
  text-align: right;
}
.card-heading {
  font-size: 12px;
}
.styled-link {
  text-decoration: none;
  outline: none;
  color: #333;
  transition: all 0.25s ease-in;
}
.styled-link:hover,
.styled-link:focus,
.styled-link:active {
  color: #333;
}
.shadow-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
}
.blue-hover {
  transition: all 0.25s ease-in;
}

.mb-1 {
  margin-bottom: 1rem;
}

.px-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

//#########modal#########/

.ModalSize {
  width: 100vw;
  font-size: 12px;
  padding: 10px;
}

// /* popup

.ModalTitle {
  font-weight: 500;
}
.ModalImage {
  width: 100%;
  height: auto;
}
.ModalBtnContainer {
  min-height: 30px;
  margin: 10px 0 10px;
  text-align: center;
  display: block;
}
.ModalBtn {
  margin-left: -15px;
  display: inline-block;
  box-sizing: border-box;
  padding: 4px 8px;
  border: 1px solid #d1d1d1;
  border-radius: 0px;
  font-size: 12px;
  line-height: 25px;
  font-weight: normal;
  text-decoration: none;
  vertical-align: middle;
  word-spacing: -0.5px;
  letter-spacing: 0px;
  text-align: center;
  white-space: nowrap;
  color: #222;
  background-color: #fff;
}

//##########pagination###########
.display-container {
  padding-top: 50px;
}
div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}
.react-paginate {
  width: -webkit-fill-available;
  max-width: 1020px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  margin: 20px 5px;
  border-radius: 2rem;
  background: #ede5e6;
  box-shadow: 0 0.8rem 2rem rgba(#5a6181, 0.05);
  padding: initial;

  .previousBtn {
    color: darkgrey;
    &.active {
      color: darkgrey;
      pointer-events: initial;
      &:hover {
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .nextBtn {
    color: darkgrey;
    &.active {
      color: darkgrey;
      pointer-events: initial;
      &:hover {
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .currentPage {
    font-weight: bold;
    color: black;
  }
}
.paginate-container {
  text-align: center;
}
.react-paginate li {
  display: inherit;
  justify-content: center;
  align-items: center;
  margin: 1%;
  font-size: 0.9rem;
  cursor: pointer;
  width: 1.8rem;
  height: 2.6rem;
  border-radius: 0.4rem;
  color: darkgrey;
  @media only screen and (max-width: 760px) {
    font-size: 0.8rem;
  }
  a {
    text-decoration: none;
  }
  &:hover {
    color: black;
  }

  &.active {
    color: #ffffff;
    font-weight: 600;
    border: 1px solid var(--primary);
  }
}

.soldout-circle {
  background: #f00;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  float: right;
  margin-top: 3.5px;
}

.payWrap {
  position: relative;
  width: 1240px;
  margin: 150px auto 0;
}

.payTitle {
  min-height: 30px;
  margin: 10px 0 100px;
  text-align: center;
  display: block;
  h2 {
    display: block;
    margin: 0;
    padding: 0 0 10px;
    font-size: 20px;
    color: #606060;
    font-weight: 700;
    *display: inline;
    *zoom: 1;
  }
}
.tempText {
  font-size: 12px;
}
.orderContainer {
  width: 1200px;
  .orderTitle {
    margin-bottom: 30px;
    position: relative;
    width: 100%;
    height: 50px;
    padding: 0 0 0 9px;
    border: 1px solid #d7d5d5;
    border-bottom: 0;
    line-height: 50px;
    background: #f6f6f6;
    h3 {
      display: inline-block;
      vertical-align: middle;
      color: #353535;
      font-size: 12px;
      font-weight: 700;
    }
    .required {
      margin: -15px 0 0;
      color: #353535;
      text-align: right;
    }
  } ///ortderTitle

  .orderTable {
    margin-top: 10px;
    border-bottom: 1px solid #e8e8e8;
    border-width: 1px 0;
    display: flex;
    border-color: inherit;
    .tableCol {
      padding: 11px 0 10px;
      border-bottom: 1px solid #e8e8e8;
      color: #353535;
      font-weight: 500;
      background: #efefef;
    }
  } //orderTable

  .orderContent {
    width: 1240px;
    display: flex;
    margin: 10px 0 0;
    line-height: 1.5;
    border-bottom: 1px solid #e8e8e8;

    .contentCol {
      margin: auto;

      padding: 8px 10px 7px;
      background-color: #efefef;

      .contentImg {
        width: 60%;
        height: auto;
        padding: 5px;
      }
    }
  }
}

.orderForm {
  width: 100%;
  margin-top: 25px;
  border: 1px solid #e8e8e8;
  border-width: 1px 0;
  .inputLine {
    display: flex;
    height: auto;
    .left {
      width: 300px;
      padding: 11px 0 10px 18px;
      border: 1px solid #e8e8e8;
      // border-bottom-width: 0;
      color: #353535;
      text-align: left;
      font-weight: normal;
      background-color: #efefef;
      vertical-align: top;
    }
    .right {
      padding: 11px 10px 10px;
      border-top: 1px solid #e8e8e8;
      color: #353535;
      vertical-align: middle;
      word-break: break-all;
      word-wrap: break-word;
    }
    .inputStyle {
      line-height: 27px;
      padding: 2px 4px;
      border: 1px solid #d5d5d5;
      color: #353535;
      font-size: 12px;
    }
  }
}

.searchBtn {
  margin-left: 5px;
  display: inline-block;
  box-sizing: border-box;
  padding: 4px 8px;
  border: 1px solid #d1d1d1;
  border-radius: 0px;
  font-size: 12px;
  line-height: 25px;
  font-weight: normal;
  text-decoration: none;
  vertical-align: middle;
  word-spacing: -0.5px;
  letter-spacing: 0px;
  text-align: center;
  white-space: nowrap;
  color: #222;
  background-color: #fff;
}

.agreeContainer {
  width: 100%;
  overflow: hidden;
  margin: 20px 0 20px;
  padding: 10px 14px 0 4px;
  border: 1px solid #d7d5d5;
  .checkLine {
    width: 100%;
    min-height: 14px;
    padding: 10px;
    margin: 10px -10px 10px 10px;
    background: #fbfafa;
  }
  .agreeContent {
    width: 100%;
    padding: 10px 0 10px;
    .agreeBox {
      position: relative;
      margin: 10px 10px 20px;
      padding-left: 160px;
      .agreeTitle {
        position: absolute;
        top: 0;
        left: 0;
        width: 150px;
        font-size: 12px;
        font-weight: 700;
      }
    }
  }
}

.payMethodContainer {
  overflow: hidden;
  position: relative;
  padding: 0 241px 0 0;
  border: 1px solid #777;
  color: #353535;
  line-height: 1.5;
  .payment {
    float: left;
    width: 100%;
  }
  .methodHeader {
    padding: 17px 20px 15px;
    font-weight: bold;
    border-bottom: 3px double #dedede;
  }
  .methodSpan {
    display: inline-block;
    margin: 0 15px 5px 0;
  }
}

$dark: #222222;
$black: #000000;
$blue: #326bb5;
$darkgray: #333333;
$lightgray: #e3e3e3;
$white: #efefef;

.bg-blue {
  background-color: $blue;
}
.bg-dark {
  background-color: $dark;
}
.bg-mydark {
  background-color: $dark;
}
.bg-darkgray {
  background-color: $darkgray;
}
.bg-lightgray {
  background-color: $lightgray;
}
.bg-gray {
  background-color: gray;
}
.bg-white {
  background-color: $white;
}
.bg-red {
  background-color: red;
}
.section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.border-test {
  border: 1px solid $darkgray;
}
.zIndex1 {
  z-index: 5;
}
.label {
  font-size: 13px;
  font-weight: 500;
  color: $black;
}
.detail {
  font-size: 13px;
  font-weight: 100;
  color: $black;
}
.nav-dropdown {
  background-color: transparent;
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.navbar-transparent {
  z-index: 100;
  padding-left: 20px;
  padding-right: 20px;
  transition: 0.3s;
  background-color: rgba(255, 255, 255, 0);
  @media only screen and (max-width: 992px) {
    box-shadow: 0px 0px 20px 2px rgba(76, 76, 76, 0.3);
    background-color: rgba(255, 255, 255, 0.8);
  }
  .navbtn {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 12px;
    font-weight: 100;
    color: $black;
  }

  .logo-text {
    font-size: 30px;
    font-weight: 200;
    color: $black;
  }
}
.logo-box {
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-left {
  width: 50%;
  height: 100%;
}
.nav-right {
  width: 30%;
  height: 100%;
}
.h100p {
  height: 100%;
}
.w50p {
  width: 50%;
}
.w30p {
  width: 30%;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.nav {
  position: fixed;
  z-index: 100;
  top: 5px;
  left: 0.5%;
  width: 99%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    left: 2%;
    width: 96%;
  }
}
.nav-toggle {
  display: none;
  @media only screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.darkness {
  position: fixed;
  top: -5px;
  left: -1%;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.5);
}
.nav-element {
  height: 100%;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 768px) {
    display: none;
  }
}
.nav-dropdown {
  position: fixed;
  top: 10px;
  display: block;
  z-index: 10;
  left: 0.5%;
  width: 99%;
  background-color: black;
  height: 100px;
  opacity: 1;
  transform: translateY(150px) scaleY(4);
  transition: 0.5s ease-in-out;
  @media only screen and (max-width: 768px) {
    left: 2%;
    width: 96%;
  }
}

.navbar-white {
  z-index: 104;
  padding-left: 20px;
  padding-right: 20px;
  transition: 0.3s;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 20px 2px rgba(51, 51, 51, 0.3);
  @media only screen and (max-width: 992px) {
    background-color: rgba(255, 255, 255, 0.9);
  }
  .navbtn {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 12px;
    font-weight: 100;
    color: $black;
  }

  .logo-text {
    font-size: 30px;
    font-weight: 200;
    color: $black;
  }
  .logo-text {
    font-size: 30px;
    font-weight: 200;
    color: $black;
  }
}
.none-hover :hover {
  text-decoration: none;
}
.highlight {
  text-decoration: underline;
  font-weight: 300 !important;
}
.shop-btn-highlight {
  font-size: 23px;
  text-decoration: underline;
  font-weight: 500 !important;
  color: $black;
  @media only screen and (max-width: 760px) {
    font-size: 16px;
  }
}

.shop-btn {
  font-size: 23px;
  font-weight: 300;
  @media only screen and (max-width: 1000px) {
    font-size: 16px;
  }
}

.abs-test {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('./images/home1.jpg');
  background-repeat: repeat;
  z-index: 0;
  height: 100vh;
  width: 100vw;
}

.aspect-ratio-1-1 {
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  position: relative; /* If you want text inside of it */
}
.aspect-ratio-4-3 {
  width: 100%;
  padding-top: 75%; /* 1:1 Aspect Ratio */
  position: relative; /* If you want text inside of it */
}
.aspect-ratio-16-9 {
  width: 100%;
  padding-top: 56.25%; /* 1:1 Aspect Ratio */
  position: relative; /* If you want text inside of it */
}
.inline-box {
  position: absolute;
  top: 0;
  left: 0;
  color: #ffffff;
}

.vh100 {
  height: 100vh;
}

.pointer {
  cursor: pointer;
  transition: ease-out 0.5s;
  opacity: 1;
}
.pointer:hover {
  opacity: 0.3;
  transition: ease-out 0.5s;
}

.homeTransition-appear {
  opacity: 0;
}

.homeTransition-appear.homeTransition-appear-active {
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
.alert-appear {
  opacity: 0;
  transform: scale(0.9);
}
.alert-appear-active {
  opacity: 1;
  transform: translateX(0px);
  transition: all 0.5s ease-in-out;
}

.fadein {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.custom {
  animation: custom 2s;
}
@keyframes custom {
  from {
    width: 0%;
    height: 0%;
  }
  to {
    width: 100%;
    height: 100%;
  }
}

.fadeout {
  -webkit-animation: fadeout 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeout 2s; /* Firefox < 16 */
  -ms-animation: fadeout 2s; /* Internet Explorer */
  -o-animation: fadeout 2s; /* Opera < 12.1 */
  animation: fadeout 2s;
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

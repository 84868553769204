.Wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px solid #000;
  min-height: 85vh;
  animation: top-down-animation;
  transition: 0.3s all ease-in;
}

.titleWrap {
  width: 90%;
  margin: 0 5% 0 5%;
  line-height: 40px;
  text-align: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.titleWrap .title {
  width: 100%;
  text-align: center;
  height: 50px;
  margin: auto;
}
.titleWrap .title .member {
  font-weight: 700;
  font-size: 16px;
  color: #333;
}

.loginWrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #3f3f3f;
  font-size: 12px;
}

.loginInput {
  width: 90%;
  overflow: hidden;
  margin-bottom: 2px;
  line-height: 40px;
}

.loginInput input {
  outline: none;
  width: 100%;
  height: 40px;
  font-size: 12px;
  padding: 2px 10px 2px 10px;
  margin-top: 10px;
  line-height: 40px;
  background-color: #fff;
}
.loginInput input:focus {
  outline: none;
}
.loginButtonWrap {
  left: 20px;
  top: 155px;
  width: 100%;
  margin: 10px 0 0;
  border: 1px #d8d6d6;
  // background-color: #f2f2f2;
}
.loginButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin-top: 15px;
  height: 40px;
  background-color: #333;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
}

.signWrap {
  left: 428px;
  top: 0px;
  width: 300px;
  text-align: center;
  border: 0px solid #000;
}
.signMsg {
  text-align: left;
  padding: 5px 0;
  line-height: 20px;
  color: #333;
  text-decoration: none;
  font-size: 12px;
}
.signInBtn {
  width: 300px;
  line-height: 14px;
  padding: 10px 0;
  font-size: 11px;
  text-align: center;
  margin: 0;
  letter-spacing: 0.5px;
  display: inline-block;
  background: #f2f2f2;
  color: #000000;
  margin-bottom: 15px;
}

@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
    margin-top: 100px;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.payWrap {
  position: relative;
  width: 1240px;
  margin: 150px auto 0;
}

.payTitle {
  min-height: 30px;
  margin: 10px 0 100px;
  text-align: center;
  display: block;
  h2 {
    display: block;
    margin: 0;
    padding: 0 0 10px;
    font-size: 20px;
    color: #606060;
    font-weight: 700;
    *display: inline;
    *zoom: 1;
  }
}
.tempText {
  font-size: 12px;
}
.SignUpContainer {
  margin-top: 25px;
  .SignUpTitle {
    position: relative;
    width: 100%;
    height: 50px;
    margin: 0;
    padding: 0 0 0 9px;
    line-height: 50px;
    //background: #f6f6f6;
    h3 {
      display: inline-block;
      vertical-align: middle;
      color: #353535;
      font-size: 12px;
      font-weight: 700;
    }
    .required {
      margin: -15px 0 0;
      color: #353535;
      text-align: right;
    }
  } ///ortderTitle

  .SignUpTable {
    margin-top: 10px;
    border: 1px solid #e8e8e8;
    border-width: 1px 0;
    display: contents;
    border-color: inherit;
    .tableCol {
      padding: 11px 0 10px;
      border-bottom: 1px solid #e8e8e8;
      color: #353535;
      font-weight: 500;
      background: #fafafa;
    }
  } //orderTable

  .SignUpContent {
    display: contents;
    margin: 10px 0 0;
    line-height: 1.5;
    border-bottom: 1px solid #e8e8e8;

    .contentCol {
      margin: auto;

      padding: 8px 10px 7px;
      background-color: #fff;

      .contentImg {
        width: 100%;
        height: auto;
        padding: 5px;
      }
    }
  }
  .required {
    margin: -15px 0 0;
    color: #353535;
    text-align: right;
  }
} ///ortderTitle

.SignUpForm {
  margin-top: 25px;
  border: 1px solid #e8e8e8;
  border-width: 1px 0;
  .inputLine {
    height: auto;
    .left {
      padding: 11px 0 10px 18px;
      border: 1px solid #e8e8e8;
      // border-bottom-width: 0;
      color: #353535;
      text-align: left;
      font-weight: normal;
      //background-color: #fafafa;
      @media only screen and (max-width: 360px) {
        width: 320px;
      }
    }
    .right {
      padding: 11px 10px 10px;
      border-top: 1px solid #e8e8e8;
      color: #353535;
      vertical-align: middle;
      word-break: break-all;
      word-wrap: break-word;
    }
    .inputStyle {
      line-height: 27px;
      padding: 2px 4px;
      border: 1px solid #d5d5d5;
      color: #353535;
      font-size: 12px;
    }
  }
} //orderTable

.SignUpContent {
  display: contents;
  margin: 10px 0 0;
  line-height: 1.5;
  border-bottom: 1px solid #e8e8e8;

  .contentCol {
    margin: auto;

    padding: 8px 10px 7px;
    background-color: #fff;

    .agreeHeader {
      position: relative;
      z-index: 2;
      border-width: 1px;
      border-color: #d7d5d5;
      //background-color: #f5f5f5;
      padding: 20px;
      margin-top: 50px;
      margin-left: auto;
      margin-right: auto;
      border: 1px solid #e8e8e8;
      color: #404040;
      width: 100%;
      .agreeP {
        margin: auto;
        font-size: 14px;
        color: #353535;
        line-height: 22px;
        .checkSpan {
          display: inline-block;
          position: relative;
          margin: 0 8px 0 0;
          width: 22px;
          height: 22px;
          vertical-align: top;
          cursor: pointer;
          .checkEm {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url(//img.echosting.cafe24.com/skin/base/common/bg_join_check.png)
              no-repeat 0 0;
          }
        }
      }
    }
  }

  .SignUpForm {
    width: 1200px;
    margin-top: 25px;
    border: 1px solid #e8e8e8;
    border-width: 1px 0;
    .inputLine {
      display: flex;
      height: auto;
      .left {
        padding: 11px 0 10px 18px;
        border: 1px solid #e8e8e8;
        // border-bottom-width: 0;
        color: #353535;
        text-align: left;
        font-weight: normal;
        background-color: #efefef;
        vertical-align: top;
      }
      .right {
        padding: 11px 10px 10px;
        border-top: 1px solid #e8e8e8;
        color: #353535;
        vertical-align: middle;
        word-break: break-all;
        word-wrap: break-word;
      }
      .inputStyle {
        line-height: 27px;
        padding: 2px 4px;
        border: 1px solid #d5d5d5;
        color: #353535;
        font-size: 12px;
      }
    }
  }

  .searchBtn {
    margin-left: 5px;
    display: inline-block;
    box-sizing: border-box;
    padding: 4px 8px;
    border: 1px solid #d1d1d1;
    border-radius: 0px;
    font-size: 12px;
    line-height: 25px;
    font-weight: normal;
    text-decoration: none;
    vertical-align: middle;
    word-spacing: -0.5px;
    letter-spacing: 0px;
    text-align: center;
    white-space: nowrap;
    color: #222;
    background-color: #fff;
  }

  .agreeBody {
    color: #747474;
    line-height: 20px;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #e8e8e8;
    border-width: 1px;
    border-color: #d7d5d5;
    //background-color: #f5f5f5;
    .agreeBodyTitle {
      margin: 0 0 10px;
      font-size: 12px;
      color: #353535;
    }
    .agreeBodyContent {
      overflow: auto;
      height: 110px;
      padding: 20px;
      border: 1px solid #d5d5d5;
      background: #fff;
      font-size: 11px;
      .contents {
        padding: 0 0 17px;
      }
      .privacyTitle {
        font-weight: 700;
      }
    }
  }
}

.agreeBody {
  color: #747474;
  line-height: 20px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #e8e8e8;
  border-width: 1px;
  border-color: #d7d5d5;
  background-color: #f5f5f5;
  .agreeBodyTitle {
    margin: 0 0 10px;
    font-size: 12px;
    color: #353535;
  }
  .agreeBodyContent {
    overflow: auto;
    height: 110px;
    padding: 20px;
    border: 1px solid #d5d5d5;
    background: #fff;
    font-size: 11px;
    .contents {
      padding: 0 0 17px;
    }
    .privacyTitle {
      font-weight: 700;
    }
  }
}

.agreeFooter {
  padding: 7px 0 0 0;
  color: #202020;
  font-size: 12px;
  .footerCheck {
    margin-top: px;
    width: 13px;
    height: 13px;
    border: 0;
  }
  .footerLabel {
    margin: 0 4px 0 2px;
  }
}

.signUpBtn {
  width: 120px;
  padding-left: 8px;
  padding-right: 8px;
  display: inline-block;
  box-sizing: border-box;
  padding: 10px 16px;
  border: 1px solid transparent;
  border-radius: 0px;
  font-size: 12px;
  line-height: 18px;
  font-weight: normal;
  text-decoration: none;
  vertical-align: middle;
  word-spacing: -0.5px;
  letter-spacing: 0px;
  text-align: center;
  white-space: nowrap;
  color: #fff;
  background-color: #434343;
}

//##########validation#############

.notValid {
  font-size: 10px;
  color: #ff0000;
}
.notValid2 {
  font-size: 10px;
  color: #606060;
}

// .agreeContainer{
//     width:100%;
//     overflow: hidden;
//     margin: 20px 0 20px;
//     padding: 10px 14px 0 4px;
//     border: 1px solid #d7d5d5;
//     .checkLine{

//         width:100%;
//         min-height: 14px;
//         padding: 10px;
//         margin: 10px -10px 10px 10px;
//         background: #fbfafa;
//     }
//     .agreeContent{
//         width: 100%;
//         padding: 10px 0 10px;
//         .agreeBox{
//             position: relative;
//             margin: 10px 10px 20px;
//             padding-left: 160px;
//             .agreeTitle{
//                 position: absolute;
//                 top: 0;
//                 left: 0;
//                 width: 150px;
//                 font-size: 12px;
//                 font-weight: 700;
//             }
//         }

//     }
// }

// .signUpContainer{
//     width:1240px;
//     margin: auto;

//     ;

// }
// .signUpTitle{
//     margin: auto;
//     width:1240px;
//     min-height: 30px;
//     margin-top: 110px;
//     text-align: center;
//     border-bottom:1px solid #000;
// }
// .signUpForm{

//     margin:auto;
//     padding: 60px;
// }

// .findAddressBtn{
//     display: inline-block;
//     padding: .5em 1em;
//     margin: .4em .15em;
//     border: 1px solid #ccc;
//     border-color: #dbdbdb #d2d2d2 #b2b2b2 #d2d2d3;
//     cursor: pointer;
//     color: #464646;
//     border-radius: .2em;
//     vertical-align: middle;
//     font-size: 1em;
//     line-height: 1.25em;
//     background-image: -webkit-gradient(linear,left top,left bottom,from(#fff),to(#f2f2f2));
// }
// .signUpBtnContainer{
//     padding: 10px 0;
//     text-align: center;
// }

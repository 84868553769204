@charset 'utf-8';
@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700|Playfair+Display:400,400i,700,900);
// a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,div,dl,dt,em,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,ol,output,p,pre,q,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video{margin:0;padding:0;border:0;font-size:100%;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}blockquote,q{quotes:none}blockquote:after,blockquote:before,q:after,q:before{content:'';content:none}table{border-collapse:collapse;border-spacing:0}*,:active,:focus,:hover{outline:0 none}*{box-sizing:border-box}body,html{margin:0;width:100%;height:100%;padding:0;font-family:Arial,Helvetica,sans-serif;line-height:100%}body,html{background-color:#fff}body{font-family:Playfair Display,serif}
#wrap {
  width: 100%;
  height: 100%;
}
.body {
  overflow-x: hidden;
}
.b {
  font-size: 16px;
}
.main_wrap {
  width: 100%;
  height: 100%;
  background-image: url('../../images/story/story_back.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  display: table;
  @media only screen and (max-width: 720px) {
    background-image: url('https://cdn.pixabay.com/photo/2019/06/05/14/08/nature-4253751_960_720.jpg');
  }
}

#navbar a {
  width: 25%;
  float: left;
  display: block;
  color: #2c2e65;
  padding: 25px 0px;

  text-align: center;
  text-decoration: none;
  font-size: 14px;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 100%;
}

.main_wrap .main_h_line {
  width: 80px;
  height: 1px;
  background-color: #fff;
  display: inline-block;
  margin: 5px 0px;

  @media only screen and (min-width: 1200px) and (max-width: 1600px) {
  }
  @media only screen and (min-width: 767px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
  }
  @media only screen and (max-width: 576px) {
  }
}

.main_cont {
  width: 100%;
  max-width: 1400px;
  padding-left: 13%;
  display: table-cell;
  vertical-align: middle;
  margin: 0 auto;
  transition: all 1s;
}

p.text1 {
  display: inline-block;
  color: #fff;
  font-weight: 300;
  font-size: 14px;
}

h1.text1 b {
  font-weight: 500;
  @media only screen and (max-width: 1200px) {
    font-weight: 400;
  }
  @media only screen and (max-width: 576) {
    font-weight: 300;
  }
}

h1.text1 {
  color: #fff;
  font-weight: 300;
  font-size: 120px;
  padding-top: 30px;

  @media only screen and (min-width: 1200px) and (max-width: 1600px) {
    font-size: 90px;
    font-weight: 80;
  }
  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    font-size: 65px;
    font-weight: 70;
    padding-top: 15px;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    padding-top: 0px;
    font-size: 50px;
    font-weight: 60;
  }
  @media only screen and (max-width: 576px) {
    font-size: 40px;
    padding-top: 0px;
    font-weight: 40;
  }
}

p.text2 {
  color: #fff;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  padding-top: 20px;

  @media only screen and (min-width: 576px) and (max-width: 1200px) {
    font-size: 11px;
    font-weight: 200;
    padding-top: 15px;
  }

  @media only screen and (max-width: 576px) {
    font-size: 8px;
    padding-top: 5px;
    font-weight: 300;
  }
}

.button_wrap {
  width: auto;
  height: auto;
  margin-top: 40px;
  display: inline-flex;
  @media only screen and (min-width: 550px) and (max-width: 1200px) {
    margin-top: 10px;
  }

  @media only screen and (max-width: 550px) {
    display: none;
  }
}

.main_button2 {
  width: 10vw;
  padding: 18px 0;
  color: #fff;
  font-size: 15px;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 0.5s ease;
  position: relative;
  z-index: 2;

  @media only screen and (max-width: 767px) {
    font-size: 12px;
    width: 10vw;
  }
}
.main_button2:hover {
  color: rgba(255, 255, 255, 0.85);
  box-shadow: rgba(120, 116, 131, 0.7) 0 0px 0px 40px inset;
}
.main_button.a > a {
  background-color: #fff;
  color: #a09f9f;
}

.thick {
  color: #333;
}

.timeline_container {
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  -webkit-letter-spacing: 0.05em;
  -moz-letter-spacing: 0.05em;
  -ms-letter-spacing: 0.05em;
  letter-spacing: 0.05em;
}
.timeline_ul {
  position: relative;
  max-width: 95%;
  list-style: none;
}
.timeline_line {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background-color: black;
  content: '';
  margin-left: -1px;
  position: absolute;
  top: 0;
  left: 2em;
  width: 2px;
  height: 100%;
}
.timeline_li {
  position: relative;
}
.timeline_content {
  padding: 2em 2em 0 2em;
  position: relative;
  top: -1.875em;
  left: 2em;
  width: 95%;
  @media only screen and (max-width: 768px) {
    padding: 1em 0 0;
    left: 1em;
  }
}

.timeline_content_title {
  color: #606060;
  font-style: oblique;
  display: inline-block;
  font-size: 120%;
  font-weight: 500;
  margin-bottom: 1.2em;
  padding: 0.25em 1em 0.2em 1em;
}
.timeline_content_p {
  color: #333;
  font-weight: 300;
}
.thin {
  color: #818182;
}

.zoom {
  cursor: zoom-in;
}

@media (min-width: 768px) {
  .gallery_container {
    padding-left: 11px;
    padding-right: 11px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    max-width: 1320px;
  }
}

#layout {
  color: #fff;
  width: 50%;
  height: 50%;
  margin: 0 auto;
}

#navbar {
  width: 90%;
}

.stars {
  z-index: 0;
  position: absolute;

  top: 0;
  background-repeat: repeat-x, repeat-x repeat-y;
  transform: translate3D(0em, 0em, 0);
  animation: stars 21s ease;
  transform-style: preserve-3d;
}

.right_img {
  position: absolute;
  right: 15%;
  top: 40%;
  transition: all 1s;
}

.v_line {
  width: 1px;
  height: 25px;
  margin: 0 0 0 100px;
  background-color: #fff;
}

.r_text {
  color: #fff;
  font-size: 12px;
  text-align: right;
  line-height: 30px;
  font-weight: 300;
  margin: 20px 0;
}
/*animate_box*/
#wrap_box {
  width: 100%;
  height: 50px;
}

.animate {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  position: relative;
  bottom: 80px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.22);
}

.top_line {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 1px;
  background: #fff;
}

.bottom_line {
  position: absolute;
  bottom: 0;
  left: 100%;
  width: 100%;
  height: 1px;
  background: #fff;
}

.right_line {
  position: absolute;
  width: 1px;
  height: 100%;
  right: 0;
  top: -100%;

  background: #fff;
}

.left_line {
  position: absolute;
  width: 1px;
  height: 100%;
  left: 0;
  top: 100%;
  background: #fff;
}

@media (max-width: 1455px) {
  .main_cont {
    padding-left: 7%;
  }
}

@media (max-width: 1008px) {
  .main_cont {
    padding-left: 7%;
  }
}

@media (max-width: 608px) {
  .main_cont {
    width: 100%;
    padding-left: 5%;
  }
}
.contents_wrap {
  width: 100%;
  height: auto;
  border: 1px solid #f00;
}

.section {
  width: 94%;
  max-width: 1200px;
  height: auto;
  margin: 0 auto;
}

.section .secline {
  margin-top: 60px;
  width: 50px;
  height: 3px;
  background-color: #666;
  margin-bottom: 10px;
}

.sec1,
.sec2,
.sec3 {
  padding: 40px 0px 50px 0;
}
.sec1-Profile {
  padding: 40px 0px 50px 0;
  background-color: #fff;
}
.section .title {
  font-size: 24px;
  display: inline-block;
  font-weight: 300;
  color: #dee2e6;
}

.section .s_title {
  font-size: 12px;
  display: inline-block;
  font-weight: 300;
  color: #818182;
}

.sec_box_wrap {
  width: 100%;
  height: auto;
  margin-top: 70px;
}

.sec_box_wrap ul li:nth-child(1) img {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  display: block;
  margin-top: 20px;
}

.sec_box_wrap ul li:nth-child(1) p {
  text-align: center;
  font-size: 14px;
  color: #818182;
  font-weight: 300;
  line-height: 0;
}
.sec_box_wrap ul li:nth-child(1) p:nth-child(2) {
  text-align: center;
  font-size: 16px;
  color: #dee2e6;
  line-height: 40px;
  font-weight: 500;
  padding-top: 10px;
}
.sec_box_wrap ul li:nth-child(2) h5 {
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
  margin-top: 10px;
}

.sec_box_wrap ul li:nth-child(2) p {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}

@media (max-width: 608px) {
  .sec_box_wrap ul li:nth-child(2) h5 {
    text-align: center;
  }
  .sec_box_wrap ul li:nth-child(2) {
    padding: 30px 4% 0px;
    box-sizing: border-box;
    text-align: center;
    position: relative;
  }
}

/* =====================================CSS RESET===================================== */

a {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  color: inherit;
  display: inline-block;
}
img {
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
* {
  box-sizing: border-box;
}
/* =====================================GRID SYSTEM===================================== */
.center {
  width: 1200px;
  margin: 0 auto;
}
[class*='col-'] {
  width: 100%;
  float: left;
  border: 5px #fff;
  box-sizing: border-box;
  padding: 0px 0px;
}

.Timeline {
  position: relative;
  max-width: 95%;
  list-style: none;
}
.TimelineBefore {
  background-color: black;
  content: '';
  margin-left: -1px;
  position: absolute;
  top: 0;
  left: 2em;
  width: 2px;
  height: 100%;
}
.TimelineContainer {
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.05em;
}
.TimelineContainer * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

.TimelineEvent {
  position: relative;
}
.TimelineIcon {
  transform: rotate(45deg);
  background-color: black;
  outline: 10px solid white;
  display: block;
  margin: 0.5em 0.5em 0.5em -0.5em;
  position: absolute;
  top: 0;
  left: 2em;
  width: 1em;
  height: 1em;
}
.TimelineBody {
  padding: 2em 2em 0 2em;
  position: relative;
  top: -1.875em;
  left: 2em;
  width: 95%;
}
.TimelineBody .h3 {
  font-size: 1.75em;
}
.TimelineBody .h4 {
  font-size: 1.2em;
  margin-bottom: 1.2em;
}
.TimelineDate {
  color: white;
  background-color: black;
  box-shadow: inset 0 0 0 0em #ef795a;
  display: inline-block;
  margin-bottom: 1.2em;
  padding: 0.25em 1em 0.2em 1em;
}
.TimelineDescription .strong {
  font-weight: 700;
}
.TimelineDescription .p {
  padding-bottom: 1.2em;
}

@media only screen and (max-width: 1024px) {
  .Profile .profile-picture-block .profile-picture {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 0.5;
  }
  .Profile .container {
    width: 90vw;
  }
  .Profile .container .title {
    margin-left: 0;
    width: 100%;
  }
  .Profile .container .content {
    width: 80%;
    font-size: 2vmax;
    line-height: 150%;
  }
  .Profile .container .button,
  .Profile .container .content {
    margin-top: 4vmax;
    margin-left: 0;
  }
  .Profile .container .update {
    margin-top: 2vmax;
    margin-left: 0;
    font-size: 1.3vmax;
    color: #aaa;
  }
}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .Profile .container .content {
    width: 80%;
    font-size: 1.75vmax;
  }
  .Profile .container .update {
    display: none;
  }
}
.NotFound {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.NotFound .animate {
  transform: translateY(10%);
  opacity: 0;
}
.NotFound .container {
  position: absolute;
  width: 100%;
}
.NotFound .container .content {
  margin: 0 auto;
  width: 80vw;
  padding: 11.24vw 0 2.81vw;
}
.NotFound .container .content .title {
  width: 35vw;
}
.NotFound .container .content .description {
  margin-top: 4vw;
  width: 50%;
  line-height: 150%;
  word-break: break-all;
}
.NotFound .container .content .button-block {
  margin-top: 2vw;
}
@media only screen and (max-width: 1024px) {
  .NotFound .container .content {
    padding: 10vmax 0 12vmax;
  }
}

.story-gallery {
  max-width: 70vw;
  margin: auto;
  @media only screen and (max-width: 768px) {
    max-width: 90vw;
  }
}

.story-title-container {
  width: 94%;
  max-width: 1200px;
  height: auto;
  margin: 0 auto;
  padding: 40px 0px 50px 0;
}
.story-title {
  font-size: 24px;
  display: inline-block;
  font-weight: 300;
  color: #333;
}
.story-stitle {
  font-size: 12px;
  display: inline-block;
  font-weight: 300;
  color: #818182;
}
.title-line {
  margin-top: 60px;
  width: 50px;
  height: 3px;
  background-color: #666;
  margin-bottom: 10px;
}
.clearfix:after {
  content: '';
  display: block;
  clear: both;
}

// ######## Profile ##########

.display-block {
  display: block;
}
$color_white: #fff;
$color_prime: #333;
$color_grey: #e2e2e2;
$color_grey_dark: #a2a2a2;

.blog-card {
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
  box-shadow: 0 3px 7px -1px rgba(#000, 0.1);
  margin-bottom: 1.6%;
  background: $color_white;
  line-height: 1.4;
  border-radius: 5px;
  overflow: hidden;
  z-index: 0;
  a {
    color: inherit;
    &:hover {
      color: $color_prime;
    }
  }
  &:hover {
    .photo {
      transform: scale(1.3) rotate(3deg);
    }
  }
  .meta {
    position: relative;
    z-index: 0;
    height: 200px;
  }
  .photo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    transition: transform 0.2s;
  }
  .details,
  .details ul {
    margin: auto;
    padding: 0;
    list-style: none;
  }

  .details {
    position: absolute;
    top: 0;
    bottom: 0;
    // left: -100%;
    margin: auto;
    transition: left 0.2s;
    // background: rgba(#000, .6);
    color: $color_white;
    padding: 10px;
    width: 100%;
    font-size: 0.7rem;
    a {
      text-decoration: dotted underline;
    }
    ul li {
      display: inline-block;
    }
    .author:before {
      margin-right: 10px;
      content: '\f007';
    }

    .date:before {
      margin-right: 10px;
      content: '\f133';
    }

    .tags {
      ul:before {
        content: '\f02b';
        margin-right: 10px;
      }
      li {
        margin-right: 2px;
        &:first-child {
          margin-left: -4px;
        }
      }
    }
  }
  .description {
    padding: 1rem;
    background: $color_white;
    position: relative;
    z-index: 1;
    h1,
    h2 {
      font-weight: 500;
    }
    h1 {
      line-height: 1;
      margin: 0;
      font-size: 1.2rem;
    }
    h2 {
      font-size: 1rem;
      font-weight: 300;
      text-transform: uppercase;
      color: $color_grey_dark;
      margin-top: 5px;
    }
    .read-more {
      text-align: right;
      a {
        color: $color_prime;
        display: inline-block;
        position: relative;
        &:after {
          margin-left: -10px;
          opacity: 0;
          vertical-align: middle;
          transition: margin 0.3s, opacity 0.3s;
        }

        &:hover:after {
          margin-left: 5px;
          opacity: 1;
        }
      }
    }
  }
  p {
    position: relative;
    margin: 1rem 0 0;
    &:first-of-type {
      margin-top: 1.25rem;
      &:before {
        content: '';
        position: absolute;
        height: 5px;
        background: $color_prime;
        width: 70px;
        top: -0.75rem;
        border-radius: 3px;
      }
    }
  }
  &:hover {
    .details {
      left: 0%;
    }
  }

  @media (min-width: 640px) {
    flex-direction: row;
    max-width: 700px;
    .meta {
      flex-basis: 40%;
      height: auto;
    }
    .description {
      flex-basis: 60%;
      &:before {
        transform: skewX(-3deg);
        content: '';
        background: #fff;
        width: 30px;
        position: absolute;
        left: -10px;
        top: 0;
        bottom: 0;
        z-index: -1;
      }
    }
    &.alt {
      flex-direction: row-reverse;
      .description {
        &:before {
          left: inherit;
          right: -10px;
          transform: skew(3deg);
        }
      }
      .details {
        padding-left: 25px;
      }
    }
  }
}

.background-profile1 {
  background-image: url(../../images/story/1.jpg);
}
.background-profile2 {
  background-image: url(../../images/logo.jpg);
}

// Story

.story-body {
  margin-left: initial;
  margin-right: initial;
}
.story-col {
  @media only screen and (max-width: 768px) {
    padding-right: 0;
  }
}

// Major
.major-image-container {
  width: 100%;
}

.major-subtitle {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
}

.major-image {
  box-shadow: 0px 0px 20px 2px rgba(153, 153, 153, 0.2);
  object-fit: cover;
  max-width: 300px;
  max-height: 300px;
  margin: auto;
  transition: 0.2s ease-in all;
  @media only screen and (max-width: 1200px) {
    max-width: 160px;
    max-height: 160px;
  }
}
.major-image:hover {
  transition: 0.2s ease-in all;
  opacity: 0.6;
  cursor: pointer;
}
